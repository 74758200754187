import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { DateTimeService } from 'src/app/core/services/date-time.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-select-export-type-filiz',
  templateUrl: './select-export-type-filiz.component.html',
  styleUrls: ['./select-export-type-filiz.component.scss'],
})
export class SelectExportTypeFilizComponent extends BaseModal implements OnInit {
  @Input() id: number;
  constructor(
    private modalService: ModalService) {
    super();
    super._modalService = modalService;
  }

  ngOnInit() { }

  result(isAll: boolean) {
    this.modalService.dismissByModalId(this.id, isAll, "export-type");
  }
}
