import { Injectable, Input } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AlertController, PopoverController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { LoadingService } from "./loading.service";
// import { WalkthroughPopoverComponent } from "../helper-components/walkthrough-popover/walkthrough-popover.component";
import { StorageService } from "./storage.service";
import { StorageKeys } from "../models/enums/storage-keys";
import { IWalkthroughMaster, WalkthroughItem, WalkthroughSteps } from "../models/interfaces/walkthrough_items";

@Injectable({
    providedIn: "root"
})
export class WalkthroughService {
    @Input() showWalktrough: boolean = false; //false => hide, true => show
    @Input() triggerClick: boolean = false;
    @Input() message: string = null;
    @Input() header: string = null;
    @Input() tooltipSize: "small" | "medium" | "large" = "medium";
    @Input() messageType: "popover" | "toast" | "alert" = "popover";
    masterPopover: HTMLIonPopoverElement;
    masterToast: HTMLIonToastElement;
    masterAlert: HTMLIonAlertElement
    private _commSubject: Subject<any> = new Subject<any>();
    lastWalkthroughKey: string;
    currentMasterStep: IWalkthroughMaster;
    currentWalkthrough: WalkthroughItem;
    passedFilizWalkthroughs: Array<any>;
    passedTrapWalkthroughs: Array<any>
    passedChildStepKeys: Array<string>;
    passedChildSteps: Array<WalkthroughItem>;
    isOn: boolean = false;
    loadingObj;
    isFiliz: boolean = false;
    constructor(private loadingService: LoadingService, private router: Router, private translate: TranslateService, private storage: StorageService, private popoverController: PopoverController, private alertController: AlertController, private toastController: ToastController) {

    }

    InitWalkthrough() {
        let that = this;
        that.passedFilizWalkthroughs = [];
        that.passedTrapWalkthroughs = [];
        this.isOn = false;
        that.storage.get(StorageKeys.FILIZ_MODE).then(mode => {
            if (mode.IsSuccess) {
                that.isFiliz = mode.Data;
                that.storage.get(that.isFiliz ? (StorageKeys.FILIZ_WALKTHROUGH_STEPS) : (StorageKeys.TRAPP_WALKTHROUGH_STEPS)).then(response => {
                    if (response.Data && Array.isArray(response.Data)) {
                        if (that.isFiliz) {
                            that.passedFilizWalkthroughs = response.Data;
                        } else {
                            that.passedTrapWalkthroughs = response.Data;
                        }
                    }
                    that.router.events.subscribe(ev => {
                        if (ev instanceof NavigationEnd) {
                            let mainUrl = ev.url.split("?")[0], queryParam = ev.url.split("?")[1] || "";

                            let masterSteps = WalkthroughSteps.filter(t => t.mainUrl.includes(mainUrl) && queryParam.includes(t.queryParam));
                            if (masterSteps.length === 1) {
                                if (that.isFiliz ? !that.passedFilizWalkthroughs.find(t => t === masterSteps[0].key) : !that.passedTrapWalkthroughs.find(t => t === masterSteps[0].key)) {
                                    that.passedChildStepKeys = [];
                                    that.passedChildSteps = [];
                                    that.currentMasterStep = masterSteps[0];
                                    that.currentWalkthrough = masterSteps[0].values[0];
                                    that.isOn = true;
                                    setTimeout(() => {
                                        that.storage.get(StorageKeys.FILIZ_MODE).then(modeInner => {
                                            that.isFiliz = modeInner.Data;
                                            that.storage.get(that.isFiliz ? (StorageKeys.FILIZ_WALKTHROUGH_STEPS) : (StorageKeys.TRAPP_WALKTHROUGH_STEPS)).then(response => {
                                                if (response.Data && Array.isArray(response.Data)) {

                                                } else {
                                                    that.presentWalkthrough(that.currentWalkthrough);
                                                }
                                            })
                                        })


                                    }, 1000);
                                }
                            }
                        }
                    })
                })
            }
        });


    }

    i = 0;
    forward(props?: WalkthroughItem | any) {
        if (props && Object.keys(props).length > 0) {
            let check = props.key === this.GetWalkthroughKey()
            if (check)
                this.processForward();
        } else {
            this.processForward();
        }
    }

    private processForward() {
        this.lastWalkthroughKey = this.GetWalkthroughKey();
        this.DismissLastPresentation().subscribe(res => {
            if (this.currentWalkthrough.nextButton?.handler) {
                setTimeout(() => {
                    this.currentWalkthrough.nextButton?.handler();
                }, 100);
            }
            if (this.currentWalkthrough.nextItem && this.currentWalkthrough.nextItem !== '') {
                this.passedChildSteps.push(this.currentWalkthrough);
                this.passedChildStepKeys.push(this.currentWalkthrough.key);
                setTimeout(() => {
                    let splittedKeys = this.currentWalkthrough.nextItem.split(":")
                    let childKey = splittedKeys[0];
                    if (splittedKeys.length > 1) {
                        this.currentMasterStep = WalkthroughSteps.find(t => t.key === splittedKeys[0]);
                        childKey = splittedKeys[1];
                    }
                    let nextStep = this.currentMasterStep.values.find(t => t.key === childKey);
                    if (nextStep) {
                        this.currentWalkthrough = nextStep;
                        this.presentWalkthrough(this.currentWalkthrough);
                    }
                }, 100);
            }
            else {
                this.storage.get(StorageKeys.FILIZ_MODE).then(mode => {
                    if (mode.IsSuccess) {
                        this.isFiliz = mode.Data;
                        if (this.isFiliz ? !this.passedFilizWalkthroughs.find(t => t === this.currentMasterStep.key) : !this.passedTrapWalkthroughs.find(t => t === this.currentMasterStep.key)) {
                            this.isFiliz ? this.passedFilizWalkthroughs.push(this.currentMasterStep.key) : this.passedTrapWalkthroughs.push(this.currentMasterStep.key);
                            this.storage.set(this.isFiliz ? StorageKeys.FILIZ_WALKTHROUGH_STEPS : StorageKeys.TRAPP_WALKTHROUGH_STEPS, this.isFiliz ? this.passedFilizWalkthroughs : this.passedTrapWalkthroughs).then();
                        }
                    };
                })
                this.passedChildStepKeys = [];
                this.isOn = false;
            }
        })
    }
    WalkthroughCommService(): Observable<IWalkthroughActions> {
        return this._commSubject.asObservable();
    }

    SendMessage(item: IWalkthroughActions) {
        this._commSubject.next(item);
    }

    CloseCommSubject() {
        this._commSubject.complete();
    }

    async presentWalkthrough(props: WalkthroughItem) {
        switch (props.presentType) {
            case "alert":
                this.presentAlert(props);
                break;
            case "customToast":
            case "popover":
                setTimeout(() => {
                    this.presentCustomPopover(props);
                }, 100);
                break;
            case "toast":
                this.presentToast(props);
                break;
            default:
                break;
        }
    }

    async presentAlert(props) {
        const alert = await this.alertController.create({
            header: this.translate.instant("walkthrough." + props.header),
            message: this.translate.instant("walkthrough." + props.description),
            buttons: [{
                text: this.translate.instant("walkthrough.next"),
                handler: () => {
                    this.forward();
                }
            }]
        });

        await alert.present();
        this.masterAlert = alert;
    }
    customPopover: any = {};
    async presentCustomPopover(props) {
        this.customPopover = {
            header: this.translate.instant("walkthrough." + props.header),
            message: this.translate.instant("walkthrough." + props.description),
            mediaTemplate: null,
            isMediaFullPage: false,
            targetElementId: props.targetElementId,
            showPopover: true,
            buttonsStatus: props.popoverButtons,
            hideBackdrop: props.hideBackdrop,
            nextButton: props.nextButton,
            backButton: props.backButton,
            pageIndex: { total: this.currentMasterStep.values.filter(t => t.presentType === "popover" || t.presentType === "customToast").length, current: (this.passedChildSteps.filter(t => t.presentType === "popover" || t.presentType === "customToast").length + 1) },
            style: props.style,
            hasUserInteraction: props.hasUserInteraction,
            isToast: props.presentType === "customToast"
        }
    }
    async presentToast(props: WalkthroughItem) {
        let that = this;
        const toast = await this.toastController.create({
            header: this.translate.instant("walkthrough." + props.header),
            message: this.translate.instant("walkthrough." + props.description),
            duration: 0,
            position: 'bottom',
            color: "warning",

        });
        await toast.present();
        this.masterToast = toast;
    }
    DismissLastPresentation() {
        this.customPopover = {
            message: null,
            header: null,
            mediaTemplate: null,
            isMediaFullPage: false,
            targetElementId: null,
            showPopover: false,
            buttonsStatus: null,
            hideBackdrop: null,
            nextButton: null,
            backButton: null,
            pageIndex: null,
            style: null,
            hasUserInteraction: null
        }
        return new Observable(observer => {

            let promises = [];
            if (this.masterAlert)
                promises.push(this.masterAlert.dismiss())
            if (this.masterToast)
                promises.push(this.masterToast.dismiss())
            promises.push(this.toastController.dismiss())
            this.customPopover = {};
            this.customPopover.showPopover = false
            Promise.all(promises).then(res => {
                this.masterToast = null;
                this.masterAlert = null;
                observer.next(true);
                observer.complete();
            }).catch(err => {
                this.masterToast = null;
                this.masterAlert = null;
                observer.next(true);
                observer.complete();
            })
        })
    }
    GetWalkthroughKey() {
        if (this.currentMasterStep && this.currentWalkthrough)
            return this.currentMasterStep.key + ":" + this.currentWalkthrough.key
        else {
            return "unknown"
        }
    }

    UpdatePassedData() {
        let master = this.currentMasterStep.key;
        this.storage.get(this.isFiliz ? StorageKeys.FILIZ_WALKTHROUGH_STEPS : StorageKeys.TRAPP_WALKTHROUGH_STEPS).then((response: any) => {
            if (response && Array.isArray(response)) {
                if (response.find(t => t === master))
                    return;
                response.push(master);
            } else {
                response = [master];
            }
            this.storage.set(this.isFiliz ? StorageKeys.FILIZ_WALKTHROUGH_STEPS : StorageKeys.TRAPP_WALKTHROUGH_STEPS, response).then();
        })
    }
}

export interface IWalkthroughActions {
    direction: "forward" | "back" | "skip" | "skipWithoutComplete"
    data: any
}