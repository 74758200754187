import { Injectable } from '@angular/core';
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CleverTapService {
    constructor(private cleverTap: CleverTap) {

    }

    activate(deviceToken: string) {
        if (Capacitor.isNativePlatform()) {
            this.cleverTap.setDebugLevel(3)
            this.cleverTap.getCleverTapID().then(res => {
                console.log("getCleverTapID => ", res)
            }).catch(err => {
                console.log("getCleverTapID err => ", err)
            })
            this.cleverTap.notifyDeviceReady().then(res => {
                console.log("device ready => ", res)
            }).catch(err => {
                console.log("device ready error => ", err)
            })

            // this.cleverTap.registerPush().then()
            // if (Capacitor.getPlatform() === "android") {
                this.cleverTap.setPushToken(deviceToken).then(res => {
                    console.log("setPushToken => ", res)
                }).catch(err => {
                    console.log("setPushToken error => ", err)
                })
            // }

            this.cleverTap.activate().then(res => {
                console.log("activated => ", JSON.stringify(res))
                this.cleverTap.registerPush().then(register => {
                    // console.log(register)
                    // if (Capacitor.getPlatform() == "android")
                        this.cleverTap.setPushToken(deviceToken).then()
                })
            }).catch(err => {
                console.log("activation error => ", JSON.stringify(err))
            })
        }
    }

    recordEvent(eventName: string, props?: any) {
        if (Capacitor.isNativePlatform()) {
            if (props)
                this.cleverTap.recordEventWithNameAndProps(eventName, props);
            else
                this.cleverTap.recordEventWithName(eventName)
        }
    }

    recordScreen(screenName: string) {
        if (Capacitor.isNativePlatform())
            this.cleverTap.recordScreenView(screenName)
    }

    setUserProfile(props: {
        Name: string, //'Captain America',
        Identity: string, //'100',
        Email: string, //'captain@america.com',
        Phone: string, //'+14155551234',
        stuff: any
    }) {
        if (Capacitor.isNativePlatform()) {
            this.cleverTap.onUserLogin(props).then(res => {

            })
            this.cleverTap.enablePersonalization();
        }
    }

    setLocation(lat, lng) {
        if (Capacitor.isNativePlatform())
            this.cleverTap.setLocation(lat, lng).then()
    }

    recordPurchase(receipt, props) {
        if (Capacitor.isNativePlatform()) {
            this.cleverTap.recordChargedEventWithDetailsAndItems(receipt, props).then(res => {
                console.log("recordPurchase success => ", JSON.stringify(res));
            }).catch(err => {
                console.log("recordPurchase error => ", JSON.stringify(err));
            })
        }
    }
}