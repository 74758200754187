import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Message_Type } from 'src/app/core/models/enums/message-type';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ProfileService } from 'src/app/pages/common/profile/services/profile.service';
import { BaseModal } from '../../../models/classes/base-modal';
import { ModalService } from '../../../services/modal.service';
import { OrbitFormValidators } from '../../class/form-validators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent extends BaseModal implements OnInit {
  passwordForm: FormGroup = new FormGroup({
    Password: new FormControl("", [(ev) => {
        if (this.passwordForm) {
          let control = this.passwordForm.controls["PasswordRepeat"]
          setTimeout(() => {
            if (control.value && control.value !== '') {
              control.markAsDirty({ onlySelf: true })
              control.enable({ emitEvent: true, onlySelf: true })
            }
          }, 500);
          let response = OrbitFormValidators.mismatchPasswordValidation(control)
          return response(ev)
        } else
          return null
      }, Validators.minLength(6),Validators.maxLength(14), Validators.required]),
    PasswordRepeat: new FormControl("",[(ev) => {
      if (this.passwordForm) {
        let control = this.passwordForm.controls["Password"]
        setTimeout(() => {
          if (control.value && control.value !== '') {
            control.markAsDirty({ onlySelf: true })
            control.enable({ emitEvent: true, onlySelf: true })
          }
        }, 500);
        let response = OrbitFormValidators.mismatchPasswordValidation(control)
        return response(ev)
      }
      else
        return null
    }, Validators.minLength(6),Validators.maxLength(14), Validators.required]),
    OldPassword: new FormControl("", Validators.required)
  });
  isSend: boolean = false;
  passwordMatch: boolean = true;
  wrongPassword: boolean = false;
  constructor(private modalService: ModalService,
    private profileService: ProfileService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private translateService: TranslateService) {
    super()
    super._modalService = modalService
    // this.demoArray = new Array(2).fill(1).map(t => 1)

  }


  save() {
    if (this.passwordForm.value.Password == this.passwordForm.value.PasswordRepeat) {
      this.loadingService.showLoading();
      this.profileService.changePassword(this.passwordForm.value.OldPassword, this.passwordForm.value.Password).subscribe(e => {
        this.loadingService.hideLoading();
        if (e.IsSuccess) {
          this.isSend = true;
        } else {
          if (e.ValidationErrors && e.ValidationErrors.length > 0) {
            this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.ValidationErrors[0]), type: Message_Type.WARNING, duration: 3000 });
          } else {
            this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.Message), type: Message_Type.DANGER, duration: 3000 });
          }
        }
      }, err => {
        this.loadingService.hideLoading();
        if (err.Message === 'old-password-is-incorrect')
          this.wrongPassword = true
        this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + err.Message), type: Message_Type.DANGER, duration: 3000 });
      });
    } else {
      this.passwordMatch = false;
    }
  }


  ngOnInit() {
    super.ngOnInit()
  }

  dismissModal() {
    super.dismiss({}, '');
  }

}
