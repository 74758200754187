import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-modal-base',

  templateUrl: './modal-base.component.html',
  styleUrls: ['./modal-base.component.scss'],
})
export class ModalBaseComponent implements OnInit {

  @Input() id: number
  @Input() initialBreakpoint: number
  @Input() bottomPadding: number = 0;
  @Input() isBottomSheet: boolean = false
  @Output() onDismiss: EventEmitter<any> = new EventEmitter()

  public contentClass: any = "bg-default"//
  constructor(private modalService: ModalService) {
  }


  ngOnInit(): void {
    if (this.initialBreakpoint) {
      let diff = 1 - this.initialBreakpoint;
      this.bottomPadding = 100 * diff;
    }
    setTimeout(() => {
      this.contentClass = "bg-opaque"
    }, 500);
  }

  async dismiss(data?, role = "canceled") {
    this.contentClass = "bg-default"
    this.onDismiss.emit(true);
  }

}
