import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FarmNoteResponse } from "../models/classes/farm-note-response";
import { FarmNotes } from "../models/classes/farm-notes";
import { HttpRequestTypes } from "../models/enums/http-request-types";
import { Message_Type } from "../models/enums/message-type";
import { IFarmModel } from "../models/interfaces/farm-model";
import { IFarmEditModel, IFarmSaveModel } from "../models/interfaces/farm-save-model";
import { IParcelRequestModel, IParcelResponseModel } from "../models/interfaces/parcel-model";
import { IAvailableTimeModel } from "../models/interfaces/spraying-hours";
import { StorageService } from "../services/storage.service";
import { ToastService } from "../services/toast.service";
import { ClientBaseApiService } from "./client-base-api.service";
import { ModalService } from "../services/modal.service";
import { LoadingService } from "../services/loading.service";
import { CustomErrorModalComponent } from "../helper/components/custom-error-modal/custom-error-modal.component";
import { AppEventListenerService } from "../services/app-event-listener.service";
import { AppEventTypes } from "../models/enums/app-event-types";

@Injectable({
    providedIn: "root"
})
export class FarmApiService {
    /**
     *
     */
    public data: any;
    farmList: Array<IFarmModel> = [];
    private static _SelectedFarm: IFarmModel;
    public static get SelectedFarm(): IFarmModel {
        return FarmApiService._SelectedFarm;
    }
    public static set SelectedFarm(value: IFarmModel) {
        FarmApiService._SelectedFarm = value;
        AppEventListenerService.EmitEvent(AppEventTypes.SELECTED_FARM, FarmApiService.SelectedFarm);
    }

    constructor(private clientApi: ClientBaseApiService, private translateService: TranslateService, private toastService: ToastService, private storageService: StorageService, private modalService: ModalService, private loading: LoadingService) {
    }
    refresh() {
        window.location.reload();
    }
    public GetAll(isUpdate: boolean = true, isDemo: boolean = false, deviceType: string = 'filiz'): Observable<Array<IFarmModel>> {
        return new Observable(observer => {
            this.loading.hideLoading();
            if (isUpdate || this.farmList.length == 0) {
                let url = environment.baseApiUrl + "v2/api/" + "farm/iot/list?isDemo=" + isDemo + "&deviceType=" + deviceType
                this.clientApi.baseApiCall<Array<IFarmModel>>({ url, payload: {}, requestType: HttpRequestTypes.GET }).subscribe(e => {
                    if (e.IsSuccess) {
                        this.farmList = e.Data;
                        if (FarmApiService.SelectedFarm) {
                            FarmApiService.SelectedFarm = e.Data.find(t => t.TempFarmAttId == FarmApiService.SelectedFarm.TempFarmAttId)
                        }
                        AppEventListenerService.EmitEvent(AppEventTypes.FARM_GET, this.farmList);
                        // AppEventListenerService.EmitEvent(AppEventTypes.SELECTED_FARM, FarmApiService.SelectedFarm);
                        observer.next(e.Data);
                    } else {
                        if (e.ValidationErrors && e.ValidationErrors.length > 0) {
                            this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.ValidationErrors[0]), type: Message_Type.WARNING, duration: 3000 });
                        } else {
                            this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.Message), type: Message_Type.DANGER, duration: 3000 });
                        }
                        this.modalService.presentModal({
                            component: CustomErrorModalComponent,
                            componentProps: {},
                            options: ModalService.fullPageOptions
                        }).then(response => {
                            response.modalObj.onDidDismiss().then(dismissProps => {
                                if (dismissProps) {
                                    this.refresh()
                                }
                            })
                        })
                    }
                })
            } else {
                observer.next(this.farmList);
            }
        });


    }

    getLastFarm(type: string = "trap") {
        if (type.includes("trap")) {
            return this.farmList.find(e => e.TempFarmAttId == this.storageService.lastTrapFarmId);
        } else {
            return this.farmList.find(e => e.TempFarmAttId == this.storageService.lastFilizFarmId);
        }
    }

    public filterFarmListWithDeviceType(type: string, farmList: Array<IFarmModel>) {
        if (type.includes("trap")) {
            return farmList.filter(e => e.Device.length > 0 && e.Device.filter(device => device.Type.includes("trap")).length > 0)
        } else {
            return farmList.filter(e => e.Device.length > 0 && e.Device.filter(device =>  !device.Type.includes("trap")).length > 0)
        }
    }


    public FarmSave(payload: IFarmSaveModel, deviceType: string) {
        let url = environment.baseApiUrl + "v2/api/farm/save/" + deviceType;
        return this.clientApi.baseApiCall<any>({ url, payload, requestType: HttpRequestTypes.POST })
    }

    public ParselRequest(payload: IParcelRequestModel) {
        let url = `${environment.baseApiUrl + "api/"}get/parcel/detail?placeId=${payload.placeId}&ada=${payload.ada}&parsel=${payload.parsel}`
        return this.clientApi.baseApiCall<IParcelResponseModel>({ url, payload: null, requestType: HttpRequestTypes.POST })
    }

    public FarmEditRequest(payload: IFarmEditModel) {
        let url = environment.baseApiUrl + "api/" + "update/farm/information";
        return this.clientApi.baseApiCall<IParcelResponseModel>({ url, payload, requestType: HttpRequestTypes.POST })
    }

    public ChangeArchiveStatus(tempFarmAttId, status) {
        let url = `${environment.baseApiUrl + "api/"}get/swap/activetoarchieve?tempFarmAttId=${tempFarmAttId}&isArchieved=${status}`
        return this.clientApi.baseApiCall<any>({ url, payload: null, requestType: HttpRequestTypes.GET });
    }

    public GetNotes(payload: FarmNotes) {
        let url = environment.baseApiUrl + "api/" + "get/farm/flagsv2"
        return this.clientApi.baseApiCall<Array<FarmNoteResponse>>({ url, payload: payload, requestType: HttpRequestTypes.POST })
    }

    public Geocoding(text: string, lang: string) {
        let url = "https://api.mapbox.com/geocoding/v5/mapbox.places/" + text + ".json?proximity=ip&types=place%2Cpostcode%2Caddress&access_token="
            + environment.mapbox.accessToken + "&language=" + lang;
        return this.clientApi.externalApiCall<Array<FarmNoteResponse>>({ url, payload: {}, requestType: HttpRequestTypes.GET })
    }

    public getSharingFarmList(isFilizmode: boolean) {
        let type = "";
        if (isFilizmode) {
            type = "filiz";
        } else {
            type = "trap";
        }
        let url = environment.baseApiUrl + "v2/api/farm/addable/device/list?type=" + type;
        return this.clientApi.baseApiCall<any[]>({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
    }

    public GetSprayingHours({ lat, lng }) {
        let url = `${environment.baseApiUrl}api/get/available/time?lat=${lat}&lng=${lng}&isOneWeek=true&isAllData=true`
        return this.clientApi.baseApiCall<IAvailableTimeModel>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }


    public SearchPlaces(query, types = 'place') {
        let lang = this.translateService.currentLang;
        let url = "https://api.mapbox.com/geocoding/v5/mapbox.places/" + query + ".json?limit=10&proximity=ip&types=" + types + "%2Cpostcode%2Caddress&access_token="
            + environment.mapbox.accessToken + "&language=" + lang;
        return this.clientApi.externalApiCall<any>({ url, payload: {}, requestType: HttpRequestTypes.GET })
    }

    public IsThereAnyDeletedFarm() {
        let url = environment.baseApiUrl + "v2/api/farm/shared/list/by/deleted/farmowner";
        return this.clientApi.baseApiCall<any>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }
}