import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { Camera } from '@capacitor/camera';
import { Observable } from "rxjs";
import { AndroidPermissionService, PermissionType } from "./android-permission.service";
import { Injectable } from "@angular/core";
import { IButtonOpts } from "../models/interfaces/modal-components-models";
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { TranslateService } from "@ngx-translate/core";
import { ClientAlertService } from "./client-alert.service";

type TPermissionTypes = "location" | "camera" | "photos"
const PLATFORM = Capacitor.getPlatform()
@Injectable({
    providedIn: "root"
})
export class PermissionService {
    public static instanceOfService: PermissionService
    /**
     *
     */
    constructor(private androidPermissionService: AndroidPermissionService, private openNativeSettings: OpenNativeSettings, private translate: TranslateService, private alertService: ClientAlertService) {
        PermissionService.instanceOfService = this
    }

    // public static async CheckAndRequestLocation({ granted, rejected }) {
    //     let permissionStatus = (await Geolocation.checkPermissions()).location
    //     if (permissionStatus == "granted") {
    //         return granted()
    //     } else {
    //         let requestStatus = (await Geolocation.requestPermissions()).location
    //         if (requestStatus == "granted")
    //             return granted()
    //         else
    //             return rejected(requestStatus)
    //     }
    // }
    public static async CheckAndRequestLocation({ granted, rejected }) {
        try {
            await Geolocation.checkPermissions().then(async permissionStatus => {
                if (permissionStatus.location == "granted") {
                    return granted()
                } else {
                    if (PLATFORM.toLocaleLowerCase() == "ios" || PLATFORM.toLocaleLowerCase() == "android")
                        await Geolocation.requestPermissions().then(requestStatus => {
                            if (requestStatus.location == "granted")
                                return granted()
                            else {
                                return rejected(requestStatus)
                            }
                        }).catch(err => {
                            console.log("err", err);
                        })
                    else {
                        return rejected("not_available_for_web")
                    }
                }
            })
        } catch (error) {
            return rejected("")
        }
    }

    requestCameraPermission(type: string): Observable<boolean> {
        return new Observable(observer => {
            switch (PLATFORM) {
                case "android":
                    try {
                        Camera.checkPermissions().then(e => {
                            if (type == "CAMERA") {
                                if (e.camera == "granted") {
                                    observer.next(true)
                                } else {
                                    this.androidPermissionService.requestPermisson(PermissionType.Camera).subscribe(res => {
                                        if (res) {
                                            observer.next(true);
                                        } else {
                                            this.permissionDeniedAlert("camera")
                                            observer.next(false);
                                        }
                                    });
                                }
                            } else if (type == "PHOTOS") {
                                if (e.photos == "granted") {
                                    observer.next(true)
                                } else {
                                    this.androidPermissionService.requestPermisson(PermissionType.Storage).subscribe(res => {
                                        if (res) {
                                            observer.next(true);
                                        } else {
                                            this.permissionDeniedAlert("photos")
                                            observer.next(false);
                                        }
                                    });
                                }
                            }
                        })
                    } catch (error) {

                    }
                    break;
                case "ios":
                    Camera.requestPermissions().then(e => {
                        if (e[type.toLocaleLowerCase()] == "granted") {
                            observer.next(true);
                        } else {
                            this.permissionDeniedAlert(type.toLowerCase() as TPermissionTypes)
                            observer.next(false);
                        }
                    })
                    break;
                case "web":
                    return observer.next(true)
                    break;
            }
        })
    }

    requestVideoPermission(): Observable<boolean> {
        return new Observable(observer => {
            switch (PLATFORM) {
                case "android":
                    try {
                        Camera.checkPermissions().then(e => {
                            if (e.photos == "granted") {
                                observer.next(true)
                            } else {
                                this.androidPermissionService.requestPermisson(PermissionType.Video).subscribe(res => {
                                    if (res) {
                                        observer.next(true);
                                    } else {
                                        this.permissionDeniedAlert("camera")
                                        observer.next(false);
                                    }
                                });
                            }
                        });
                    } catch (error) {
                        observer.next(false);
                    }
                    break;
                case "ios":
                    Camera.requestPermissions().then(e => {
                        if (e.camera == "granted") {
                            observer.next(true);
                        } else {
                            this.permissionDeniedAlert("camera")
                            observer.next(false);
                        }
                    })
                    break;
                case "web":
                    return observer.next(true)
                    break;
            }
        });
    }
    permissionDeniedAlert(type: TPermissionTypes) {
        let buttons: IButtonOpts[] = [
            {
                callback: () => {
                    this.openNativeSettings.open("application_details").then()
                },
                color: "primary",
                fill: "solid",
                text: this.translate.instant("permission_alerts.open_settings_btn")
            },
            {
                callback: () => { },
                color: "dark",
                fill: "clear",
                text: this.translate.instant("permission_alerts.close_btn")
            }]
        this.alertService.PresentAlert({
            buttons,
            description: this.translate.instant(`permission_alerts.${type}_permisson_denied_desc`),
            title: this.translate.instant(`permission_alerts.${type}_permisson_denied_title`)
        }, false).then()
    }

}