import { Component, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { AnalyticService } from 'src/app/core/services/analytic.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { UnitPreferenceService } from 'src/app/core/services/unit-preference.service';

@Component({
  selector: 'orbit-unit-pref-modal',
  templateUrl: './unit-pref-modal.component.html',
  styleUrls: ['./unit-pref-modal.component.scss'],
})
export class UnitPrefModalComponent extends BaseModal implements OnInit {


  newSelectedAreaUnit = null;
  newSelectedTemperatureUnit = null;
  newSelectedSpeedUnit = null;
  newSelectedRainfallUnit = null;
  constructor(private modalService: ModalService, private analyticService: AnalyticService, public unitPrefService: UnitPreferenceService,
    private loadingService: LoadingService) {
    super()
    super._modalService = modalService
    this.newSelectedAreaUnit = this.unitPrefService.selectedAreaUnit
    this.newSelectedTemperatureUnit = this.unitPrefService.selectedTemperatureUnit
    this.newSelectedSpeedUnit = this.unitPrefService.selectedSpeedUnit
    this.newSelectedRainfallUnit = this.unitPrefService.selectedRainfallUnit
  }

  ngOnInit() {
    super.ngOnInit()
  }

  saveChanges() {
    this.loadingService.showLoading();
    if(this.unitPrefService.selectedAreaUnit != this.newSelectedAreaUnit)
    this.unitPrefService.changeAreaUnit(this.newSelectedAreaUnit)
    if(this.unitPrefService.selectedTemperatureUnit != this.newSelectedTemperatureUnit)
    this.unitPrefService.changeTemperatureUnit(this.newSelectedTemperatureUnit)
    if(this.unitPrefService.selectedSpeedUnit != this.newSelectedSpeedUnit)
    this.unitPrefService.changeSpeedUnit(this.newSelectedSpeedUnit)
    if(this.unitPrefService.selectedRainfallUnit != this.newSelectedRainfallUnit)
    this.unitPrefService.changeRainfallUnit(this.newSelectedRainfallUnit)
    var units = `unitsChanged:${this.newSelectedAreaUnit},${this.newSelectedSpeedUnit},${this.newSelectedTemperatureUnit},${this.newSelectedRainfallUnit}`
    this.analyticService.sendState(units).subscribe(res => { }, err => { })
    this.dismiss({}, "changesSaved");
    setTimeout(() => {
      this.loadingService.hideLoading();
    }, 100);
  }

  resetValues() {
    this.newSelectedAreaUnit = this.unitPrefService.defaultValues[0]
    this.newSelectedTemperatureUnit = this.unitPrefService.defaultValues[2]
    this.newSelectedSpeedUnit = this.unitPrefService.defaultValues[1]
    this.newSelectedRainfallUnit = this.unitPrefService.defaultValues[3]
  }
}
