import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpRequestTypes } from '../models/enums/http-request-types';
import { IApiCallBase } from '../models/interfaces/api-call-base';
import { IExternalApiCallBase } from '../models/interfaces/external-api-call';
import { IGenericResponse } from '../models/interfaces/i-generic-response';
import { catchError, map, shareReplay } from "rxjs/operators";
import { LoadingService } from '../services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class ClientBaseApiService {

  private _externalHttpClient: HttpClient
  constructor(private httpClient: HttpClient, private handler: HttpBackend, private loadingService: LoadingService) {
    this._externalHttpClient = new HttpClient(handler);
  }

  public baseApiCall<T = any>(props: IApiCallBase): Observable<IGenericResponse<T>> {
    let request: Observable<IGenericResponse<T>> = null
    switch (props.requestType) {
      case HttpRequestTypes.GET:
        request = this.httpClient.get<IGenericResponse<T>>(props.url)
        break;
      case HttpRequestTypes.POST:
      default:
        request = this.httpClient.post<IGenericResponse<T>>(props.url, props.payload)
        break;
    }
    return new Observable<IGenericResponse<T>>(observer => {
      request.pipe(shareReplay()).subscribe(response => {
        observer.next(response);
      }, error => {
        this.loadingService.hideLoading();
        let errorObj = { IsSuccess: false, Data: error, Message: "http_request_unexpected_error", StatusCode: -1 } as IGenericResponse<any>
        observer.next(errorObj)
      }, () => {
        observer.complete();
      })
    })
  }

  public externalApiCall<T>(props: IExternalApiCallBase): Observable<any> {
    var headers: HttpHeaders = new HttpHeaders();
    if (props.responseType)
      headers.append("Response-Type", props.responseType)
    switch (props.requestType) {
      case HttpRequestTypes.POST:
        return this._externalHttpClient.post(props.url, props.payload, { headers });
        break;
      case HttpRequestTypes.GET:
        return this._externalHttpClient.get(props.url, { headers: headers });
        break;
      default:
        const error: IGenericResponse<any> = {
          Data: null,
          IsSuccess: false,
          Message: "no_request_type",
          StatusCode: "-1"
        }
        return new Observable<any>(observer => {
          observer.error(error);
          observer.complete()
        })
        break;
    }
  }

  public serviceCallExternal(url: string, payload: any, op: string): Observable<any> {
    payload = payload || {};
    //var baseAddress = environment.baseUrl;
    if (op == 'post') {
      return new Observable(observer => {
        this.httpClient
          .post<any>(url, payload)
          .subscribe(data => {
            observer.next(data);
          },
            err => {
              observer.next(false);
            }, () => {
              observer.complete();
            });
      });
    } else {
      return new Observable(observer => {
        this.httpClient
          .get<any>(url)
          .subscribe(data => {
            observer.next(data);
          },
            err => {
              observer.error(err);
            }, () => {
              observer.complete();
            });
      });
    }
  }
  checkFileExist(filepath: string) {
    return this.httpClient.get(filepath, { observe: 'response', responseType: 'blob' }).pipe(map(response => {
      return filepath
    }),
      catchError(error => {
        return of(error)
      })
    )
  }

}
