import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthApiService } from 'src/app/core/api/auth-api.service';
import { OrbitFormValidators } from 'src/app/core/helper/class/form-validators';
import { UserAgreementComponent } from 'src/app/core/helper/components/user-agreement/user-agreement.component';
import { Message_Type } from 'src/app/core/models/enums/message-type';
import { ComponentType } from 'src/app/core/models/enums/phone-input-component-types';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { IRegisterModel } from 'src/app/core/models/interfaces/register-model';
import { AccountService } from 'src/app/core/services/account.service';
import { LanguageService } from 'src/app/core/services/lang.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { NotificationService } from 'src/app/core/services/notification-service';
import { StorageService } from 'src/app/core/services/storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', '../../auth.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, AfterViewInit {
  registerForm: FormGroup;
  countryCodes: string = "tr"
  componentType = ComponentType.FULL_INPUT_SEPERATED
  passwordVisibility: boolean = false;
  userInfo: any;
  @Input() socialUserInfo;
  loginWithApple: boolean = false;
  constructor(private formBuilder: FormBuilder, private router: Router,
    private langService: LanguageService, private accountService: AccountService,
    private modalService: ModalService, private storageService: StorageService,
    private toastService: ToastService, private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService, private authApi: AuthApiService,
    private notificationService: NotificationService, private changeDetRef: ChangeDetectorRef,
    private translate: TranslateService) { }
  ngAfterViewInit(): void {
    this.registerDocListeners()
    this.translate.onLangChange.subscribe(res => {
      this.changeDetRef.detectChanges();
      this.registerDocListeners();
    })
  }

  userAgreementTemplate = "<a class='btn_userAgreement'>" + this.langService.getTranslation("general.userAgreement") + "</a>"
  kvkkTemplate = "<a class='btn_kvkk'>" + this.langService.getTranslation("general.kvkk") + "</a>"
  iysTemplate = "<a class='btn_iys'>" + this.langService.getTranslation("general.iys") + "</a>"


  ngOnInit() {
    this.setupForm();
    this.userInfo = this.activatedRoute.snapshot.queryParams;
    if (this.userInfo.socialLogin == "true" || this.userInfo.socialLogin == true) {
      // this.toastService.PresentBasicToast({ message: this.langService.getTranslation("register.fb-register-message"), duration: 3000, type: Message_Type.WARNING })

    }
  }


  setupForm() {
    var that = this
    this.registerForm = this.formBuilder.group({
      namesurname: [this.socialUserInfo ? this.socialUserInfo.NameSurname : "", [OrbitFormValidators.nameSurnameValidator(), Validators.required]],
      phone: ["", [OrbitFormValidators.phoneNumberValidator(), Validators.required]],
      email: [this.socialUserInfo ? this.socialUserInfo.Email : "", [OrbitFormValidators.emailValidation(), Validators.required]],
      password: [this.socialUserInfo ? "147258" : "", [(ev) => {
        if (that.registerForm) {
          let control = that.registerForm.controls["passwordCheck"]
          setTimeout(() => {
            if (control.value && control.value !== '') {
              control.markAsDirty({ onlySelf: true })
              control.enable({ emitEvent: true, onlySelf: true })
            }
          }, 500);
          let response = OrbitFormValidators.mismatchPasswordValidation(control)
          return response(ev)
        } else
          return null
      }, Validators.minLength(6), Validators.required]],
      passwordCheck: [this.socialUserInfo ? "147258" : "", [(ev) => {
        if (that.registerForm) {
          let control = that.registerForm.controls["password"]
          setTimeout(() => {
            if (control.value && control.value !== '') {
              control.markAsDirty({ onlySelf: true })
              control.enable({ emitEvent: true, onlySelf: true })
            }
          }, 500);
          let response = OrbitFormValidators.mismatchPasswordValidation(control)
          return response(ev)
        }
        else
          return null
      }, Validators.minLength(6), Validators.required]],
      refCode: [''],
      agreementConfirmation: [false, [Validators.required]],
    });
    this.socialType = this.socialUserInfo ? this.socialUserInfo.Type : null;
    if (this.socialType == "apple") this.loginWithApple = true;
    this.socialToken = this.socialUserInfo ? this.socialUserInfo.Id : null;
  }
  registerDocListeners() {
    document.getElementsByClassName("btn_userAgreement")[0]?.addEventListener("click", (e) => {
      this.modalService.presentModal({
        component: UserAgreementComponent,
        componentProps: {
          document: environment.termsOfUse,
          title: this.langService.getTranslation("auth.terms-of-use"),
        },
        options: {}
      }).then(modalFP => {
        modalFP.modalObj.onDidDismiss().then(dismiss => {
          this.registerForm.controls["agreementConfirmation"].setValue(dismiss.data);
        });
      });
    });
    document.getElementsByClassName("btn_kvkk")[0]?.addEventListener("click", (ev) => {
      this.modalService.presentModal({
        component: UserAgreementComponent,
        componentProps: {
          document: environment.kvkk,
          title: this.langService.getTranslation("auth.kvkk_header"),
        },
        options: {}
      }).then(modalFP => {
        modalFP.modalObj.onDidDismiss().then(dismiss => {
          this.registerForm.controls["agreementConfirmation"].setValue(dismiss.data);
        });
      });
    });
    document.getElementsByClassName("btn_iys")[0]?.addEventListener("click", (ev) => {
      this.modalService.presentModal({
        component: UserAgreementComponent,
        componentProps: {
          document: environment.commercialElectronicMessage,
          title: this.langService.getTranslation("auth.commercial_electronic_message_agreement"),
        },
        options: {}
      }).then(modalFP => {
        modalFP.modalObj.onDidDismiss().then(dismiss => {
          this.registerForm.controls["agreementConfirmation"].setValue(dismiss.data);
        });
      });
    });
  }

  currentCountryInfo
  dialCode
  lastPhonenumber: string = ""

  phoneInputChanged(ev, type) {
    switch (type) {
      case "countryChanged":
        this.currentCountryInfo = ev;
        this.dialCode = ev.dialCode
        break;
      case "phoneChanged":
        let phone: string = ev.parsedNumber;
        this.lastPhonenumber = phone
        if (this.currentCountryInfo) {
          this.registerForm.patchValue({ phone: phone }, { emitEvent: true })
        } else {

        }
        break;
      case "phoneValidationChanged":
        if (!ev) {
          this.registerForm.patchValue({ phone: null }, { emitEvent: true })
        }
        break;
      default:
        break;
    }
  }
  socialType: "facebook" | "apple" | "google";
  socialToken: string;
  submitRegister() {
    if (this.registerForm.valid) {
      var registerControls = this.registerForm.controls
      if (registerControls["agreementConfirmation"].value) {
        let payload = {
          NameSurname: registerControls["namesurname"].value,
          PhoneNumber: this.dialCode + registerControls["phone"].value,
          Mail: registerControls["email"].value,
          Password: registerControls["password"].value,
          SocialType: this.socialType,
          SocialToken: this.socialToken
          // confirmPassword: registerControls["passwordCheck"].value,
          // isConfirm: registerControls["agreementConfirmation"].value
        } as IRegisterModel;
        // if (this.userInfo.fbId > 0) {
        //   this.loadingService.hideLoading();
        //   this.authApi.FacebookRegister(this.registerForm.controls["namesurname"].value, this.userInfo.mail, this.dialCode + this.registerForm.controls["phone"].value, this.userInfo.fbId).subscribe(res => {
        //     if (res.IsSuccess) {
        //       this.notificationService.pushNotificationOperations();
        //       this.storageService.set(StorageKeys.USER, res.Data).then(e => { })
        //       this.storageService.set(StorageKeys.TOKEN, res.Data.Token).then();
        //       this.storageService.set(StorageKeys.WELCOME, true).then(e => {
        //         this.router.navigate([""]);
        //       });
        //       this.storageService.set(StorageKeys.FIRST_LOGIN, true).then();
        //     } else {
        //       this.toastService.PresentBasicToast({ message: res.Message, duration: 5000, type: Message_Type.WARNING })
        //     }
        //   });
        // } else {

        this.loadingService.showLoading();
        this.accountService.Register(payload).subscribe(response => {
          this.loadingService.hideLoading();
          if (response.IsSuccess) {
            this.notificationService.pushNotificationOperations();
            this.storageService.set(StorageKeys.FIRST_LOGIN, true).then();
            this.storageService.set(StorageKeys.WELCOME, true).then(e => {
              this.router.navigate([""], {
                replaceUrl: true
              });
            });
          } else {
            this.toastService.PresentBasicToast({
              message: this.langService.getTranslation("errors." + response.Message),
              type: Message_Type.DANGER
            }).then(response => {

            });
          }
        }, err => {
          this.loadingService.hideLoading();
        });
        // }
      }
    }
  }
  emailOnBlur(ev) {
    let email = this.registerForm.get("email").value as string
    if (email) {
      email = email.trim();
      this.registerForm.patchValue({ email }, { emitEvent: true, onlySelf: true })
    }
  }
  setAutofillValue(ev, fieldName) {
    let fieldObj = {}
    fieldObj[fieldName] = ev.target.value
    this.registerForm.patchValue(fieldObj, { emitEvent: true, onlySelf: true })
  }
}
