import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { Capacitor } from '@capacitor/core';
import { AccountService } from './account.service';
import { UnitPreferenceService } from './unit-preference.service';
import { UnitKeys } from '../models/enums/unit-keys';


@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private accountService: AccountService, private storageService: StorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.baseApiUrl)) {


      let devicePlatform = Capacitor.getPlatform(); //Capacitor.platform ?? 'other';
      let headers =
        [
          { name: "platform", value: "mobile" },
          { name: "apiVersion", value: "1.1" },
          { name: "roleId", value: "37" },
          { name: "companyId", value: environment.companyId }, //"5"
          { name: "campaignId", value: environment.campaignId }, //"110"
          { name: "lang", value: this.accountService.lang || "en" },
          { name: "devicePlatform", value: devicePlatform },
          // { name: "speedUnit", value: this.unitService.selectedSpeedUnit || "km/h" },
          // { name: "areaUnit", value: this.unitService.selectedAreaUnit || "da" },
          // { name: "temperatureUnit", value: this.unitService.selectedTemperatureUnit || "C" },
          // { name: "rainfallUnit", value: this.unitService.selectedRainfallUnit || "mm" }
        ]
      let tokenValue = this.accountService.token;
      if (tokenValue) {
        headers.push({ name: "token", value: tokenValue });
      }
      let deviceToken = StorageService.DeviceToken;
      if (deviceToken) {
        headers.push({ name: "deviceToken", value: deviceToken })
      }
      // else {
      //   headers.push({ name: "deviceToken", value: "eJV2YwwqW0mXvl3VuOAy1H:APA91bFD_W_UnY6RY6VdR0BDNgLYdafSODJgCu4o7mVEfVmwv99n_Oc14CqIm7ZEmlq7iDblW0l_WtPMuseprZPjwj0CYGkUxbUTzQBEnyZMOb03ti-T6JIm_Vba3l2am_MXR7kUx0vo" })
      // }


      if (AccountService.selectedSpeedUnit) headers.push({ name: "speedUnit", value: AccountService.selectedSpeedUnit })
      if (AccountService.selectedAreaUnit) headers.push({ name: "areaUnit", value: AccountService.selectedAreaUnit })
      if (AccountService.selectedTemperatureUnit) headers.push({ name: "temperatureUnit", value: AccountService.selectedTemperatureUnit })
      if (AccountService.selectedRainfallUnit) headers.push({ name: "rainfallUnit", value: AccountService.selectedRainfallUnit })


      let reqHeaders = req.headers;
      headers.forEach(el => {
        reqHeaders = reqHeaders.set(el.name, el.value)
      })
      let _req = req.clone({
        headers: reqHeaders
      });
      return next.handle(_req);
    } else
      return next.handle(req);
  }
}

