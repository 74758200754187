import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { IToastModel } from "../models/interfaces/toast-model";

@Injectable({
    providedIn:"root"
})
export class ToastService{
    toasts: Array<any> = []

    /**
     *
     */
    constructor(private toastController: ToastController) {
        
    }

    async PresentBasicToast(props:IToastModel) {
        const toast = await this.toastController.create({
            message: props.message,
            duration: props.duration || 3000,
            mode:"ios",
            cssClass: props.class,
            color: props.type?.valueOf()
        });
        return await toast.present();
    }

    async PresentToastWithCallback(props:IToastModel){
        const toast = await this.toastController.create({
            message: props.message,
            duration: props.duration || 3000,
            mode:"md",
            cssClass: props.class,
            color: props.type?.valueOf(),
            buttons: [{
                role: props.buttonRole || "cancel",
                side: props.iconSide || "end",
                icon:props.buttonIcon || "close",
                handler: () => {
                    props.callback()
                }
            }]
        });
        return await toast.present();
    }


}