import { Component, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-custom-error-modal',
  templateUrl: './custom-error-modal.component.html',
  styleUrls: ['./custom-error-modal.component.scss'],
})
export class CustomErrorModalComponent extends BaseModal implements OnInit {

  constructor(private modalService: ModalService) { 
    super();
    super._modalService = modalService;
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
