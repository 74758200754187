import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Network } from "@capacitor/network"
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { NetworkAlertModalComponent } from '../helper/components/network-alert-modal/network-alert-modal.component';
import { LanguageService } from './lang.service';
// import { NetworkModalComponent } from 'src/app/common/component/network-modal/network-modal.component';

@Injectable({
    providedIn: 'root'
})
export class NetworkService {

    private static subject: Subject<any> = new Subject<any>();
    // private static _networkListener: Observable<any>;
    public static get networkListener(): Observable<any> {
        return this.subject.asObservable();
    }
    public static set setState(value: string) {
        this.subject.next(value)
    }
    // public static set networkListener(value: Observable<any>) {
    //     NetworkService._networkListener = value;
    // }
    private modal: HTMLIonModalElement
    isModalOn: boolean = false;
    isConnectionActive: boolean = true
    constructor(private modalController: ModalController, private translateService: TranslateService) {
    }

    initiateNetworkListener() {
        let handler = Network.addListener('networkStatusChange', async (status) => {
            if (!status.connected) {
                if (this.isConnectionActive) {
                    if (!this.modal && !this.isModalOn) {
                        let modalId = Date.now()
                        this.modal = await this.modalController.create({
                            component: NetworkAlertModalComponent,
                            componentProps: { id: modalId },
                            cssClass: "modal-fullscreen modal-networkalert",
                            backdropDismiss: false,
                            keyboardClose: false,
                            swipeToClose: false
                        });
                        await this.modal.present();
                        this.isModalOn = true
                        this.modal.onDidDismiss().then(res => {
                            this.isModalOn = false;
                        })
                        NetworkService.subject.next("offline")
                    }
                }
                this.isConnectionActive = false
            } else {
                // this.translateService.resetLang(LanguageService.currentLanguage.code)
                this.translateService.reloadLang(this.translateService.currentLang).subscribe(res => {
                    this.translateService.onLangChange.next({ lang: this.translateService.currentLang, translations: res })
                })
                if (!this.isConnectionActive) {
                    NetworkService.subject.next("online");
                    if (this.modal) {
                        await this.modal.dismiss();
                    } else {
                    }
                }
                this.isConnectionActive = true
            }
        });
        handler.then(res => {

        })
        // To stop listening:
        // handler.remove();
    }

    check() {

        // Get the current network status
        Network.getStatus().then(async e => {
            if (!e.connected) {
                if (!this.modal && !this.isModalOn) {
                    let modalId = Date.now()
                    this.modal = await this.modalController.create({
                        component: NetworkAlertModalComponent,
                        cssClass: "modal-fullscreen modal-networkalert",
                        backdropDismiss: false,
                        keyboardClose: false,
                        swipeToClose: false
                    });
                    await this.modal.present();
                    this.isModalOn = true
                    this.modal.onDidDismiss().then(res => {
                        this.isModalOn = false;
                    })
                    NetworkService.subject.next("offline")
                }
            } else {
                NetworkService.subject.next("online");

                setTimeout(() => {
                    this.translateService.reloadLang(this.translateService.currentLang).subscribe(res => {
                        this.translateService.onLangChange.next({ lang: this.translateService.currentLang, translations: res })
                    })
                }, 1000);
                if (this.modal) {
                    await this.modal.dismiss();
                } else {
                }
            }
        });

    }


}