// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseApiUrl: "https://api.doktarim.com/",
  companyId: "5",
  campaignId: "110",
  i18nUrl: "https://doktarbase.blob.core.windows.net/legacy/lang/iotrack/i18n/",
  // i18nUrl: "./assets/i18n/",
  mapbox: {
    accessToken: "pk.eyJ1IjoibXN0ZmNpbmFyIiwiYSI6ImNrbHQ3bGhjeTIzcDUydnF5c3BvZHE0bm8ifQ.ms7GiuDSI8ZqDI-EFp_D1Q"
  },
  constantJsonUrl: "https://doktarbase.blob.core.windows.net/legacy/lang/orbit/constants.json",
  // constantJsonUrl: "./assets/constants.json",
  mapLayerStyle: { fillColor: "#000000", opacity: 0.5, lineColor: "#FFFFFF", lineWidth: 2 },
  countryFlags: "https://doktarbase.blob.core.windows.net/legacy/software/content/images/country-flags/",
  countryList: "https://doktarbase.blob.core.windows.net/legacy/software/content/images/country-flags/country-list.json",
  baseImageUrl: "https://doktarbase.blob.core.windows.net/legacy/software/content/",
  homeMapZoomLimit: 0,
  agreementsBaseUrl: "https://c.doktar.io/apps/agreements/",
  commercialElectronicMessage: "CommercialElectronicMessage",
  termsOfUse: "Kullanim_Kosullari_Kullanici_Sozlesmesi",
  kvkk: "kvkk",
  UserManualBaseUrl: "https://doktarbase.blob.core.windows.net/legacy/lang/iotrack/user-manuals/",
  imageUrl: "../../assets/images/",
  appVersion: 1,

  appInsights: {
    instrumentationKey: '50181bc8-294e-4b62-9abf-eb60cb3a47f6'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
