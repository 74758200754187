import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deviceInfo'
})
export class DeviceInfoPipe implements PipeTransform {

  transform(value: unknown, type: string): unknown {
    if (type == "network") {
      if (value == "very good" || value == "good") {
        return "#417B32"
      } else if (value == "low" || value == "very low") {
        return "#ED962F"
      } else if (value == "no-signal") {
        return "#ED2F2F"
      }
    } else if (type == "battery") {
      if (value > 60) {
        return "#417B32"
      } else if (value <= 60 && value > 15) {
        return "#ED962F"
      } else if (value <= 15) {
        return "#ED2F2F"
      }
    }
  }

}
