import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-custom-bottom-alert',
  templateUrl: './custom-bottom-alert.component.html',
  styleUrls: ['./custom-bottom-alert.component.scss'],
})
export class CustomBottomAlertComponent extends BaseModal implements OnInit {
  @Input() image: string;
  @Input() title: string;
  @Input() description: string;
  @Input() buttonName: string;
  @Input() imageHeight: number;
  constructor(private modalService: ModalService) {
    super()
    super._modalService = modalService
  }

  ngOnInit() {
    super.ngOnInit();
  }

  dismissModal() {
    super.dismiss({}, '');
  }
}
