import { Component, OnInit } from '@angular/core';
import { BaseAlertModal } from 'src/app/core/models/classes/base-alert-modal';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss'],
})
export class PopupModalComponent extends BaseAlertModal implements OnInit {
  icon: string;
  role: string;
  constructor(private modalService: ModalService) {
    super(modalService)
    super._modalService = modalService
  }

  ngOnInit() { }
  dismissModal(id) {
    this.modalService.dismissByModalId(id, {}).then(response => { })
  }
  buttonClick(ev, prop) {
    prop.callback(ev);
  }
}
