import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/lang.service';

@Component({
  selector: 'app-language-button',
  templateUrl: './language-button.component.html',
  styleUrls: ['./language-button.component.scss'],
})
export class LanguageButtonComponent implements OnInit {
  @Input() color: string = "black";
  constructor(public langService: LanguageService,public translateService:TranslateService) { }

  ngOnInit() { }

  openModal() {
    this.langService.openOptionsMenu()
  }

}
