import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'empty-page',
  templateUrl: './empty-page.component.html',
  styleUrls: ['./empty-page.component.scss'],
})
export class EmptyPageComponent implements OnInit {
  @Input() image: string = "";
  @Input() header: string = "";
  @Input() content: string = "";
  @Input() button: string = "";
  @Output() onClickButton: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() { }

  emptyButton() {
    this.onClickButton.emit(true);
  }
}
