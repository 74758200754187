import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientBaseApiService } from 'src/app/core/api/client-base-api.service';
import { ProfileUser, UserData } from 'src/app/core/models/classes/user-data';
import { HttpRequestTypes } from 'src/app/core/models/enums/http-request-types';
import { LanguageService } from 'src/app/core/services/lang.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private clientApiService: ClientBaseApiService, private translate: TranslateService) { }

  getUserDetail() {
    let url = environment.baseApiUrl + "v2/api/user/currentuser/information";
    return this.clientApiService.baseApiCall<ProfileUser>({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
  }

  getTrapFAQuestions() {
    let lang = this.translate.currentLang;
    if (!lang) lang = "en";
    return this.clientApiService.externalApiCall({ url: "https://doktarbase.blob.core.windows.net/legacy/lang/pesttrap/faq/" + lang + ".json", payload: {}, requestType: HttpRequestTypes.GET });
  }

  getFilizFAQQuestions() {
    let lang = this.translate.currentLang;
    if (!lang) lang = "en";
    return this.clientApiService.externalApiCall({ url: "https://doktarbase.blob.core.windows.net/legacy/lang/filiz-reproduction/faq/faq.json", payload: {}, requestType: HttpRequestTypes.GET });
  }

  changePassword(oldPassword: string, newPassword: string) {
    let url = environment.baseApiUrl + "v2/api/user/change/password";
    let payload = {
      "OldPassword": oldPassword,
      "NewPassword": newPassword
    }
    return this.clientApiService.baseApiCall({ url: url, payload: payload, requestType: HttpRequestTypes.POST });
  }

  getNotifications() {
    let url = environment.baseApiUrl + "v2/api/notification/user/permission/list/v2"
    return this.clientApiService.baseApiCall({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
  }

  saveNotificationPermission(payload) {
    let url = environment.baseApiUrl + "v2/api/notification/save/user/permession/v2";
    return this.clientApiService.baseApiCall({ url: url, payload: payload, requestType: HttpRequestTypes.POST });
  }

  UpdateUserProfilePhoto(userRoleId, photo) {
    let url = environment.baseApiUrl + "/api/user/update/photo";
    return this.clientApiService.baseApiCall({ url: url, payload: { userRoleId: userRoleId, photo: photo }, requestType: HttpRequestTypes.POST });
  }
  UpdateUserInfos(data: any) {
    let url = environment.baseApiUrl + "/v2/api/user/update/user/detail";
    return this.clientApiService.baseApiCall({ url: url, payload: data, requestType: HttpRequestTypes.POST });
  }
  getDeviceTrapDetail(deviceId: number) {
    let url = environment.baseApiUrl + "v2/api/device/trap/detail?deviceId=" + deviceId;
    return this.clientApiService.baseApiCall({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
  }

  getDeviceFilizDetail(deviceId: number) {
    let url = environment.baseApiUrl + "v2/api/device/filiz/detail?deviceId=" + deviceId;
    return this.clientApiService.baseApiCall({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
  }

  saveResponseValue(payload) {
    let url = environment.baseApiUrl + "v2/api/device/trap/update/response/value";
    return this.clientApiService.baseApiCall({ url: url, payload: payload, requestType: HttpRequestTypes.POST });
  }

  setUserPhoto(payload, userRoleId) {
    let url = environment.baseApiUrl + "api/update/user/information?userRoleId=" + userRoleId;
    return this.clientApiService.baseApiCall({ url: url, payload: payload, requestType: HttpRequestTypes.POST });
  }
}
