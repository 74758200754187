import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Media } from "@capacitor-community/media";
import { Camera, CameraResultType, CameraSource, Photo } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { MediaCapture } from "@ionic-native/media-capture/ngx";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ClientBaseApiService } from "../api/client-base-api.service";
import { AddMediaSheetComponent } from "../helper/components/add-media-sheet/add-media-sheet.component";
import { AddPhotoSheetComponent } from "../helper/components/add-photo-sheet/add-photo-sheet.component";
import { DrawingComponent } from "../helper/components/drawing/drawing.component";
import { GalleryModalComponent } from "../helper/components/gallery-modal/gallery-modal.component";
import { MediaGalleryModalComponent } from "../helper/components/media-gallery-modal/media-gallery-modal.component";
import { HttpRequestTypes } from "../models/enums/http-request-types";
import { IGenericResponse } from "../models/interfaces/i-generic-response";
import { INewImageModel } from "../models/interfaces/new-image-model";
import { UserPhoto } from "../models/interfaces/user-photo";
import { LanguageService } from "./lang.service";
import { ModalService } from "./modal.service";
import { PermissionService } from "./permission.service";

const PLATFORM = Capacitor.getPlatform();
// const media = Media;
@Injectable({
    providedIn: "root"
})
export class MediaService {
    state: string;
    imageSrc: any = null;
    blobData: any = null;
    imageFormat: any = null;
    public photos: UserPhoto[] = [];
    constructor(private clientApi: ClientBaseApiService,
        private modalService: ModalService,
        private sanitizer: DomSanitizer,
        private permissionService: PermissionService,
        private mediaCapture: MediaCapture,
        private translateService: TranslateService) {

    }

    uploadPhoto(url: string, data: FormData): Observable<IGenericResponse> {
        // "save/photos/1/v2"
        return new Observable(observer => {
            this.clientApi.baseApiCall({ url, payload: data, requestType: HttpRequestTypes.POST }).subscribe(res => {
                observer.next(res);
                observer.complete();
            },
                err => {
                    err = {
                        IsSuccess: false,
                        Message: err.message,
                        Data: null,
                        StatusCode: 100
                    } as IGenericResponse
                    observer.next(err);
                    observer.complete();
                    return;
                }, () => {
                });
        });
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    openAddMediaSheet() {
        return new Observable(observer => {
            this.modalService.presentModal({
                component: AddMediaSheetComponent,
                componentProps: {},
                options: ModalService.defaultOptionsTransparent,
            }).then(response => {
                response.modalObj.onDidDismiss().then(dismiss => {
                    if (dismiss.role === 'itemSelected') {
                        observer.next(dismiss.data.key);
                        observer.complete()
                    } else {
                        observer.next();
                        observer.complete()
                    }
                }).catch(err => {
                    observer.error(err)
                    observer.complete()
                })
            }).catch(err => {
                observer.error(err)
                observer.complete()
            });
        });
    }
    openAddPhotoSheet() {
        return new Observable(observer => {
            this.modalService.presentModal({
                component: AddPhotoSheetComponent,
                componentProps: {},
                options: ModalService.defaultOptionsTransparent,
            }).then(response => {
                response.modalObj.onDidDismiss().then(dismiss => {
                    if (dismiss.role === 'itemSelected') {
                        this.permissionService.requestCameraPermission(dismiss.data.key).subscribe(res => {
                            if (res) {
                                Camera.getPhoto({
                                    quality: 100,
                                    allowEditing: false,
                                    resultType: CameraResultType.Base64,
                                    source: dismiss.data.key,
                                    width: window.innerWidth,
                                    height: window.innerHeight - 200
                                }).then(res => {
                                    this.openDrawingModal(res).then((drawingResponse: any) => {
                                        observer.next(drawingResponse);
                                        observer.complete();
                                    })
                                }).catch(err => {
                                    observer.error(err);
                                    observer.complete();
                                })
                            }
                        })

                    } else {
                        observer.next(dismiss.data);
                        observer.complete()
                    }
                }).catch(err => {
                    observer.error(err)
                    observer.complete()
                })
            }).catch(err => {
                observer.error(err)
                observer.complete()
            })
        })
    }

    openDrawingModal(prop) {
        let imageSrc = `data:image/${prop.format};base64,` + prop.base64String
        let imageObj: INewImageModel = {
            id: Date.now(),
            src: `data:image/${prop.format};base64,${prop.base64String}`,
            image: this.b64toBlob(prop.base64String, `image/${prop.format}`),
            format: prop.format,
            type: 'new',
            mediaType: 'image'
        }
        return new Promise((resolve, reject) => {
            this.modalService.presentModal({
                component: DrawingComponent,
                componentProps: { imageUrl: imageSrc },
                options: ModalService.fullPageOptions,
                id: Date.now()
            }).then(modalResponse => {
                modalResponse.modalObj.onDidDismiss().then(async dismiss => {
                    if (dismiss.data) {
                        if (dismiss.role === 'canceled')
                            resolve(imageObj)
                        else {
                            imageObj.image = dismiss.data
                            let base64Resp = imageSrc
                            try {
                                base64Resp = await this.convertBlobToBase64(dismiss.data) as string
                            } catch (error) {
                                console.error(error)
                            }
                            imageObj.src = base64Resp
                            resolve(imageObj)
                        }
                    }
                    else
                        resolve(imageObj)
                }).catch(err => {
                    resolve(imageObj)
                })
            }).catch(err => {
                resolve(imageObj)
            })
        })
    }
    openGalleryModal(props) {
        return this.modalService.presentModal({
            component: GalleryModalComponent,
            componentProps: props,
            options: ModalService.defaultOptionsTransparent
        })
    }

    openMediaGalleryModal(props) {
        return this.modalService.presentModal({
            component: MediaGalleryModalComponent,
            componentProps: props,
            options: ModalService.defaultOptionsTransparent
        })
    }

    deleteFarmPhoto(payload) {
        let url = environment.baseApiUrl + "v2/api/farm/flags/photos/delete";
        return this.clientApi.baseApiCall({ url, payload: payload, requestType: HttpRequestTypes.POST })
    }


    captureVideo(): Observable<any> {
        return new Observable(observer => {
            this.state = 'will capture';
            try {
                this.permissionService.requestVideoPermission().subscribe(permission => {
                    if (permission) {
                        this.mediaCapture.captureVideo().then(capture => {
                            let capturedVid = capture[0];
                            let localVideoPath = Capacitor.getPlatform() === "ios" ? capturedVid.localURL : capturedVid.fullPath;
                            // let directoryPath = localVideoPath.substr(0, localVideoPath.lastIndexOf('/'));
                            // let fileName = localVideoPath.substr(localVideoPath.lastIndexOf('/') + 1);
                            let type = capturedVid.name.split(".").reverse()[0]
                            this.saveVideo(localVideoPath, type).subscribe(e => {
                                this.imageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(Capacitor.convertFileSrc(localVideoPath))
                                this.imageFormat = type //capturedVid.type;
                                let imageObj: INewImageModel = {
                                    id: Date.now(),
                                    src: this.imageSrc,
                                    image: this.blobData,
                                    format: this.imageFormat,
                                    type: 'new',
                                    mediaType: 'video'
                                }
                                observer.next(imageObj);
                                observer.complete();
                            });
                        })
                        this.state = 'captured';
                    }
                    else {
                        observer.next(false);
                    }
                })

            } catch (e) {
                console.error(e);
                this.state = e;
                observer.next(false);
            }
        })

    }

    captureAudio(): Observable<any> {
        return new Observable(observer => {
            this.state = 'will capture';
            try {
                this.permissionService.requestVideoPermission().subscribe(permission => {
                    if (permission) {
                        this.mediaCapture.captureAudio().then(capture => {
                            let capturedVid = capture[0];
                            let localVideoPath = Capacitor.getPlatform() === "ios" ? capturedVid.localURL : capturedVid.fullPath;
                            let directoryPath = localVideoPath.substr(0, localVideoPath.lastIndexOf('/'));
                            let fileName = localVideoPath.substr(localVideoPath.lastIndexOf('/') + 1);
                            let type = capturedVid.name.split(".").reverse()[0]
                            this.saveVideo(localVideoPath, type).subscribe(e => {
                                this.imageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(localVideoPath)
                                this.imageFormat = type //capturedVid.type;
                                let imageObj: INewImageModel = {
                                    id: Date.now(),
                                    src: this.imageSrc,
                                    image: this.blobData,
                                    format: this.imageFormat,
                                    type: 'new',
                                    mediaType: 'voice'
                                }
                                observer.next(imageObj);
                                observer.complete();
                            });
                        })
                        this.state = 'captured';
                    }
                    else {
                        observer.next(false);
                    }
                })

            } catch (e) {
                console.error(e);
                this.state = e;
                observer.next(false);
            }
        })

    }

    saveVideo(video: any, format = "mp4"): Observable<any> {
        return new Observable(observer => {
            this.readAsBase64Video(video).then(base64Data => {
                const fileName = new Date().getTime() + '.' + format;
                Filesystem.writeFile({
                    path: `${fileName}`,
                    data: base64Data,
                    directory: Directory.Data
                }).then(savedFile => {
                    Filesystem.readFile({
                        path: `${fileName}`,
                        directory: Directory.Data
                    }).then(data => {
                        this.blobData = this.b64toBlob(data.data);
                        observer.next(this.blobData);
                    });
                });
            });
        });
    }

    async savePicture(photo: string) {
        // if (Capacitor.getPlatform() === "ios") {
        //     let albums = await Media.getAlbums()
        //     if (!albums.albums.some(t => t.name == "IoTrack Images")) {
        //         await Media.createAlbum({ name: "IoTrack Images" })
        //     }
        // }
        let albumName = this.translateService.instant("history.album-name");;
        let albumIdentifier = '';
        if (Capacitor.getPlatform() === 'ios') {
            // Handle albums
            let albums = await Media.getAlbums();
            albumIdentifier = albums.albums.find(a => a.name === albumName)?.identifier || null;

            if (!albumIdentifier) {
                // Doesn't exist, create new album
                await Media.createAlbum({ name: albumName });
                albums = await Media.getAlbums();
                albumIdentifier = albums.albums.find(a => a.name === albumName)?.identifier;
            }
            albumName = albumIdentifier
        }
        let props = {
            path: photo,
            album: albumName
        }
        if (Capacitor.getPlatform() === "android")
            props["album"] = this.translateService.instant("history.album-name");
        try {
            let res = await Media.savePhoto(props)
            console.log("res => ", res)
            return res
        } catch (error) {
            console.log("error => ", error)
            return error
        }
    }
    private async readAsBase64Video(video: any) {
        if (PLATFORM !== 'web') {
            const file = await Filesystem.readFile({
                path: video
            });
            return file.data;
        }
        else {
            const response = await fetch(video);
            const blob = await response.blob();
            return await this.convertBlobToBase64(blob) as string;
        }
    }

    convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
        const reader = this.getFileReader() //new FileReader();
        reader.onerror = (err) => {
            reject(err)
        };
        reader.onload = (evtReader) => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

    getFileReader(): FileReader {
        const fileReader = new FileReader();
        const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
        return zoneOriginalInstance || fileReader;
    }

    getBase64ImageFromUrl(imageUrl): Observable<any> {
        return new Observable(observer => {
            // function getBase64Image(img) {
            let image = new Image();
            image.setAttribute('crossOrigin', 'anonymous');
            image.onload = (ev) => {


                var canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0);
                var dataURL = canvas.toDataURL("image/png");
                var res = dataURL.replace(/^data:image\/?[A-z]*;base64,/, '')
                observer.next({ base64: dataURL, blob: this.b64toBlob(res) });
                observer.complete()
            }
            image.src = imageUrl;
        })


    }

    convert(blob: any) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.onerror = (err) => {
                reject(err)
            };
            reader.onload = (evtReader) => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        })
    }

}