import { Injectable } from "@angular/core";
import { AuthApiService } from "../api/auth-api.service";
import { UserData } from "../models/classes/user-data";
import { StorageKeys } from "../models/enums/storage-keys";
import { IGenericResponse } from "../models/interfaces/i-generic-response";
import { ILoginModel } from "../models/interfaces/login-model";
import { IAuthResponse } from "../models/interfaces/auth-response";
import { IRegisterModel } from "../models/interfaces/register-model";
import { StorageService } from "./storage.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import {
    FacebookLogin,
    FacebookLoginPlugin
} from '@capacitor-community/facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { LoadingService } from "./loading.service";

@Injectable({
    providedIn: "root"
})
export class AccountService {
    token: string = null
    hasLogin: boolean = false
    lang: string = "en"
    userData: UserData;
    fbLogin: FacebookLoginPlugin;
    static token: string = null
    /**
     *
     */


    static selectedSpeedUnit
    static selectedAreaUnit
    static selectedTemperatureUnit
    static selectedRainfallUnit
    static selectedWeightUnit
    constructor(private storageService: StorageService, private authApiCall: AuthApiService, private router: Router, private loadingService: LoadingService) {
        storageService.get<string>(StorageKeys.TOKEN).then(response => {
            if (response.IsSuccess)
                this.token = response.Data;
                AccountService.token = response.Data
        })
    }

    Login(payload: ILoginModel): Observable<any> {
        // return new Promise((resolve, reject) => {
        //     this.authApiCall.login(payload).subscribe(response => {
        //         if (response.IsSuccess) {
        //             this._setUserData(response.Data)
        //             resolve(this.userData)
        //         } else {
        //             reject(response.Message)
        //         }
        //     }, err => {
        //         reject(err)
        //     })
        // })
        return new Observable(observer => {
            this.authApiCall.login(payload).subscribe(response => {
                if (response.IsSuccess) {
                    this._setUserData(response.Data)
                    observer.next(response)
                } else {
                    observer.next(response)
                }
            });
        });
    }

    Register(payload: IRegisterModel): Observable<any> {
        return new Observable(observer => {
            this.authApiCall.register(payload).subscribe(response => {
                if (response.IsSuccess) {
                    this._setUserData(response.Data);
                    observer.next(response);
                } else {
                    observer.next(response);
                }
            });
        });
    }

    public async Logout() {
        this.storageService.remove(StorageKeys.USER).then();
        this.storageService.remove(StorageKeys.TOKEN).then(
            e => {
                this.router.navigateByUrl("auth/login-register");
            }
        );
        this.storageService.set(StorageKeys.mapUpdated, true).then();
        this.storageService.remove(StorageKeys.LAST_FILIZ_FARM).then();
        this.storageService.remove(StorageKeys.LAST_TRAP_FARM).then();
        this.storageService.remove(StorageKeys.FILIZ_DEMO).then();
        this.storageService.remove(StorageKeys.TRAP_DEMO).then();
        this.storageService.lastFilizFarmId = null;
        this.storageService.lastTrapFarmId = null;
        this.storageService.demoFiliz = false;
        this.storageService.demoTrap = false;
        this.authApiCall.deleteDeviceToken(this.storageService.deviceToken).subscribe(response => { });
        this.storageService.get(StorageKeys.FACEBOOK_USER).then(res => {
            if (res.IsSuccess) {
                if (res.Data) {
                    this.logoutFacebook();
                    this.storageService.set(StorageKeys.FACEBOOK_USER, null);
                }
            }
        });
        
        this.storageService.get(StorageKeys.GOOGLE_USER).then(res => {
            if (res.IsSuccess) {
                if (res.Data) {
                    this.logoutGoogle();
                    this.storageService.set(StorageKeys.GOOGLE_USER, null);
                }
            }
        });
        this.storageService.get(StorageKeys.APPLE_USER).then(res => {
            if (res.IsSuccess) {
                if (res.Data) {
                    this.logoutApple();
                    this.storageService.set(StorageKeys.APPLE_USER, null);
                }
            }
        });
    }

    public _setUserData(props: IAuthResponse) {
        this.userData = new UserData(props)
        this.storageService.set(StorageKeys.TOKEN, this.userData.Token).then(response => { })
        this.token = this.userData.Token
        this.storageService.set(StorageKeys.USER, this.userData).then(response => { })
    }
    public GetNotifications(skipCount: number, takeCount: number = 10) {
        return this.authApiCall.GetNotifications(skipCount, takeCount)
    }


    public loginWithSocial(payload) {
        return this.authApiCall.socialLogin(payload);
    }

    deleteAccount(): Observable<any> {
        return new Observable(observer => {
            this.authApiCall.deleteAccount().subscribe(e => {
                this.loadingService.hideLoading();
                if (e.IsSuccess) {
                    this.Logout().then(res => {
                        observer.next(true);
                    });
                } else {
                    observer.next(false);
                }
            });
        });
    }

    async logoutFacebook() {
        // await this.fbLogin.logout();
    }

    logoutGoogle() {
        GoogleAuth.signOut();
    }
    logoutApple() {

    }
}