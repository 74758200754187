import { Component, OnInit } from '@angular/core';
import { CameraSource } from '@capacitor/camera';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { IListModalModel } from 'src/app/core/models/interfaces/modal-components-models';
import { LanguageService } from 'src/app/core/services/lang.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'orbit-add-photo-sheet',
  templateUrl: './add-photo-sheet.component.html',
  styleUrls: ['./add-photo-sheet.component.scss'],
})
export class AddPhotoSheetComponent extends BaseModal implements OnInit {
  list: IListModalModel[] = [
    {
      id: 1,
      value: this.langService.getTranslation("options_take_photo_lbl"),
      key: CameraSource.Camera,
      icon: "camera"
    },
    {
      id: 2,
      value: this.langService.getTranslation("options_choose_library_lbl"),
      key: CameraSource.Photos,
      icon: "image"
    }
  ]
  constructor(private modalService: ModalService, private langService: LanguageService) {
    super()
    super._modalService = modalService
  }

  ngOnInit() {
    super.ngOnInit()
  }
  itemClicked(prop) {
    this.dismiss(prop, 'itemSelected')
  }
}
