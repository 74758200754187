import { Component, OnInit, Input } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-date-picker-modal',
  templateUrl: './date-picker-modal.component.html',
  styleUrls: ['./date-picker-modal.component.scss'],
})
export class DatePickerModalComponent extends BaseModal implements OnInit {
  @Input() date: any;
  constructor(private modalService: ModalService) {
    super();
    super._modalService = modalService;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ionViewDidEnter() {
    super.ngOnInit();
  }

  dismissModal() {
    this.dismiss(null);
  }

  onDateChange(ev) {
    this.date = ev;
  }

  save() {
    this.dismiss(this.date, "date-data")
  }

}
