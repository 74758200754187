import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { UnitPrefModalComponent } from "../helper/components/unit-pref-modal/unit-pref-modal.component";
import { FieldAreaPipe } from "../pipes/field-area.pipe";
import { NumberCulturePipe } from "../pipes/number-culture.pipe";
import { RainfallPipe } from "../pipes/rainfall.pipe";
import { SpeedPipe } from "../pipes/speed.pipe";
import { TemperaturePipe } from "../pipes/temperature.pipe";

@NgModule({
    imports: [IonicModule, CommonModule, TranslateModule],
    declarations: [UnitPrefModalComponent, FieldAreaPipe, NumberCulturePipe, RainfallPipe, SpeedPipe, TemperaturePipe],
    exports: [UnitPrefModalComponent, FieldAreaPipe, NumberCulturePipe, RainfallPipe, SpeedPipe, TemperaturePipe]
})
export class UnitPreferenceModule { }