import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";
import { Observable } from "rxjs";
import { StorageKeys } from "../models/enums/storage-keys";
import { UnitKeys } from "../models/enums/unit-keys";
import { IGenericResponse } from "../models/interfaces/i-generic-response";

@Injectable({
    providedIn: "root"
})
export class StorageService {
    storage: Storage
    draw: any;
    lang: string = "";
    lastTrapFarmId: number = null;
    lastFilizFarmId: number = null;
    openFarmList: boolean = false;
    deviceToken: any;
    demoTrap: boolean = false;
    demoFiliz: boolean = false;
    static ApnsToken: string
    static DeviceToken: string
    /**
     *
     */
    constructor(private ionicStorage: Storage) {
        this.init().then();
    }

    async init() {
        const storage = await this.ionicStorage.create();
        this.ionicStorage = storage;
    }

    initStorage() {
        return new Observable(observer => {
            if (this.storage)
                observer.next(this.storage);
            else
                this.ionicStorage.create().then(response => {
                    this.storage = response;
                    observer.next(this.storage);
                }).catch(err => {
                    observer.error(err);
                }).finally(() => {
                    observer.complete();
                })
        })
    }
    // : Observable<IGenericResponse<T>> 
    set<T = any>(key: StorageKeys | UnitKeys, data: T): Promise<IGenericResponse<T>> {
        return new Promise((resolve, reject) => {
            let response: IGenericResponse<any> = {
                StatusCode: null,
                IsSuccess: null,
                Message: null,
                Data: null
            }
            if (!this.storage)
                this.initStorage().subscribe(storage => {
                    this.storage.set(key, data).then(res => {
                        response = {
                            StatusCode: 1,
                            IsSuccess: true,
                            Message: "",
                            Data: null
                        }
                        resolve(response)
                    }).catch(err => {
                        response = {
                            StatusCode: -1,
                            IsSuccess: false,
                            Message: "error_on_storage_save",
                            Data: err
                        }
                        reject(response)
                    }).finally(() => {

                    })
                })
            else
                this.storage.set(key, data).then(res => {
                    response = {
                        StatusCode: 1,
                        IsSuccess: true,
                        Message: "",
                        Data: null
                    }
                    resolve(response)
                }).catch(err => {
                    response = {
                        StatusCode: -1,
                        IsSuccess: false,
                        Message: "error_on_storage_save",
                        Data: err
                    }
                    reject(response)
                }).finally(() => {

                })
        })
    }

    remove(key: StorageKeys | UnitKeys): Promise<any> {
        return this.storage.remove(key);
    }

    //TODO error statei T tipinde değil. Burası patlayabilir.
    get<T = any>(key: StorageKeys | UnitKeys): Promise<IGenericResponse<T>> {
        // return new Observable<IGenericResponse<T>>(observer => {
        return new Promise((resolve, reject) => {
            let response: IGenericResponse<T> = {
                StatusCode: null,
                IsSuccess: null,
                Message: null,
                Data: null
            }
            if (!this.storage)
                this.initStorage().subscribe(storage => {
                    this.storage.get(key).then(res => {
                        response = {
                            StatusCode: res ? 1 : 0,
                            IsSuccess: true,
                            Message: "",
                            Data: res
                        }
                        resolve(response)
                    }).catch(err => {
                        response = {
                            StatusCode: -1,
                            IsSuccess: false,
                            Message: "error_on_storage_get",
                            Data: err
                        }
                        reject(response);
                    }).finally(() => {
                    })
                })
            else
                this.storage.get(key).then(res => {
                    response = {
                        StatusCode: res ? 1 : 0,
                        IsSuccess: true,
                        Message: "",
                        Data: res
                    }
                    resolve(response)
                }).catch(err => {
                    response = {
                        StatusCode: -1,
                        IsSuccess: false,
                        Message: "error_on_storage_get",
                        Data: err
                    }
                    reject(response);
                }).finally(() => {
                })
        })
    }

}