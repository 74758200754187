import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { DateTimeService } from 'src/app/core/services/date-time.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit {
  private _tableArray;
  selectedTitle: string;
  unit: string;

  @Input() dateIsRange: boolean = true

  @Input() 
  set tableArray(value) {
    console.log(value);
    this.storageService.get(StorageKeys.FILIZ_MODE).then(data => {
      if(data.Data){
        this.selectedTitle = "filiz-history." + value[0].key;
      }else{
        this.selectedTitle = "history." + value[0].key;
      }
    })
    this.unit = value[0].unit;
    this._tableArray = value;
  }

  get tableArray() {
    return this._tableArray;
  }

  constructor(private storageService: StorageService) { }


  ngOnInit() { }
}

