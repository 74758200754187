import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { LoadingComponent } from './core/helper/components/loading/loading.component';
import { CustomTranslateLoader } from './core/helper/class/ngx-transateLoader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NetworkAlertModalComponent } from './core/helper/components/network-alert-modal/network-alert-modal.component';
import { Drivers } from '@ionic/storage';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { AuthPageModule } from './pages/auth/auth.module';
import { HttpInterceptorService } from './core/services/http-interceptor.service';
import * as Hammer from 'hammerjs';
import { NgxEchartsModule } from 'ngx-echarts';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

import * as echarts from 'echarts';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { AppInitService, OnAppInit } from './core/services/on-app-init.service';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { DeletedFarmsAlertModalModule } from './components/deleted-farms-alert-modal/deleted-farms-alert-modal.module';
import { WalkthroughModule } from './core/modules/walkthrough.module';
import { ClientStatusBarService } from './core/services/client-status-bar.service';
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx';
import { ErrorHandlerService } from './core/services/error-handler.service';

export const maskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  }
}


@NgModule({
  declarations: [AppComponent, LoadingComponent, NetworkAlertModalComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: "ios",
      swipeBackEnabled: false,
      scrollPadding: false,
      hardwareBackButton: true
    }),
    AppRoutingModule,
    HttpClientModule,
    NgxMaskModule.forRoot(maskOptions),
    IonicStorageModule.forRoot({
      name: '__doktar_filiz_app', //Her uygulama için değişecek
      driverOrder: ['websql', Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    // IonicModule.forRoot({
    //   _forceStatusbarPadding: true,
    // }),
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AuthPageModule,
    HammerModule,
    NgxEchartsModule.forRoot({ echarts }),
    DeletedFarmsAlertModalModule,
    WalkthroughModule
  ],
  providers: [
    SocialSharing,
    MediaCapture,
    AndroidPermissions,
    SignInWithApple,
    File,
    OpenNativeSettings,
    ClientStatusBarService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: APP_INITIALIZER, useFactory: OnAppInit, multi: true, deps: [AppInitService] },
    CleverTap],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.i18nUrl, '.json?v=' /* + Math.random()*/);
  // return new TranslateHttpLoader(http, environment.i18nUrl, '.json?v=' + Math.random());
  // return new CustomTranslateLoader(http, './assets/i18n/', '.json');
}
