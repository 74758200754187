import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonContent, isPlatform, Platform } from '@ionic/angular';
import { StorageKeys, FilizWalkthroughStepKeys } from './core/models/enums/storage-keys';
import { AnalyticService } from './core/services/analytic.service';
import { LanguageService } from './core/services/lang.service';
import { LoadingService } from './core/services/loading.service';
import { NavigationService } from './core/services/navigation.service';
import { NetworkService } from './core/services/network.service';
import { AppConstantsService } from './core/services/app-constants.service';
import { StorageService } from './core/services/storage.service';
import { UtilsService } from './core/services/utils.service';
import { VisualThemeModeService } from './core/services/visual-theme-mode.service';
import { UnitPreferenceService } from './core/services/unit-preference.service';
import { Router } from '@angular/router';
import { AccountService } from './core/services/account.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { NotificationService } from './core/services/notification-service';
import { ClientBaseApiService } from './core/api/client-base-api.service';
import {
  AppTrackingTransparency, AppTrackingStatusResponse
} from 'capacitor-plugin-app-tracking-transparency';
import { WalkthroughService } from './core/services/walkthrough.service';
import { ClientStatusBarService } from './core/services/client-status-bar.service';
import { FarmMapService } from './pages/common/farm-map/services/farm-map.service';
import { NotificationResponse } from './core/models/interfaces/notification';
import { LoggingService } from './core/services/logging.service';
import { BaseLoggingComponent } from './components/base-logging/base-logging.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent extends BaseLoggingComponent implements OnInit, OnDestroy {

  @ViewChild("content") content: IonContent;
  constructor(
    private navigationService: NavigationService,
    public loadingService: LoadingService,
    private loggingService: LoggingService,
    private platform: Platform,
    private langService: LanguageService,
    private storageService: StorageService,
    private networkService: NetworkService,
    private analyticService: AnalyticService,
    private appConstants: AppConstantsService,
    private unitService: UnitPreferenceService,
    private router: Router,
    private accountService: AccountService,
    private notificationService: NotificationService,
    private clientProxy: ClientBaseApiService,
    private farmService: FarmMapService,
    private walkthroughService: WalkthroughService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
  ) {
      super();
    // setTimeout(() => {
    //   SplashScreen.hide()
    // }, 2000);
    // this.storageService.init().then(e => {
    //   this.initApp();
    // });
  }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  public async getStatus(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.getStatus();

    // { status: 'authorized' } for example

    return response;
  }
  public async requestPermission(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.requestPermission();

    // { status: 'authorized' } for example

    return response;
  }

  ngOnInit(): void {

    //       this.clientProxy.serviceCallExternal("../assets/i18n/en.json", {}, "get").subscribe(en => {
    //   this.clientProxy.serviceCallExternal("../assets/i18n/tr.json", {}, "get").subscribe(bg => {
    //     this.detectDiff(en, bg,"");
    //     console.table(this.diffArr);

    //   })
    // })
    let clientStatusBar = new ClientStatusBarService()
    setTimeout(() => {
      clientStatusBar.statusBarOverlay()
    }, 1000);
    this.initApp();
    SplashScreen.hide()
  }
  diffArr = [];
  detectDiff(main, search, mainKey) {
    for (let key in main) {
      if (search[key] == undefined) {
        this.diffArr.push({ key: key, value: main[key], mainKey: mainKey });
      }
      if (Object.keys(main[key]).length > 0 && Object.keys(main)[0].length != 0) {
        if (typeof (main[key]) != "string" && typeof (main[key]) != "number" && !Array.isArray(main[key])) {
          if (search[key]) {
            this.detectDiff(main[key], search[key], key);
          }
        }
      }
    }
  }
  initApp() {
    let themeService = new VisualThemeModeService(this.storageService, new ClientStatusBarService);
    themeService.initApplicationTheme();
    this.appConstants.GetAppConstants(true).subscribe()
    this.networkService.check()
    this.networkService.initiateNetworkListener()
    this.analyticService.trackNavigationEnd()

    this.platform.ready().then(response => {
      if (!isPlatform('capacitor')) {
        // GoogleAuth.initialize();

      }
      this.notificationService.GetNotificationPermissions().subscribe()
      this.notificationService.pushNotificationOperations();
      themeService.setTextZoom();
      if (this.platform.is('ios')) {
        this.getStatus();
        this.requestPermission();
      }
      this.navigationService.trackNavigation();
      let utils = new UtilsService(this.platform)
      this.storageService.initStorage().subscribe(response => {
        this.unitService.initializeUnitValues();
        this.storageService.get(StorageKeys.DEVICE_TOKEN).then(res => {
          this.storageService.deviceToken = res.Data;
          console.log("deviceToken",this.storageService.deviceToken)
        });
        this.storageService.set(StorageKeys.FILIZ_DEMO, false).then();
        this.storageService.set(StorageKeys.TRAP_DEMO, false).then();

        this.storageService.get(StorageKeys.FIRST).then(e => {
          if (e.IsSuccess) {
            if (!e.Data) {
              this.storageService.set(StorageKeys.FIRST, true).then(e => {
                this.router.navigateByUrl("auth/on-boarding");
              });
            }
          }
        });
        if (!(this.storageService.lastTrapFarmId || this.storageService.lastFilizFarmId)) {
          this.storageService.get(StorageKeys.FILIZ_MODE).then(e => {
            if (e.IsSuccess) {
              if (e.Data) {
                themeService.setFilizTheme().subscribe();
              } else {
                themeService.setPesTrappTheme().subscribe();
              }
            } else {
              themeService.setPesTrappTheme().subscribe();
            }
          });

          this.storageService.get(StorageKeys.LAST_TRAP_FARM).then(farmId => {
            if (farmId.IsSuccess) {
              if (farmId.Data) this.storageService.lastTrapFarmId = +farmId.Data;
            }
          });

          this.storageService.get(StorageKeys.LAST_FILIZ_FARM).then(farmId => {
            if (farmId.IsSuccess) {
              if (farmId.Data) this.storageService.lastFilizFarmId = +farmId.Data;
            }
          });
        }
        this.langService.getLangOpts().subscribe(r => {
          this.storageService.get<string>(StorageKeys.LANG).then(langResponse => {
            if (langResponse && langResponse.IsSuccess && langResponse.Data && langResponse.Data !== '') {
              this.langService.setCurrentLang(langResponse.Data);
            } else {
              this.langService.setCurrentLang(this.langService.getBrowserLang());
            }
          })
        });
        this.storageService.get(StorageKeys.TOKEN).then(user => {
          if (user.IsSuccess) {
            if (user.Data) {
              this.accountService.token = user.Data;
              // this.farmService.CheckIfAnyFarmDeletedByOwner()
              setTimeout(() => {
                this.analyticService.sendState("app.ready").subscribe();
                // this.notificationService.pushNotificationOperations();
              }, 1);
            }
          }
        });


      });
      this.walkthroughService.WalkthroughCommService().subscribe(res => {
        if (res.direction == "forward") {
          this.walkthroughService.forward(res.data)
        }
      });
      this.walkthroughService.InitWalkthrough();
    })

    // this.translateService.onLangChange.subscribe((event: LangChangeEvent) =>
    //   {
    //     if(event.lang == 'ar')
    //     {
    //       document.dir = 'rtl';
    //     }
    //     else
    //     {
    //       document.dir = 'ltr';        }
    //   });
  }
}
