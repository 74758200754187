import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.page.html',
  styleUrls: ['./auth.page.scss'],
})
export class AuthPage implements OnInit {
  isOpenModal: boolean = false;
  segment = "login"
  @Input() userInfo: any;
  socialUserInfo: any;
  appLogin: boolean = false;
  constructor(private modalService: ModalService) { }

  ngOnInit() {

  }

  changeModalStatus(ev) {
    this.isOpenModal = ev;
  }
  segmentChanged(ev) {
    this.socialUserInfo = null;
    this.appLogin = false;
    this.segment = ev.detail.value
  }


  socialLogin(ev) {
    this.segment = "register";
    this.socialUserInfo = ev;
    if (this.socialUserInfo?.type == "apple") {
      this.appLogin = true;
    }
  }
  // CheckForOnboarding() {
  //   this.modalService.presentModal({ component: c, componentProps: {}, options: { cssClass: "modal modal-fullscreen" } }).then(response => { })
  // }


}
