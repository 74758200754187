import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ModalService } from 'src/app/core/services/modal.service';
import { IButtonOpts } from '../interfaces/modal-components-models';
import { AllGeoJSON } from '@turf/turf';

@Component({
    selector: "",
    template: ""
})
export class BaseAlertModal extends BaseModal implements OnInit {
    @Input() mainTitle: string = null
    @Input() title: string = null
    @Input() description: string = null
    @Input() image: string = null
    @Input() buttons: Array<IButtonOpts> = []
    @Input() svg: AllGeoJSON = null
    
    constructor(private alertModalService: ModalService) {
        super()
        super._modalService = alertModalService
    }

    ngOnInit() { }

    dismissModal(id) {
        this.alertModalService.dismissByModalId(id, {}).then(response => { })
    }
    buttonClick(ev, prop) {
        this.dismiss({ event: ev, data: prop }, "buttonClicked")
        prop.callback(ev, this.id);
    }
}