import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class LoadingService {
    isLoad: boolean = false;
    loadingCount: number = 0
    /**
     *
     */
    constructor() {

    }

    showLoading() {
        this.loadingCount++;
        this.isLoad = true;
        // if (this.loadingCount > 1) {
        //     this.loadingCount = 1;
        // }
    }

    hideLoading() {
        this.isLoad = false;
        if (this.loadingCount > 0) {
            this.loadingCount--
        }
        if (this.loadingCount < 0) {
            this.loadingCount = 0;
        }

    }
}