import { Component, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-generic-list-modal',
  templateUrl: './generic-list-modal.component.html',
  styleUrls: ['./generic-list-modal.component.scss'],
})
export class GenericListModalComponent extends BaseModal implements OnInit {

  constructor(private modalService:ModalService) { 
    super()
    super._modalService = modalService
  }

  ngOnInit() {}

}
