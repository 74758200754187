import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { StorageService } from 'src/app/core/services/storage.service';
import { VisualThemeModeService } from 'src/app/core/services/visual-theme-mode.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [VisualThemeModeService]
})
export class HeaderComponent implements OnInit, OnDestroy {
  mode: string
  openSwitch: boolean = false;
  @Input() showEndButtons: boolean = true;
  constructor(private storageService: StorageService, private visualThemeModeService: VisualThemeModeService, private router: Router) { }
  ngOnDestroy(): void {
    this.mode = null;
  }

  ngOnInit() {
    this.storageService.get(StorageKeys.FILIZ_MODE).then(e => {
      if (e.IsSuccess) {
        if (e.Data) {
          this.mode = "filiz";
        } else {
          this.mode = "pesttrapp";
        }
      }
    });
  }


  changeApp(isFilizMode: boolean) {
    if (isFilizMode) {
      if (this.mode == "pesttrapp") {
        this.visualThemeModeService.setFilizTheme().subscribe(e => {
          if (e) {
            this.mode = null;
            this.router.navigate(["/filiz/home"], {
              replaceUrl: true
            });
            this.openSwitch = false;
          }

        });
      }
    } else {
      if (this.mode == "filiz") {
        this.visualThemeModeService.setPesTrappTheme().subscribe(e => {
          if (e) {
            this.mode = null;
            this.router.navigate(["/pesttrapp/home"], {
              replaceUrl: true
            });
            this.openSwitch = false;
          }
        });
      }
    }
  }

  openWeatherForecast() {
    this.router.navigateByUrl("/common/weather-forecast");
  }
  openNotificationPage() {
    this.router.navigateByUrl("common/notification-list");
  }

}
