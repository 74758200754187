import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { MediaService } from 'src/app/core/services/media.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { INewImageModel } from "src/app/core/models/interfaces/new-image-model";
import { ClientAlertService } from 'src/app/core/services/client-alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'orbit-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.scss'],
})
export class GalleryModalComponent extends BaseModal implements OnInit {

  @Input() imageList: Array<INewImageModel & any>
  @Input() maxImageCount: number = 10
  selectedImage: string = null // "https://picsum.photos/id/1002/4312/2868"
  areaHeight = 0;
  constructor(private modalService: ModalService, private translate: TranslateService, private alerService: ClientAlertService, private mediaService: MediaService) {
    super()
    super._modalService = modalService;
  }

  ngOnInit() {
    super.ngOnInit()
  }
  previewImage(item) {
    this.selectedImage = item.src
  }

  deleteImage(props: INewImageModel) {
    if (props.type === 'uploaded' && props.deleteAction)
      props.deleteAction().then(res => {
        this.imageList = this.imageList.filter(t => t.id !== props.id)
      }).catch(err => {
        this.alerService.PresentAlert({
          buttons: [{
            callback: () => { },
            color: "dark",
            fill: "clear",
            text: this.translate.instant("general.error_on_image_delete")
          }]
        })
      })
    else {
      this.imageList = this.imageList.filter(t => t.id !== props.id)
    }
  }

  addPhoto() {
    this.mediaService.openAddPhotoSheet().subscribe((response: any) => {
      this.imageList.push(response)
    })
  }

  override async dismiss(data: any, role?: string): Promise<void> {
    await this.modalService.dismissByModalId(this.id, this.imageList, "imageListChanged")
    // return new Promise((resolve,reject) => {
    // })
  }
}
