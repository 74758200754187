import { Component, Input, OnInit } from '@angular/core';
import { BaseAlertModal } from 'src/app/core/models/classes/base-alert-modal';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-float-alert-modal',
  templateUrl: './float-alert-modal.component.html',
  styleUrls: ['./float-alert-modal.component.scss'],
})
export class FloatAlertModalComponent extends BaseAlertModal implements OnInit {
  contentClass: any = "bg-default"//
  constructor(private modalService:ModalService) {
    super(modalService)
   }


   ngOnInit() {
    super.ngOnInit()
    setTimeout(() => {
      this.contentClass = "bg-opaque"
    }, 350);
  }

  ionViewDidEnter() {
  }

  // dismissModal() {
  //   this.modalService.dismissByModalId(this.id, {}).then(response => { })
  // }

  applyBtn() { }
  clear() { }
}
