import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { TextZoom } from "@capacitor/text-zoom";
import { Observable } from "rxjs";
import { StorageKeys } from "../models/enums/storage-keys";
import { ClientStatusBarService } from "./client-status-bar.service";
import { StorageService } from "./storage.service";

@Injectable({
    providedIn:"root"
})
export class VisualThemeModeService {

    /**
     *
     */
    constructor(private storageService: StorageService, private statusbarService: ClientStatusBarService) {

    }
    public initApplicationTheme() {
        this.storageService.get<boolean>(StorageKeys.FILIZ_MODE).then(response => {
            if (response.IsSuccess)
                this.toggleTheme(response.Data).then()
        });
    }

    setFilizTheme(): Observable<boolean> {
        return new Observable(observer => {
            this.toggleTheme(true).then(e => {
                observer.next(true)
            });
        });
    }

    setPesTrappTheme(): Observable<boolean> {
        return new Observable(observer => {
            this.toggleTheme(false).then(e => {
                observer.next(true)
            });
        });
    }


    private toggleTheme(isEnabled: boolean) {
        return new Promise(resolve => {
            document.body.classList.toggle('filiz', isEnabled);
            this.storageService.set<boolean>(StorageKeys.FILIZ_MODE, isEnabled).then(response => {
            });
            this.statusbarService.setStatusBarTheme(isEnabled).then(e => {
                resolve(true);
            });
        });
    }
    public setTextZoom() {
        if (Capacitor.isNativePlatform())
            TextZoom.set({
                value: 1
            }).then()
    }
}