import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { GestureController } from '@ionic/angular';
import { IPopoverButtonStatus, ITargetStyleOpts } from 'src/app/core/models/interfaces/walkthrough_items';
import { WalkthroughService } from 'src/app/core/services/walkthrough.service';

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.component.html',
  styleUrls: ['./walkthrough.component.scss'],
})
export class WalkthroughComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('containerArea') containerArea: ElementRef<any>
  @Input() scrollContent: any

  @Input() props: IWalkthroughComponentProps = {
    message: "",
    header: "",
    mediaTemplate: "",
    isMediaFullPage: false,
    targetElementId: null,
    isToast: false,
    style: null,
    buttonsStatus: { isBackActive: true, isNextActive: true, isSkipActive: true },
    hideBackdrop: false,
    backButton: {},
    nextButton: {},
    pageIndex: null,
    hasUserInteraction: false
  }
  @Input() setFullWidth: boolean = false;
  @Input() parentWidth: number = null;
  targetElementZindex: number
  targetElement = null;
  directionArrow = null;
  containerElement = null;

  constructor(private walkthroughService: WalkthroughService, private gestureCtrl: GestureController) { }

  ngOnInit() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["targetElementId"] && this.props.targetElementId && this.props.targetElementId !== '')
      this.InitElements();
  }
  tryCount: number = 0;
  ngAfterViewInit() {
    if (this.props.targetElementId && this.props.targetElementId !== '')
      if (this.props.isToast) {
        this.ToggleTargetElementClass(true);
        this.InitNoTarget();
      } else
        setTimeout(() => {
          this.InitElements();
        }, 300);
    else {
      this.InitNoTarget();
    }
  }

  InitElements() {
    let targetDoc = document.getElementById(this.props.targetElementId);
    let containerDoc = document.getElementById(`walkthrough_${this.props.targetElementId}`)
    if (targetDoc && containerDoc) {
      this.targetElement = targetDoc;
      this.containerElement = containerDoc;
      this.GetTargetStyle();
      if (this.props.style?.showTargetBorder)
        this.ToggleTargetElementClass(true);
      let targetRects = targetDoc.getClientRects()[0];
      if (targetRects) {
        this.tryCount = 0;
        let containerRects = containerDoc?.getClientRects()[0] || { bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0, x: 0, y: 0 };
        let deviceHeight = window.innerHeight, deviceWidth = this.parentWidth || window.innerWidth;

        if (targetRects.y < 0 || (targetRects.y + targetRects.height + 10 >= deviceHeight)) {
          let delayTime = 200; //milisecond
          try {
            targetDoc.scrollIntoView({ block: "start", inline: "center" })
          } catch (error) {

          }
          targetRects = targetDoc.getClientRects()[0];
          containerRects = containerDoc?.getClientRects()[0] || { bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0, x: 0, y: 0 };
          setTimeout(() => {
            this.CalculateContentLocation({ containerDoc, containerRects, deviceHeight, deviceWidth, targetDoc, targetRects });
          }, delayTime);
        } else {
          this.CalculateContentLocation({ containerDoc, containerRects, deviceHeight, deviceWidth, targetDoc, targetRects });
        }

      } else if (this.tryCount < 2) {
        this.tryCount++
        setTimeout(() => {
          return this.InitElements();
        }, 200);
      } else
        return;
    } else if (this.tryCount < 5) {
      this.tryCount++
      setTimeout(() => {
        return this.InitElements();
      }, 200);
    } else
      return this.InitNoTarget();
  }

  CalculateContentLocation({ targetRects, containerRects, deviceHeight, deviceWidth, containerDoc, targetDoc }) {
    let tabBarHeight = document.getElementsByClassName("tab-bar")[0]["offsetHeight"];
    let rawY = targetRects.y + targetRects.height + containerRects.height;
    let containerY, directionArrow;
    // let iosSafeArea = +getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-bottom').slice(0, -2).trim()
    let iosSafeArea = 0;
    let arrowDirection: "up" | "down" = "up"
    if (!(iosSafeArea && !Number.isNaN(iosSafeArea)))
      iosSafeArea = 0
    if (rawY > (deviceHeight - 50)) {
      arrowDirection = "down";
      containerY = targetRects.y - (25 + containerRects.height);
      directionArrow = document.getElementById("arrow_down");
      directionArrow.style.display = "block";
      directionArrow.style.bottom = (deviceHeight - targetRects.y + 10 + 10 - iosSafeArea - tabBarHeight) + "px"; // (targetRects.y - 35 - iosSafeArea) + "px";// containerRects.y + targetRects.height + containerRects.height + "px"
      this.directionArrow = directionArrow;
      containerDoc.style.top = "unset"
      containerDoc.style.bottom = (deviceHeight - targetRects.y + 10 - iosSafeArea - tabBarHeight) + "px";
    } else {
      arrowDirection = "up";
      containerY = targetRects.y + targetRects.height + 0;
      directionArrow = document.getElementById("arrow_up");
      directionArrow.style.display = "block";
      directionArrow.style.top = (targetRects.y + targetRects.height - iosSafeArea) + "px";
      this.directionArrow = directionArrow;
      containerDoc.style.top = (containerY - iosSafeArea) + "px";
    }

    let containerX = this.parentWidth ? (targetRects.x - (window.innerWidth - this.parentWidth) + 10) : (targetRects.x - 10);// + targetRects.width - targetRects.width;
    if (containerX < 0) {
      containerX = 0
    }
    if (containerX + containerRects.width > deviceWidth) {
      containerX = deviceWidth - containerRects.width
    }

    let arrowLeft = this.parentWidth ? (targetRects.x - (window.innerWidth - this.parentWidth) + 10) : (targetRects.x - 10);

    directionArrow.style.left = arrowLeft + "px"
    containerDoc.style.left = (containerX - 10) + "px";
    targetDoc.style.zIndex = "1001";
    if ((arrowLeft + 15) > (containerX - 30) && (arrowLeft + 15) <= (containerX + 30)) {
      if (arrowDirection === "up")
        containerDoc.style.borderTopLeftRadius = "5px";
      else
        containerDoc.style.borderBottomLeftRadius = "5px";
    } else if ((arrowLeft + 15) > (containerX + containerRects.width - 30 - 20) && (arrowLeft + 15) <= (containerX + containerRects.width))
      if (arrowDirection === "up")
        containerDoc.style.borderTopRightRadius = "5px";
      else
        containerDoc.style.borderBottomRightRadius = "5px";

    let _updatedContainerRect = document.getElementById(`walkthrough_${this.props.targetElementId}`).getBoundingClientRect()
    // let iosSafeAreaTop = +getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-top').slice(0, -2).trim()
    let iosSafeAreaTop = 0
    if (iosSafeAreaTop > 0) { iosSafeAreaTop += 10; }
    if (_updatedContainerRect.y < iosSafeAreaTop) { directionArrow.style.display = "none"; this.InitNoTarget(); }
    return;
  }

  GetTargetStyle() {
    let x: any = document.getElementById(this.props.targetElementId);
    let y: any = null;
    if (window.getComputedStyle) {
      y = document.defaultView.getComputedStyle(x, null).getPropertyValue("z-index");
    }
    else if (x.currentStyle) {
      y = x.currentStyle["z-index"];
    }
    this.targetElementZindex = y;

  }

  ToggleTargetElementClass(status: boolean) {
    let element = document.getElementById(this.props.targetElementId)
    if (element) {
      if (status) {
        element.classList.add("walkthrough-selectedElement");
      } else {
        element.classList.remove("walkthrough-selectedElement");
      }
    }
  }

  InitNoTarget() {
    let containerDoc = document.getElementById("walkthrough_" + this.props.targetElementId);
    containerDoc.style.bottom = "10px";
    containerDoc.style.top = "unset";
    containerDoc.style.width = "calc(100% - 100px)";
    containerDoc.style.left = "0px";
    this.ToggleTargetElementClass(true)
    let drag = this.gestureCtrl.create({
      el: this.containerArea.nativeElement,
      threshold: 1,
      direction: 'y',
      gestureName: "drag",
      onStart: ev => {
        containerDoc.style.bottom = "unset";
      },
      onMove: ev => {
        this.containerArea.nativeElement.style.top = `${ev.currentY}px`;

      },
      onEnd: ev => {
        let topSafeArea = 0 //+getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-top').slice(0, -2).trim();
        if (topSafeArea > 0)
          topSafeArea += 10;
        let bottomSafeArea = 0// +getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-bottom').slice(0, -2).trim()
        let deviceHeight = window.innerHeight;
        this.containerArea.nativeElement.style.top = ev.currentY < (5 + topSafeArea) ? `${5 + topSafeArea}px` : deviceHeight < (ev.currentY + this.containerArea.nativeElement.offsetHeight) ? `${deviceHeight - (this.containerArea.nativeElement.offsetHeight + 10 + bottomSafeArea)}px` : `${ev.currentY}px`
      }
    })
    drag.enable();

  }

  ClearElementStyles() {
    try {
      if (this.targetElement) {
        this.targetElement.style.zIndex = this.targetElementZindex || "2";
      }
      this.ToggleTargetElementClass(false);
    } catch (error) {

    }
    try {
      if (this.directionArrow) {
        this.directionArrow.style.display = "none";
        this.directionArrow.style.top = "-150px";
      }
    } catch (error) {

    }
    try {
      let containerDoc = document.getElementById("walkthrough_");
      containerDoc.style.bottom = "unset";
      containerDoc.style.top = "unset";
      containerDoc.style.width = "auto";
    } catch (error) {

    }
  }

  moveBack() {
    this.ClearElementStyles();
    this.walkthroughService.SendMessage({ direction: 'back', data: {} })
  }
  moveForward() {
    this.ClearElementStyles();
    this.walkthroughService.SendMessage({ direction: 'forward', data: {} })
  }
  skip() {
    this.ClearElementStyles();
    this.walkthroughService.SendMessage({ direction: 'skip', data: {} })
  }

}
export interface IWalkthroughComponentProps {
  message: string
  header?: string
  mediaTemplate: string
  isMediaFullPage: boolean
  targetElementId: string
  style: ITargetStyleOpts
  buttonsStatus: IPopoverButtonStatus
  hideBackdrop: boolean
  nextButton: any
  backButton: any
  pageIndex: { total: number, current: number },
  hasUserInteraction: boolean,
  isToast: boolean
}
