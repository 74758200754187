import { Directive, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, Renderer2 } from '@angular/core';
import { AllGeoJSON, bbox as tBbox, projection } from '@turf/turf';
import {GeoJSON2SVG} from 'geojson2svg';

@Directive({
  selector: '[fromGeoJson]'
})
export class FromGeoJsonDirective implements OnChanges {

  @Input('fromGeoJson') geojson: AllGeoJSON | any;

  private path: SVGPathElement;

  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.path = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );

    this.renderer.appendChild(this.el.nativeElement, this.path);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let geoType = this.geojson["geometry"]["type"];
    if (geoType == "Point") {
      // this.renderer.setAttribute(this.path, 'd', "M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1164-64 64.07 64.07 0 01-64 64z");
      return;
    }
    else {// if(this.geojson)
      const mercator = projection.toMercator(this.geojson);
      const bbox = tBbox(mercator);
      const converter =new GeoJSON2SVG({
        mapExtent: bboxToExtent(bbox),
        output: 'path'
      });
      this.renderer.setAttribute(this.path, 'd', converter.convert(mercator)[0]);
      //this.renderer.setAttribute(this.el.nativeElement, 'viewBox', bboxToViewBox(this.path.getBBox()));
      return;
    }
  }
}

function bboxToExtent(bbox) {
  return {
    left: bbox[0],
    bottom: bbox[1],
    right: bbox[2],
    top: bbox[3]
  };
}

function bboxToViewBox(bbox) {
  return `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
}