import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { WalkthroughComponent } from '../helper/components/walkthrough/walkthrough.component';



@NgModule({
  declarations: 
  [WalkthroughComponent
  ],
  exports: [
    WalkthroughComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ]
})
export class WalkthroughModule { }
