import { Component, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
})
export class AuthModalComponent extends BaseModal implements OnInit {

  segment = "login"
  constructor(private modalService:ModalService) { 
    super()
    super._modalService = modalService
  }

  ngOnInit() {
    super.ngOnInit()
  }

  segmentChanged(ev){
    this.segment = ev.detail.value
  }

}
