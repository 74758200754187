import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ModalBaseComponent } from '../helper/components/modal-base/modal-base.component';

@NgModule({
    imports: [
        IonicModule,
        FormsModule,
        CommonModule
    ],
    exports: [ModalBaseComponent],
    declarations: [ModalBaseComponent],
    providers: [],
})
export class BaseModalModule { }
