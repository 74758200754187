export enum StorageKeys {
    TOKEN = "TOKEN",
    IS_REGISTERED = "is_registered",
    USER = "USER",
    LANG = "LANG",
    PERMISSIONS = "permissions",
    DEVICE_TOKEN = "DEVICETOKEN",
    DEVICE_TOKEN_CONTROL = "device_token_control",
    PHONE_CODE = "phone_code",
    FILIZ_MODE = "filiz_mode",
    DRAW = "storage_keys",
    LAST_TRAP_FARM = "trap_farm",
    LAST_FILIZ_FARM = "filiz_farm",
    WELCOME = "welcome",
    FIRST = "first-app-in",
    FACEBOOK_USER = "fbUser",
    GOOGLE_USER = "googleUser",
    APPLE_USER = "appleUser",
    TRAP_DEMO = "trap_demo",
    FILIZ_DEMO = "filiz_demo",
    FIRST_LOGIN = "first-login",
    NOTIFICATION_STATUS = "notification_status",
    welcome = "WELCOME",
    updated = "update_farm",
    mapUpdated = "map_update",
    NEW_MEASUREMENTS = 'new_measurements',
    DRAWING = 'drawing',
    FILIZ_WALKTHROUGH_STEPS = "filiz_walkthrough_step",
    TRAPP_WALKTHROUGH_STEPS = "trapp_walkthrough_step",
    LİCENCE_EXPİRED_SHOW ="license_expired_show",
    STICKY_VALUE = 'sticky_value',
    PAPER_LOG_STATUS = 'paper_log_status'
}

export enum FilizWalkthroughStepKeys {
    HISTORY_GRAPHS = "history_graphs",
}

export enum TrappWalkthroughStepKeys {
    TRAPP_HISTORY_GRAPHS = "trapp_history_graphs",
}