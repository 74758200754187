import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  FacebookLogin,
  FacebookLoginPlugin
} from '@capacitor-community/facebook-login';
import { AuthApiService } from 'src/app/core/api/auth-api.service';
import { LanguageService } from 'src/app/core/services/lang.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
// import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { Message_Type } from 'src/app/core/models/enums/message-type';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { Capacitor } from '@capacitor/core';


@Component({
  selector: 'orbit-external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.scss'],
})
export class ExternalLoginComponent implements OnInit {
  @Input() showOr: boolean = true;
  fbLogin: FacebookLoginPlugin;
  token = null;
  user = null;
  socialMediaToken = null;
  fbUser: any;
  platform: string = Capacitor.getPlatform();
  @Output() socialLoginExternal: EventEmitter<any> = new EventEmitter();
  constructor(
    private loading: LoadingService,
    private http: HttpClient,
    private authApi: AuthApiService,
    private storageService: StorageService,
    private router: Router,
    private toast: ToastService,
    private langService: LanguageService,
    // private signInWithApple: SignInWithApple
  ) {
    this.fbLogin = FacebookLogin;
  }

  ngOnInit() { }

  userInfo

  async signInGoogle() {
    const googleUser = await GoogleAuth.signIn() as any;
    // "displayName":"Duygu SAYGIN","email":"duygusayginn@gmail.com","familyName":"SAYGIN","givenName":"Duygu","id":"111183152332406165190","imageUrl":"https://lh3.googleusercontent.com/a/ALm5wu1N7BGnBFP7XjViQAridfsGK4AJzg11rBlQKz8V=s96-c"
    // this.userInfo = googleUser;
    if (googleUser) {
      this.userInfo = {
        Id: googleUser.id,
        NameSurname: googleUser.name,
        ImageUrl: googleUser.imageUrl,
        Email: googleUser.email,
        Type: "google"
      };
      this.storageService.set(StorageKeys.GOOGLE_USER, this.userInfo).then();
      this.socialLoginExternal.emit(this.userInfo);
    }
  }


  async loadUserData() {
    const url = `https://graph.facebook.com/${this.token.userId}?fields=id,name,picture.width(720),birthday,email&access_token=${this.token.token}`;
    // this.fbLogin.getProfile({ fields: ["id", "name", "first_name", "last_name", "email"] }).then();
    this.loading.showLoading();
    this.http.get(url).subscribe(res => {
      this.user = res;
      if (this.user) {
        this.storageService.set(StorageKeys.FACEBOOK_USER, this.user).then();
        this.userInfo = {
          Id: this.user.id,
          NameSurname: this.user.first_name + " " + this.user.last_name,
          ImageUrl: "",
          Email: this.user.email,
          Type: "facebook"
        };
        this.socialLoginExternal.emit(this.userInfo);
      }
      // this.authApi.FacebookUser(this.user.id).subscribe(elem => {
      //   if (elem.IsSuccess) {
      //     this.loading.hideLoading();
      //     this.storageService.set(StorageKeys.USER, elem.Data).then();
      //     this.storageService.set(StorageKeys.TOKEN, elem.Data.Token).then();
      //     this.router.navigateByUrl("dashboard");
      //   } else {
      //     this.toast.PresentBasicToast({ message: this.langService.getTranslation('login.alert-login'), duration: 3000, type: Message_Type.DANGER });
      //   }
      // })
    });
  }
  async getCurrentToken() {
    const result = await this.fbLogin.getCurrentAccessToken();
    if (result.accessToken) {
      this.token = result.accessToken;
      this.loadUserData();
    } else {
      this.toast.PresentBasicToast({ message: this.langService.getTranslation('login.alert-login'), duration: 3000, type: Message_Type.DANGER });
    }
  }
  async loginFacebook() {
    const FACEBOOK_PERMISSIONS = ['public_profile', "email"];
    const result = await this.fbLogin.login({ permissions: FACEBOOK_PERMISSIONS });
    if (result.accessToken && result.accessToken.userId) {
      this.socialMediaToken = result.accessToken.userId;
      this.token = result.accessToken;
      this.fbLogin.getProfile({ fields: ["id", "name", "first_name", "last_name", "email"] }).then(res => {
        this.fbUser = res;
        this.storageService.set(StorageKeys.FACEBOOK_USER, this.fbUser).then();
        if (this.fbUser.id) {
          // this.authApi.FacebookUser(this.fbUser.id).subscribe(elem => {
          //   let user = elem.Data;
          //   if (elem.IsSuccess) {
          //     this.loading.hideLoading();
          //     if (user.UserRoleId > 0) {
          //       this.storageService.set(StorageKeys.USER, user).then();
          //       this.storageService.set(StorageKeys.TOKEN, user.Token).then();
          //       this.router.navigateByUrl("/common/pick-device");
          //     } else {
          //       var userInfo = {
          //         FullName: this.fbUser.name,
          //         NameSurname: this.fbUser.first_name,
          //         NameSurname1: this.fbUser.last_name,
          //         phoneNumber: user.UserTelNo,
          //         mail: this.fbUser.email,
          //         password1: '',
          //         password2: '',
          //         fbId: this.fbUser.id
          //       };
          //       this.router.navigate(["/auth/login-register"], { queryParams: userInfo });
          //       //lütfen üyeliğinizi tamamlayın gibi bi uyarı vermemiz gerekecek. Alttaki key değiştirilecek
          //       this.toast.PresentBasicToast({ message: this.langService.getTranslation('login.alert-login'), duration: 3000, type: Message_Type.DANGER });
          //     }
          //   } else {
          //     this.loading.hideLoading();
          //     this.toast.PresentBasicToast({ message: this.langService.getTranslation('login.alert-login'), duration: 3000, type: Message_Type.DANGER });
          //   }
          // })
          this.user = res;
          if (this.user) {
            this.userInfo = {
              Id: this.user.id,
              NameSurname: this.user.first_name + " " + this.user.last_name,
              ImageUrl: "",
              Email: this.user.email,
              Type: "facebook"
            };
            this.socialLoginExternal.emit(this.userInfo);
          }
        }
      });
    } else if (result.accessToken && !result.accessToken.userId) {
      this.getCurrentToken();
    } else {
      this.loading.hideLoading();
      // this.toast.PresentBasicToast({ message: this.langService.getTranslation('auth.alert-login'), duration: 3000, type: Message_Type.DANGER });
    }
  }

  signInApple() {
    // this.signInWithApple.signin({
    //   requestedScopes: [
    //     ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
    //     ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
    //   ]
    // })
    //   .then((res: AppleSignInResponse) => {
    //     // https://developer.apple.com/documentation/signinwithapplerestapi/verifying_a_user
    //     alert('Send token to apple for verification: ' + res.identityToken);
    //     console.log(res);
    //     if (res) {
    //       this.storageService.set(StorageKeys.APPLE_USER, this.user).then();
    //     }
    //   })
    //   .catch((error: AppleSignInErrorResponse) => {
    //     alert(error.code + ' ' + error.localizedDescription);
    //     console.error(error);
    //   });
  }

  signWithApple() {
    let options: SignInWithAppleOptions = {
      clientId: 'com.doktar.verim365',
      redirectURI: 'https://verim365.firebaseapp.com/__/auth/handler',
      scopes: 'email name'
    };

    SignInWithApple.authorize(options).then((e: SignInWithAppleResponse) => {
      this.user = e.response;
      if (this.user) {
        this.userInfo = {
          Id: this.user.user,
          NameSurname: this.user.givenName + " " + this.user.familyName,
          ImageUrl: "",
          Email: this.user.email,
          Type: "apple"
        };
        this.storageService.set(StorageKeys.APPLE_USER, this.user).then();
        this.socialLoginExternal.emit(this.userInfo);
      }
    })
  }

}
