import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpRequestTypes } from '../models/enums/http-request-types';
import { ClientBaseApiService } from './client-base-api.service';
import { IPushNotificationRequest } from '../models/interfaces/notification-settings-model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private clientApi: ClientBaseApiService) { }

  pushNotification(payload: IPushNotificationRequest) {
    let url = environment.baseApiUrl + "v2/api/notification/set/push/token";
    return this.clientApi.baseApiCall<any>({ url: url, payload: payload, requestType: HttpRequestTypes.POST });
  }

}
