import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  disabled = false
  @Input() iconSlot: "start" | "end" | "icon-only" = "icon-only"
  @Input() iconName: string = "arrow-back-outline"
  @Input() btnText: string = ""
  @Input() color: string = "dark"
  constructor(private navigationService: NavigationService, private translate: TranslateService) {
    if (this.iconSlot !== "icon-only" && (!this.btnText || this.btnText == '')) {
      this.btnText = translate.instant("general.back_btn")
    }
  }

  ngOnInit() { }

  goBack() {
    this.disabled = true;
    this.navigationService.goBack();
    this.disabled = false;
  }
}
