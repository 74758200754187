import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthApiService } from 'src/app/core/api/auth-api.service';
import { OrbitFormValidators } from 'src/app/core/helper/class/form-validators';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { Message_Type } from 'src/app/core/models/enums/message-type';
import { ComponentType } from 'src/app/core/models/enums/phone-input-component-types';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { NewPasswordComponent } from '../new-password/new-password.component';

@Component({
  selector: 'orbit-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss', '../../auth.page.scss'],
})
export class ForgetPasswordComponent extends BaseModal implements OnInit {

  countryCodes: string = "tr"
  componentType = ComponentType.FULL_INPUT_SEPERATED
  forgotForm: FormGroup;
  stages = {

  }
  isSendCode: boolean = false;

  constructor(private modalService: ModalService,
    private formBuilder: FormBuilder,
    private authService: AuthApiService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private translateService: TranslateService) {
    super()
    super._modalService = modalService
  }

  setupForm() {
    this.forgotForm = this.formBuilder.group({
      phone: ["", [Validators.required]],
    });
  }
  ngOnInit() {
    this.setupForm();

  }
  currentCountryInfo
  dialCode
  lastPhonenumber
  phoneInputChanged(ev, type) {
    switch (type) {
      case "countryChanged":
        this.currentCountryInfo = ev;
        this.dialCode = ev.dialCode
        break;
      case "phoneChanged":
        let phone: string = ev.parsedNumber;
        this.lastPhonenumber = this.dialCode + phone.toString();
        if (this.currentCountryInfo) {
          // this.forgotForm.patchValue({ phone: phone }, { emitEvent: true })
          this.forgotForm.controls["phone"].setValue(this.dialCode + phone);
        } else {

        }
        break;
      case "phoneValidationChanged":
        if (!ev) {
          this.forgotForm.patchValue({ phone: null }, { emitEvent: true })
          // this.loginForm.controls["phone"].markAsDirty()
        }
        break;
      default:
        break;
    }
  }



  confirm(otp) {
    let payload = {
      "PhoneNumber": this.forgotForm.value.phone,
      "Otp": otp,
      "NewPassword": null
    }
    this.authService.sendSms(payload).subscribe(res => {
      if (res.IsSuccess) {
        this.modalService.presentModal({
          component: NewPasswordComponent,
          componentProps: {
            otpKey: otp,
            phoneNumber: this.forgotForm.value.phone
          },
          options: {}
        }).then(modalFP => {
          modalFP.modalObj.onDidDismiss().then(dismiss => {
          });
        });
        this.isSendCode = true;
      } else {
        if (res.ValidationErrors && res.ValidationErrors.length > 0) {
          this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + res.ValidationErrors[0]), type: Message_Type.WARNING, duration: 3000 });
        } else {
          this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + res.Message), type: Message_Type.DANGER, duration: 3000 });
        }
      }
    });



  }

  requestCode(event) {
    // this.isSendCode = true;
    let payload = {
      "PhoneNumber": event,
      "Otp": null,
      "NewPassword": null
    }
    this.loadingService.showLoading();
    this.authService.sendSms(payload).subscribe(e => {
      this.loadingService.hideLoading();
      if (e.IsSuccess) {
        this.isSendCode = true;
      } else {
        if (e.ValidationErrors && e.ValidationErrors.length > 0) {
          this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.ValidationErrors[0]), type: Message_Type.WARNING, duration: 3000 });
        } else {
          this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.Message), type: Message_Type.DANGER, duration: 3000 });
        }
      }
    });
  }
}
