import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { FromGeoJsonDirective } from "../directives/from-geo-json.directive";
import { SharedModule } from "./shared.module";

@NgModule({
    imports: [IonicModule,FormsModule,CommonModule,TranslateModule,SharedModule],
    declarations:[],
    exports:[],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FarmDrawingToolsModule{}