import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ModalService } from 'src/app/core/services/modal.service';
import { DatePickerModalComponent } from '../date-picker-modal/date-picker-modal.component';

@Component({
  selector: 'app-set-date-filter',
  templateUrl: './set-date-filter.component.html',
  styleUrls: ['./set-date-filter.component.scss'],
})
export class SetDateFilterComponent extends BaseModal implements OnInit {
  @Input() title: string;
  @Input() buttonAvailable: boolean = false;
  @Input() buttonName: string;
  @Input() subTitle: string
  @Input() startDate: any = new Date();
  @Input() endDate: any = new Date();
  @Input() firstDateName: string;
  @Input() secondDateName: string;
  @Input() polygonCenter: Array<any> = [];
  constructor(private modalService: ModalService) {
    super();
    super._modalService = modalService;
  }

  ngOnInit() {}
  
  chooseDate() {
    let presentYear = new Date().getFullYear();
    if (this.polygonCenter[1] > 0) {
      this.startDate = new Date((presentYear -= 1), 11, 1);
      this.endDate = new Date(presentYear += 1, 2, 15);
    }else{
      this.startDate = new Date((presentYear -= 1), 2, 1);
      this.endDate = new Date(presentYear += 1, 7, 1);
    }
  }

  enterDate(type: string) {
    let modalId = Date.now();
    let date = new Date();
    if (type == "startDate") {
      date = this.startDate ? this.startDate : new Date();
    } else if (type == "endDate") {
      date = this.endDate ? this.endDate : new Date();
    }
    this.modalService.presentModal({
      component: DatePickerModalComponent,
      componentProps: {
        date: date
      },
      id: modalId,
      options: ModalService.defaultOptionsTransparent
    }).then(modal => {
      modal.modalObj.onDidDismiss().then(res => {
        if (res.role == "date-data") {
          if (type == "startDate") {
            this.startDate = res.data;
          } else if (type == "endDate") {
            this.endDate = res.data;
          }
        }
      });
    });
  }

  result() {
    let res = {
      startDate: this.startDate,
      endDate: this.endDate
    }
    this.modalService.dismissByModalId(this.id, res, "filter-obj");
  }
}
