import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { DateFormats } from 'src/app/core/models/enums/date-formats';
import { ICalendarModel, ICalendarObject } from 'src/app/core/models/interfaces/calendar-model';
import { DateTimeService } from 'src/app/core/services/date-time.service';
import { LanguageService } from 'src/app/core/services/lang.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'calendar-picker-modal',
  templateUrl: './calendar-picker-modal.component.html',
  styleUrls: ['./calendar-picker-modal.component.scss'],
})
export class CalendarPickerModalComponent extends BaseModal implements OnInit {
  @Input() title: string = ""
  @Input() selectionMode: "single" | "range" = "single"
  @Input() initialDate: Date = new Date()
  /**
   * selectionMode == "range" ? Array<Date> | Array<string> : Date | string
   */
  @Input() preSelected: Array<Date> | Array<string> | Date | string = ""
  /**
   * Takvimi bu ay dışında farklı bir aydan açmak için kullanılır
   */
  @Input() startDate: Date | string = null
  @Input() min: Date | string = null
  @Input() max: Date | string = null


  selectedDate: any

  constructor(private modalService: ModalService, private langService: LanguageService, public dateTimeService: DateTimeService) {
    super()
    super.isBottomSheet = false
    super._modalService = modalService
  }

  ngOnInit() {
    super.ngOnInit()
    setTimeout(() => {
      this.contentClass = "bg-opaque"
    }, 350);
  }

  rangeChanged(ev) {
    this.selectedDate = (ev as Array<Date>).map(t => {
      return { date: t, dateStr: this.dateTimeService.formatDate(t, DateFormats.FULL_DATE_SLASH) }
    })
  }

  daySelected(ev) {
    this.selectedDate = { date: ev?.dateObj, dateStr: this.dateTimeService.formatDate(ev?.dateObj, DateFormats.FULL_DATE_SLASH) }
  }

  confirm() {
    this.dismiss(this.selectedDate, 'selected')
  }
}
