import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpRequestTypes } from '../models/enums/http-request-types';
import { IGenericConstantModel } from '../models/interfaces/generic-constant-model';
import { INotification, INotificationModel } from '../models/interfaces/notification-model';
import { IPushNotificationRequest, NotificationResponse } from '../models/interfaces/notification-settings-model';
import { ClientBaseApiService } from './client-base-api.service';

@Injectable({ providedIn: 'root' })
export class NotificationsApiService {
    constructor(private clientApiService: ClientBaseApiService) { }

    public GetNotifications(skipCount: number, takeCount: number) {
        let url = environment.baseApiUrl + `api/get/notifications?skipCount=${skipCount}&takeCount=${takeCount}`;
        return this.clientApiService.baseApiCall<Array<INotificationModel>>({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
    }

    public GetUnreadNotificationCount(notificationGroup?: number) {
        let url = environment.baseApiUrl + `api/get/count/unreadnotifications` + (notificationGroup ? "?notificationGroup=" + notificationGroup : '');
        return this.clientApiService.baseApiCall<Array<IGenericConstantModel>>({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
    }

    public GetNotificationsByGroup(groupId: number, skipCount: number, takeCount: number) {
        let url = environment.baseApiUrl + `api/get/notifications?notificationGroup=${groupId}&skipCount=${skipCount}&takeCount=${takeCount}`;
        return this.clientApiService.baseApiCall<Array<INotificationModel>>({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
    }

    /**
     * 
     * @deprecated
     */
    public GetNotificationPermissions() {
        let url = environment.baseApiUrl + "v2/api/notification/user/permission/list"
        return this.clientApiService.baseApiCall<Array<NotificationResponse>>({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
    }

    public GetNotificationPermissionsV2() {
        let url = environment.baseApiUrl + "v2/api/notification/user/permission/list/v2"
        return this.clientApiService.baseApiCall<Array<INotification>>({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
    }

    /**
     * 
     * @deprecated
     */
    public SaveNotificationPermission(payload: { NotificationTypeId: number[] }) {
        let url = environment.baseApiUrl + "v2/api/notification/save/user/permission";
        return this.clientApiService.baseApiCall<boolean>({ url: url, payload: payload, requestType: HttpRequestTypes.POST });
    }

    public SaveNotificationPermissionV2(payload: { NotificationIds: number[] }) {
        let url = environment.baseApiUrl + "v2/api/notification/save/user/permession/v2";
        return this.clientApiService.baseApiCall<boolean>({ url: url, payload: payload, requestType: HttpRequestTypes.POST });
    }

    public RegisterPushNotificationToken(payload: IPushNotificationRequest) {
        let url = environment.baseApiUrl + "v2/api/notification/set/push/token";
        return this.clientApiService.baseApiCall<any>({ url: url, payload: payload, requestType: HttpRequestTypes.POST });
    }

    public ReadSelectedNotification(payload: number) {
        let url = `${environment.baseApiUrl}update/notification/${payload}/read/status`;
        return this.clientApiService.baseApiCall<any>({ url, payload: null, requestType: HttpRequestTypes.GET })

    }

}