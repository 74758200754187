import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { StorageKeys } from "../models/enums/storage-keys";
import { StorageService } from "../services/storage.service";

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private storageService: StorageService, private router: Router) { }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return new Observable<boolean | UrlTree>(
            observe => {
                this.storageService.get(StorageKeys.TOKEN).then(response => {
                    if (response.IsSuccess && response.Data !== '' && response.Data !== null) {
                        return observe.next(true);
                    } else {
                        this.router.navigateByUrl("/auth/login-register");
                        return observe.next(false);
                    }
                }, error => {
                    this.router.navigateByUrl("/auth/login-register");
                    return observe.next(false);
                })
            }
        )
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return new Observable<boolean | UrlTree>(
            observe => {
                this.storageService.get(StorageKeys.TOKEN).then(response => {
                    if (response.IsSuccess && response.Data !== '') {
                        return observe.next(true);
                    } else {
                        this.router.navigateByUrl("/auth");
                        return observe.next(false);
                    }
                }, error => {
                    this.router.navigateByUrl("/auth");
                    return observe.next(false);
                })
            }
        )
    }

}