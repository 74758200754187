import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Haptics } from '@capacitor/haptics';
import { Network } from '@capacitor/network';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { NetworkService } from 'src/app/core/services/network.service';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-network-alert-modal',
  templateUrl: './network-alert-modal.component.html',
  styleUrls: ['./network-alert-modal.component.scss'],
})
export class NetworkAlertModalComponent extends BaseModal implements OnInit {
  langWords: { [key: string]: { [key: string]: string } } = {
    "tr": {
      "no_internet_title": "İnternet bağlantısı yok.",
      "no_internet_desc": "Uygulamayı kullanabilmek için internete bağlı olmalısınız.",
      "retry_connection": "Tekrar Dene"
    },
    "en": {
      "no_internet_title": "No internet connection",
      "no_internet_desc": "You must be connected to the internet to use the application.",
      "retry_connection": "Retry"
    },
    "bg": {
      "no_internet_title": "Няма интернет връзка",
      "no_internet_desc": "Трябва да сте свързани с интернет, за да използвате приложението.",
      "retry_connection": "Повторение"
    },
    "es": {
      "no_internet_title": "Sin conexión a Internet",
      "no_internet_desc": "Debe estar conectado a Internet para usar la aplicación.",
      "retry_connection": "Rever"
    },
    "fr": {
      "no_internet_title": "Pas de connexion Internet",
      "no_internet_desc": "Vous devez être connecté à Internet pour utiliser l'application.",
      "retry_connection": "Recommencez"
    },
    "it": {
      "no_internet_title": "Nessuna connessione internet",
      "no_internet_desc": "È necessario essere connessi a Internet per utilizzare l'applicazione.",
      "retry_connection": "Riprovare"
    },
    "pt": {
      "no_internet_title": "Sem conexão com a Internet",
      "no_internet_desc": "Você deve estar conectado à Internet para usar o aplicativo.",
      "retry_connection": "Tente novamente"
    },
    "ro": {
      "no_internet_title": "Fără conexiune internet",
      "no_internet_desc": "Trebuie să fiți conectat la Internet pentru a utiliza aplicația.",
      "retry_connection": "Din nou"
    },
    "ru": {
      "no_internet_title": "Нет соединения с интернетом",
      "no_internet_desc": "Вы должны быть подключены к Интернету для использования приложения.",
      "retry_connection": "Повторно"
    },
    "uk": {
      "no_internet_title": "Немає підключення до Інтернету",
      "no_internet_desc": "Ви повинні бути підключені до Інтернету для використання програми.",
      "retry_connection": "Повторити"
    },
  }

  currentLangCode: string = "tr"
  shakeElements: boolean = false;
  constructor(private networkService: NetworkService, private translateService: TranslateService, private loadingService: LoadingService, private modalService: ModalService, private modalController: ModalController, private storageService: StorageService) {
    super()
    super._modalService = modalService
  }

  ngOnInit() {
    if (Capacitor.isNativePlatform())
      Haptics.vibrate({ duration: 1000 }).then(res => { })
    NetworkService.networkListener.subscribe(res => {
      if (res === "online")
        this.dismiss(null)
    })

    this.storageService.get(StorageKeys.LANG).then(res => {
      let langCode = ""
      if (res.IsSuccess) {
        langCode = res.Data
      } else {
        langCode = this.translateService.getBrowserLang()
      }
      if (Object.keys(this.langWords).some(t => t === langCode)) {
        this.currentLangCode = langCode
      } else {
        this.currentLangCode = "en"
      }
    })

    this.shakeElements = true;
    setTimeout(() => {
      this.shakeElements = false;
    }, 1500);
  }
  retry() {
    this.loadingService.showLoading();
    Network.getStatus().then(e => {
      setTimeout(() => {
        if (e.connected) {
          NetworkService.setState = "online"
          this.translateService.reloadLang(this.currentLangCode).subscribe(res => {
            this.loadingService.hideLoading();
            this.dismiss(null).then()
            this.translateService.onLangChange.next({ lang: this.currentLangCode, translations: res })
          })
        } else {
          this.loadingService.hideLoading();
          if (Capacitor.isNativePlatform())
            Haptics.vibrate({ duration: 1000 }).then(res => { })
          this.shakeElements = true;
          setTimeout(() => {
            this.shakeElements = false;
          }, 1500);
        }
      }, 2000);

    })
  }


  override async dismiss(data: any, role?: string): Promise<void> {
    this.modalController.dismiss().then()
  }



}
