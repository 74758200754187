import { Component, OnInit } from '@angular/core';
import { BaseAlertModal } from 'src/app/core/models/classes/base-alert-modal';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-bottom-sheet-alert-modal',
  templateUrl: './bottom-sheet-alert-modal.component.html',
  styleUrls: ['./bottom-sheet-alert-modal.component.scss'],
})
export class BottomSheetAlertModalComponent extends BaseAlertModal implements OnInit {

  contentClass: any = "bg-default"//
  constructor(private modalService: ModalService) {
    super(modalService)
  }

  ngOnInit() {
    super.ngOnInit()
    setTimeout(() => {
      this.contentClass = "bg-opaque"
    }, 350);
  }

}
