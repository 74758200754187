import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UnitPreferenceService } from '../services/unit-preference.service';

@Pipe({
  name: 'temperature',
  pure: false
})
export class TemperaturePipe implements PipeTransform, OnDestroy {

  onTypeChanged: Subscription
  previousResponse: any
  previousType: any
  previouesData: any

  /**
   *
   */
  constructor(private unitService: UnitPreferenceService, private _ref: ChangeDetectorRef, private translateService: TranslateService) {

  }

  transform(value: number | string, type?: string, fractionDigits = 2): string {
    if ((!value && value != 0) || value == -99) {
      return this.translateService.instant("constants.N/A");
    }
    if (typeof value === 'string')
      value = Number.parseInt(value);
    if (!(type && type !== ''))
      type = this.unitService.selectedTemperatureUnit;

    if (value == null || value == undefined || isNaN(value)) {
      return value + "";
    }

    if (this.previouesData && this.previouesData == value && this.previousType == type) {
      return this.previousResponse;
    }
    else {
      this._dispose()
      if (!this.onTypeChanged) {
        this.onTypeChanged = this.unitService.changeSubject.subscribe(res => {
          if (type !== this.unitService.selectedTemperatureUnit) {
            type = this.unitService.selectedTemperatureUnit;
            this._ref.detectChanges()
            this._ref.markForCheck()
          }
        })
      }
      this.previousType = type;
      this.previouesData = value
      let response = this.unitService.TemperatureConvert(value, type, fractionDigits);
      this.previousResponse = response
      return response//this.unitService.TemperatureConvert(value, type,fractionDigits);
    }
  }

  private _dispose() {
    if (typeof this.onTypeChanged != "undefined") {
      this.onTypeChanged.unsubscribe();
      this.onTypeChanged = undefined
    }
  }

  ngOnDestroy(): void {
    this._dispose()
  }
}
