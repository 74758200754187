import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { IListModalModel } from 'src/app/core/models/interfaces/list-modal-component-model';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-list-modal',
  templateUrl: './list-modal.component.html',
  styleUrls: ['./list-modal.component.scss'],
})
export class ListModalComponent extends BaseModal implements OnInit {
  @Input() title: string = ""
  @Input() hasConfirmBtn: boolean = false
  @Input() confirmBtnText: string = "confirm"
  @Input() isMultiple: boolean = false
  @Input() itemList: Array<IListModalModel> = []
  @Input() isFullscreen: boolean = false
  @Input() showSearchbar: boolean = true
  @Input() placeholder: string = ""
  areaHeight: number = 0;
  // contentClass: any = "bg-default"
  filteredList: Array<IListModalModel> = [];
  selectedList: Array<IListModalModel> = [];

  constructor(private modalService: ModalService) {
    super()
    super._modalService = modalService
    // this.demoArray = new Array(2).fill(1).map(t => 1)

  }

  ngOnInit() {
    var hasButton = this.hasConfirmBtn ? 1 : 0;
    var hasSearch = this.showSearchbar ? 1 : 0;
    this.areaHeight = 16 + 44 + 60 * hasSearch + 52 * hasButton;
    super.ngOnInit()
    this.filteredList = this.itemList;
    this.selectedList = this.itemList.filter(t => t.isChecked)
    setTimeout(() => {
      this.contentClass = "bg-opaque"
    }, 350);
  }

  ionViewDidLeave() {
    this.filteredList = [];
  }


  dismissModal() {
    super.dismiss({}, '')
    // this.modalService.dismissByModalId(this.id, {}).then(response => { })
  }
  applyBtn() {
    if (this.isMultiple)
      this.dismiss({ selectedItem: this.selectedList }, "itemSelected");
    else
      this.dismiss({ selectedItem: this.selectedList[0] }, "itemSelected");
  }
  clear() {
    this.selectedList.forEach(e => {
      e.isChecked = false;
    });
    this.selectedList = [];
  }

  itemClicked(item) {
    if (this.hasConfirmBtn) {
      if (this.isMultiple) {
        if (item.isChecked)
          this.selectedList = this.selectedList.filter(t => t.id !== item.id)
        else
          this.selectedList.push(item)
      } else {
        this.filteredList.forEach(e => e.isChecked = false)
        item.isChecked = true;
        this.selectedList = [];
        this.selectedList.push(item);
      }
      item.isChecked = !item.isChecked;
    } else {
      this.filteredList.forEach(e => e.isChecked = false)
      item.isChecked = true;
      setTimeout(() => {
        this.dismiss({ selectedItem: item }, "itemSelected")
      }, 300);
    }
  }

  onSearchChange(ev) {
    if (!(ev?.detail?.value == null || ev?.detail?.value == undefined)) {
      let query = ev.detail.value.toLowerCase();
      this.filteredList = this.itemList.filter(t => t.value.toLowerCase().includes(query)).map(t => {
        return t;
      })
    }
    else {

    }
  }
}
