import { Injectable, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { StorageKeys } from '../models/enums/storage-keys';
import { ModalService } from './modal.service';
import { IAuthResponse } from '../models/interfaces/auth-response';
import { environment } from 'src/environments/environment';
import { ClientBaseApiService } from '../api/client-base-api.service';
import { HttpRequestTypes } from '../models/enums/http-request-types';
import { IGenericResponse } from '../models/interfaces/i-generic-response';
@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(
    private storageService: StorageService,
    private clientApi: ClientBaseApiService

  ) { }

  public sendForm(payload: any): Observable<IGenericResponse<boolean>> {
    let url = environment.baseApiUrl + "v2/api/email/sendmail";
    return this.clientApi.baseApiCall<boolean>({ url, payload: payload, requestType: HttpRequestTypes.POST });
  }

  send(message: string, contactUsModel: { content: string, contentHeader: string, buttonName: string }, deviceId: number) {
    this.storageService.get(StorageKeys.USER).then(result => {
      if (result.IsSuccess) {
        let user: IAuthResponse = result.Data;
        let content = "<table style='border:1px solid;border-radius:8px;padding:10px'>" +
          "<tr><td>Message :</td><td>" + message + "</td></tr>" +
          "<tr><td>Name Surname :</td><td>" + user.NameSurname + "</td></tr>" +
          "<tr><td>E-mail :</td><td>" + user.UserMail + "</td></tr>" +
          "<tr><td>Phone :</td><td>" + user.UserTelNo + "</td></tr>" +
          "<tr><td>Cihaz ID :</td><td>" + deviceId + "</td></tr></table>";

        let payload = {
          Message: content,
          Email: "support@doktar.com",
          Subject: "Lisans Yenileme Bilgilendirmesi",
          IsHtml: true,
        };
        this.sendForm(payload).subscribe(res => {
          if (res?.IsSuccess) {
            console.log("res",res)
          }
        });
      }

    });

  }
}

