import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from "@capacitor/push-notifications";
import { StorageKeys } from '../models/enums/storage-keys';
import { ToastService } from './toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Message_Type } from '../models/enums/message-type';
import { Router } from '@angular/router';
import { CommonService } from '../api/common.service';
import { ProfileService } from 'src/app/pages/common/profile/services/profile.service';
import { ContainList, INotificationResponse } from 'src/app/pages/common/models/notification';
import { NotificationResponse } from '../models/interfaces/notification';
import { catchError, map } from "rxjs/operators";
import { of } from 'rxjs/internal/observable/of';
import { IGenericResponse } from '../models/interfaces/i-generic-response';
import { NotificationsApiService } from '../api/notification-api.service';
import { AccountService } from './account.service';
import { CleverTapService } from './clevertap.service';
import { AndroidPermissionService, PermissionType } from './android-permission.service';
import { VisualThemeModeService } from './visual-theme-mode.service';
import { ClientStatusBarService } from './client-status-bar.service';
import { FarmApiService } from '../api/farm-api.service';
import { IFarmModel } from '../models/interfaces/farm-model';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    platform: string = "";
    notificationPermissionItemsList: Array<INotificationResponse>

    constructor(private storageService: StorageService, private farmApiService: FarmApiService,
        private toastService: ToastService, private commonService: CommonService, private profileService: ProfileService, private router: Router, private notificationApi: NotificationsApiService, private cleverTapService: CleverTapService, private androidPermissions: AndroidPermissionService) {
        this.platform = Capacitor.getPlatform();
    }

    checkForPermission() {
        return new Observable(observe => {
            switch (this.platform) {
                case "ios":
                    PushNotifications.checkPermissions().then(response => {
                        observe.next(true)
                    })
                    break;
                case "android":
                    this.androidPermissions.requestPermisson(PermissionType.Notification).subscribe(res => {
                        console.log(JSON.stringify(res))
                        observe.next(res)
                    })
                    // })
                    break;
                case "web":
                default:
                    observe.next(true)
                    break;
            }
        })
    }
    AddPushNotificationRegistrationListeners() {
        if (Capacitor.getPlatform() === 'web')
            return;
        PushNotifications.checkPermissions().then(permStatus => {
            if (permStatus.receive === 'prompt') {
                PushNotifications.requestPermissions().then(reqPermStatus => {
                    if (reqPermStatus.receive !== 'granted') {
                        throw new Error('User denied permissions!');
                    } else {
                        PushNotifications.register();
                    }
                })
            } else if (permStatus.receive !== 'granted') {
                // throw new Error('User denied permissions!');
            } else {
                PushNotifications.register();
            }

            // if (permStatus.receive !== 'granted') {
            //     throw new Error('User denied permissions!');
            // }
        });

        PushNotifications.addListener('registration', token => {
            this.storageService.set(StorageKeys.DEVICE_TOKEN, token.value).then();
            // this.storageService.deviceToken = token.value;

            if (Capacitor.getPlatform() == "ios") {
                if (token.value.startsWith("fcmtoken_")) {
                    let pushtoken = token.value.replace("fcmtoken_", "")
                    StorageService.DeviceToken = pushtoken;
                    this.storageService.deviceToken = pushtoken;
                    if (AccountService.token)
                        this.notificationApi.RegisterPushNotificationToken({ Platform: Capacitor.getPlatform(), PushToken: pushtoken }).subscribe(response => {

                        })
                } else {
                    StorageService.ApnsToken = token.value
                    // this.cleverTapService.activate(token.value)
                }
            } else if (Capacitor.getPlatform() == "android") {
                PushNotifications.createChannel({
                    id: "default-channel",
                    name: "default-channel"
                }).then()
                StorageService.DeviceToken = token.value;
                this.storageService.deviceToken = token.value;
                // this.cleverTapService.activate(token.value)
                if (AccountService.token)
                    this.notificationApi.RegisterPushNotificationToken({ Platform: Capacitor.getPlatform(), PushToken: token.value }).subscribe(response => {

                    })
            }
            // this.storageService.get(StorageKeys.NOTIFICATION_STATUS).then(response => {
            //     StorageService.DeviceToken = response.IsSuccess && response.Data && response.Data === "off" ? null : token.value;
            //     this.storageService.deviceToken = response.IsSuccess && response.Data && response.Data === "off" ? null : token.value;
            // });
        })

        PushNotifications.addListener('registrationError', err => {

        });

    }

    registerNotification() {
        if (this.platform == "web") {
            return;
        }
        PushNotifications.requestPermissions().then(result => {
            PushNotifications.register();
        });
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: Token) => {
                console.log("Push token:" + token.value);
                this.storageService.set(StorageKeys.DEVICE_TOKEN, token.value);
                this.storageService.deviceToken = token.value;
                this.commonService.pushNotification({ Platform: this.platform, PushToken: token.value }).subscribe();
            }
        );
        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (err: any) => {
                // console.log(JSON.stringify(err));
            }
        );
    }

    notification_OnRecived(handler) {
        if (this.platform == "web") {
            return;
        }
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                handler(notification);
            }
        );
    }

    notification_OnPerformed(handler) {
        if (this.platform == "web") {
            return;
        }
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                handler(notification);
            }
        );
    }

    pushNotificationOperations() {
        let themeService = new VisualThemeModeService(this.storageService, new ClientStatusBarService);
        this.checkForPermission().subscribe(res => { })
        this.AddPushNotificationRegistrationListeners();
        this.notification_OnRecived((e) => {
            this.toastService.PresentBasicToast({
                message: e.data.NotificationText,
                duration: 3000,
                type: Message_Type.WARNING
            });
        });
        //---notification servisi
        this.notification_OnPerformed((e) => {
            let notResult = e as NotificationResponse;
            console.log("bildirim =>", JSON.stringify(e))
            if (notResult.actionId == "tap") {
                let payload = notResult.notification.data;
                let deviceType
                console.log("payload => ", JSON.stringify(payload))
                if (payload) {
                    if (payload.TempFarmAttId) {
                        FarmApiService.SelectedFarm = { TempFarmAttId: payload.TempFarmAttId } as IFarmModel
                        this.farmApiService.GetAll().subscribe()
                    }
                    if (payload.RedirectUri.includes("devicetype={{devicetype}}")) {
                        let uri = payload.RedirectUri.replace("devicetype={{devicetype}}", "")
                        uri = uri.endsWith("?") ? uri.replace("?", "") : uri.endsWith("&") ? uri.slice(0, (uri.length - 1)) : uri
                        this.router.navigate[uri];
                    } else {
                        let url = payload.RedirectUri.split("devicetype=")[0];
                        url = url.endsWith("?") ? url.replace("?", "") : url.endsWith("&") ? url.slice(0, (url.length - 1)) : url
                        let url2 = payload.RedirectUri.split("?");
                        if (url2.length > 1) {
                            deviceType = url2[1].split('&').find(e => e.startsWith('devicetype=')).replace("devicetype=", "");
                        }
                        console.log("payload TempFarmAttId => ", JSON.stringify(payload.TempFarmAttId))
                        if (deviceType == 'filiz') {
                            if (payload.TempFarmAttId) {
                                this.storageService.lastFilizFarmId = payload.TempFarmAttId;
                                themeService.setFilizTheme().subscribe();
                                this.storageService.set(StorageKeys.FILIZ_MODE, true).then();
                                this.storageService.set(StorageKeys.LAST_FILIZ_FARM, payload.TempFarmAttId).then()
                                this.router.navigateByUrl(url);
                                // this.router.navigate(["filiz/" + payload.RedirectUri]);
                            }
                        } else if (deviceType == 'trap') {
                            if (payload.TempFarmAttId) {
                                this.storageService.lastTrapFarmId = payload.TempFarmAttId;
                                themeService.setPesTrappTheme().subscribe();
                                this.storageService.set(StorageKeys.FILIZ_MODE, false).then;
                                this.storageService.set(StorageKeys.LAST_TRAP_FARM, payload.TempFarmAttId).then()
                                this.router.navigateByUrl(url);
                                // this.router.navigate(["pesttrapp/" + payload.RedirectUri]);
                            }
                        } else {
                            this.router.navigate["common/pick-device"];
                        }
                    }

                }
            }
        });
    }
    public GetNotificationPermissions() {
        return this.profileService.getNotifications().pipe(catchError(val => of(val as IGenericResponse<INotificationResponse[]>)));
    }

    public NotificationPermission() {
        this.GetNotificationPermissions().subscribe(res => {
            let openedOnes = []
            if (res && res.Data) {
                this.notificationPermissionItemsList = res.Data
                let imagerySlaveNotification: ContainList = {} as ContainList;
                this.notificationPermissionItemsList.forEach(el => {
                    imagerySlaveNotification = imagerySlaveNotification || el.ContainList.find(t => t.SlaveId == 1007)
                    openedOnes = [...el.ContainList.filter(t => t.IsPermission).map(t => t.SlaveId), ...openedOnes]
                })
            }
        })
    }
}
