import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { App } from "@capacitor/app";
import { TranslateService } from "@ngx-translate/core";
import { StorageKeys } from "../models/enums/storage-keys";
import { LanguageService } from "./lang.service";
import { StorageService } from "./storage.service";

export function OnAppInit(appinit: AppInitService) {
    return () => {
        return new Promise((resolve, reject) => {
            appinit.storageService.initStorage().subscribe(res => {

                appinit.storageService.get<string>(StorageKeys.LANG).then(langResponse => {
                    if (langResponse && langResponse.IsSuccess && langResponse.Data !== '') {
                        appinit.translateService.use(langResponse.Data).subscribe(resp => {
                            // console.log("lang setted => ", resp)
                            resolve(true)
                        }, err => {
                            resolve(true)
                        })
                    } else {
                        appinit.translateService.use(appinit.translateService.getBrowserLang()).subscribe(resp => {
                            // console.log("en selected => ", resp)
                            resolve(true)
                        }, err => {
                            resolve(true)
                        })
                        // this.langService.setCurrentLang(this.langService.getBrowserLang());
                    }
                }).catch(err => {
                    appinit.translateService.use(appinit.translateService.getBrowserLang()).subscribe(resp => {
                        // console.log("en selected => ", resp)
                        resolve(true)
                    }, err => {
                        resolve(true)
                    })
                })

                // let routeApp = () => appinit.router.navigate(['/onboarding-tutorial'], { replaceUrl: true, skipLocationChange: false })
                // appinit.storageService.get(StorageKeys.TUTORIAL_LAST_SHOWED).then(resp => {
                //     if (resp && resp.IsSuccess && resp.Data) {
                //         App.getInfo().then(app => {
                //             if (resp.Data !== app.build) {
                //                 console.log(1)
                //                 routeApp()
                //                 resolve(true)
                //                 // openModalFn().then()
                //             } else {
                //                 resolve(true)
                //             }
                //         })
                //     } else {
                //         console.log(2)
                //         routeApp()
                //         resolve(true)
                //         // openModalFn().then()
                //     }
                // }).catch(err => {
                //     console.log(3)
                //     routeApp()
                //     resolve(true)
                //     // openModalFn().then()
                // })
            }, err => {
                resolve(true)
            })

        })
    }
}



@Injectable({ providedIn: 'root' })
export class AppInitService {
    constructor(public router: Router, public storageService: StorageService, public translateService: TranslateService) { }

}
