import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientBaseApiService } from 'src/app/core/api/client-base-api.service';
import { HttpRequestTypes } from 'src/app/core/models/enums/http-request-types';
import { INewImageModel } from 'src/app/core/models/interfaces/new-image-model';
import { MediaService } from 'src/app/core/services/media.service';
import { environment } from 'src/environments/environment';
import { IFlagSaveModel } from '../models/farm-map.model';
import { DeletedFarmsAlertModalComponent } from 'src/app/components/deleted-farms-alert-modal/deleted-farms-alert-modal.component';
import { FarmApiService } from 'src/app/core/api/farm-api.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Injectable({
  providedIn: 'root'
})
export class FarmMapService {

  deletedFarmsCount: number = 0
  constructor(private clientService: ClientBaseApiService, private mediaService: MediaService, private farmApiService: FarmApiService, private modalService: ModalService) { }

  getFarmList() {
    let url = environment.baseApiUrl + "v2/api/farm/filter/list";
    return this.clientService.baseApiCall({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
  }

  getScoutingInformation(id: number) {
    let url = environment.baseApiUrl + "v2/api/farm/flags/information?flagId=" + id;
    return this.clientService.baseApiCall({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
  }

  removeNote(payload) {
    let url = environment.baseApiUrl + "v2/api/farm/flags/delete"
    return this.clientService.baseApiCall<number>({ url, payload: payload, requestType: HttpRequestTypes.POST });
  }

  public addScoutingNote(payload: IFlagSaveModel, imageList: Array<INewImageModel>): Observable<any> {

    return new Observable(observer => {
      this.addNotes(payload).subscribe(response => {
        if (response.IsSuccess) {
          if (Array.isArray(imageList) && imageList.length > 0) {
            let requestList = []
            let imagesFormData = new FormData()
            imageList.filter(t => t && t.type === 'new').forEach(el => {
              // imagesFormData.append('photo', el.image, `orbit_image_${Date.now()}.${el.format}`)
              imagesFormData.append('Files', el.image, `image_${Date.now()}.${el.format}`)
              // let formData = prepareImagesFormData();
            })
            imagesFormData.append("Lon", payload.Lon.toString())
            imagesFormData.append("Lat", payload.Lat.toString())
            imagesFormData.append("TempFarmAttId", payload.TempFarmAttId.toString())
            imagesFormData.append("FlagId", response.Data.toString())
            imagesFormData.append("Description", payload.Description)

            let flagId = response.Data
            // let uploadUrl = environment.baseApiUrl + "upload/" + flagId + "/photo"
            let uploadUrl = environment.baseApiUrl + "v2/api/farm/flags/photos/save"
            // observer.next({ flag: ['completed', response], images: ['start'] })
            this.mediaService.uploadPhoto(uploadUrl, imagesFormData).subscribe(imageResponse => {
              if (imageResponse.IsSuccess)
                observer.next({ flag: ['completed', response], images: ['completed', imageResponse] })
              else
                observer.next({ flag: ['completed', response], images: ['failed', imageResponse] })
              observer.complete()
            })

          } else {
            observer.next({ flag: ['completed', response], images: ['no_image'] })
            observer.complete()
          }
        } else {
          observer.next({ flag: ['failed', response], images: [] })
          observer.complete()
        }
      }, err => {
        observer.next({ flag: ['error', err], images: [] })
        observer.complete()
      })
    })
  }

  addNotes(payload: IFlagSaveModel) {
    // let url = environment.baseApiUrl + "save/farm/flags";
    let url = environment.baseApiUrl + "v2/api/farm/flags/save";
    return this.clientService.baseApiCall<any>({ url, payload, requestType: HttpRequestTypes.POST })
  }
  public CheckIfAnyFarmDeletedByOwner() {
    this.farmApiService.IsThereAnyDeletedFarm().subscribe(res => {
      if (res.IsSuccess) {
        let data: Array<{ TempFarmAttId, FarmName, GeoItem }> = res.Data;
        if (Array.isArray(data) && data.length) {
          this.modalService.presentModal({
            component: DeletedFarmsAlertModalComponent,
            componentProps: { farmList: data },
            options: ModalService.defaultOptionsTransparent
          }).then()
        }
      }
    })
  }

  isExpiredDevice(deviceId:any):Observable<boolean> {
    return new Observable(observer=>{
      observer.next(true);
    });
  }
}
