import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'sms-validation',
  templateUrl: './sms-validation.component.html',
  styleUrls: ['./sms-validation.component.scss', '../../auth.page.scss'],
})
export class SmsValidationComponent implements OnInit {

  number: string = "";
  data: any;
  otpForm: FormGroup = new FormGroup({
    otp: new FormControl("", Validators.required)
  });
  intervalId: any;
  isSendCode: boolean = false;
  counter: { min: number, sec: number }

  @Input() phoneNumber: any;
  @Output() onConfirm: EventEmitter<any> = new EventEmitter();
  @Output() onSendAgain: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: ModalService) {
    // super();
    // super._modalService = modalService;
  }


  ngOnInit() {
    this.number = this.phoneNumber?.substr(this.phoneNumber.length - 4);
    this.startTimer();
  }


  startTimer() {
    this.counter = { min: 3, sec: 0 } // choose whatever you want
    this.intervalId = setInterval(() => {
      if (this.counter.sec - 1 == -1) {
        this.counter.min -= 1;
        this.counter.sec = 59;
      }
      else this.counter.sec -= 1
      if (this.counter.min === 0 && this.counter.sec == 0) {
        clearInterval(this.intervalId)
        this.isSendCode = false;
        this.sendAgain();
      }
    }, 1000)
  }

  onOtpChange(event) {
    if (event.length == 4) {
      this.otpForm.controls["otp"].setValue(event);
    } else {
      this.otpForm.controls["otp"].setValue(null);
    }
  }

  sendAgain() {
    this.startTimer();
    this.otpForm.controls["otp"].setValue(null);
    this.onSendAgain.emit(this.phoneNumber);
  }

  requestCode() {
    this.onConfirm.emit(this.otpForm.value.otp);
  }

}
