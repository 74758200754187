import { Injectable } from "@angular/core";
import { AlertController, ModalController, ModalOptions } from "@ionic/angular";
import { AlertModalComponent } from "../helper/components/alert-modal/alert-modal.component";
import { FloatAlertModalComponent } from "../helper/components/float-alert-modal/float-alert-modal.component";
import { IBaseAlertModel, IBasePopupModel, IButtomSheetAlertModel } from "../models/interfaces/modal-components-models";
import { ModalService } from "./modal.service";
import { ComponentRef } from "@ionic/core";
import { TranslateService } from "@ngx-translate/core";
import { PopupModalComponent } from "../helper/components/popup-modal/popup-modal.component";
import { BottomSheetAlertModalComponent } from "../helper/components/bottom-sheet-alert-modal/bottom-sheet-alert-modal.component";

@Injectable({
    providedIn: "root"
})
export class ClientAlertService {
    /**
     *
     */
    alert: any;
    constructor(private modalController: ModalController,
        private modalService: ModalService,
        private translateService: TranslateService,
        private alertController: AlertController) {

    }

    public async PresentAlert(props: IBaseAlertModel, isFloat: boolean = true) {
        const modal = await this.modalService.presentModal({
            component: isFloat ? FloatAlertModalComponent : AlertModalComponent,
            componentProps: props,
            options: { backdropDismiss: true, cssClass: "modal-transparent" } as ModalOptions<ComponentRef>
        })
        return modal;
    }

    public async confirmAlert(props, isFloat: boolean = false) {
        const modal = await this.modalService.presentModal({
            component: isFloat ? FloatAlertModalComponent : AlertModalComponent,
            componentProps: props,
            options: { backdropDismiss: true, cssClass: "modal-transparent" } as ModalOptions<ComponentRef>
        })
        return modal;
    }

    async confirmAlertController(message: string = "Bu işlemi yapmak istediğinizden emin misiniz?") {
        this.alert = await this.alertController.create({
            mode: "ios",
            header: this.translateService.instant("general.warning"),
            message: message,
            buttons: [
                {
                    text: this.translateService.instant("general.no"),
                    role: "false",
                    handler: () => {
                        this.alert.dismiss(false);
                    }
                }, {
                    text: this.translateService.instant("general.yes"),
                    role: "true",
                    handler: () => {
                        this.alert.dismiss(true);
                    }
                }]
        });
        await this.alert.present();
    }
    public async PopupAlert(props: IBasePopupModel) {
        const modal = await this.modalService.presentModal({
            component: PopupModalComponent,
            componentProps: props,
            options: { backdropDismiss: true, cssClass: "modal-transparent" } as ModalOptions<ComponentRef>
        })
        return modal;
    }
    

    public async BottomSheetAlert(props: IButtomSheetAlertModel){
        const modal = await this.modalService.presentModal({
            component: BottomSheetAlertModalComponent,
            componentProps: props,
            options: { backdropDismiss: true, cssClass: "modal-transparent" } as ModalOptions<ComponentRef>
        })
        return modal;
    }
}