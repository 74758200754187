import { IAuthResponse } from "../interfaces/auth-response";

export class UserData implements IAuthResponse {
    UserId: number;
    FarmerId: number;
    NameSurname: string;
    RoleId: number;
    CompanyId: number;
    UserMail: string;
    IsAuthorize: boolean;
    ProfilePictureUrl: string;
    Token: string;
    AuthToken: string;
    UserTelNo: number;
    UserTypeId: number;
    VillageId: number;
    DistrictId: number;
    CityId: number;
    UserName: number;
    Password: string;
    Platform: string;
    RoleEndDate: string;
    UserRoleId: number;
    CampaignId: number;
    DevicePlatform: string;
    FarmerSubscriberId: number;
    IsForceUpdate: boolean;
    IsServiceAuth: boolean;
    IsDisclaimerConfirm: boolean;
    IsNotificationEnabled: boolean;
    GroupName: string;
    IsAdmin: boolean;
    TotalFarmCount: number;
    TotalFarmArea: number;
    UserTitle: string;
    CityName: string;
    Age: string;
    PhotoUrl: string;
    Email: string;


    constructor(props: IAuthResponse) {
        this.UserId = props.UserId
        this.FarmerId = props.FarmerId
        this.NameSurname = props.NameSurname
        this.RoleId = props.RoleId
        this.CompanyId = props.CompanyId
        this.UserMail = props.UserMail
        this.ProfilePictureUrl = props.ProfilePictureUrl
        this.Token = props.Token
        this.UserTelNo = props.UserTelNo
        this.UserTypeId = props.UserTypeId
        this.VillageId = props.VillageId
        this.DistrictId = props.DistrictId
        this.CityId = props.CityId
        this.UserName = props.UserName
        this.RoleEndDate = props.RoleEndDate
        this.UserRoleId = props.UserRoleId
        this.CampaignId = props.CampaignId
        this.FarmerSubscriberId = props.FarmerSubscriberId
        this.IsNotificationEnabled = props.IsNotificationEnabled
        this.TotalFarmCount = props.TotalFarmCount
        this.TotalFarmArea = props.TotalFarmArea
    }
}

    export interface UserRoleProperty {
        Key: string;
        Value: string;
        Type: string;
    }

    export interface ProfileUser {
        NameSurname: string;
        Email: string;
        Age: Date;
        CityId: number;
        CityName: string;
        UserId: number;
        PhotoUrl: string;
        UserRoleId: number;
        UserRoleProperties: UserRoleProperty[];
    }
