import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppEventTypes } from '../models/enums/app-event-types';
import { IAppEventBase } from '../models/interfaces/app-event-base';

@Injectable({
    providedIn: 'root'
})
export class AppEventListenerService {
    private static subject = new Subject<IAppEventBase>();
    constructor() { 
    }

    public static EmitEvent(event: AppEventTypes , data?: any) {
        AppEventListenerService.subject.next({ event: event, data: data } as IAppEventBase);
    }
    
    public static get GetListener(): Observable<IAppEventBase> {
        return AppEventListenerService.subject.asObservable();
    }
}
