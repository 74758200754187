import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { ClientBaseApiService } from '../api/client-base-api.service';
import { StorageKeys } from '../models/enums/storage-keys';
import { IGenericResponse } from '../models/interfaces/i-generic-response';
import { HttpRequestTypes } from '../models/enums/http-request-types';

@Injectable({
  providedIn: 'root'
})
export class AnalyticService {
  private isEventFired: boolean = false;
  constructor(private clientApi:ClientBaseApiService, private router: Router, private storageService: StorageService) {

  }

  trackNavigationEnd() {

    if (!this.isEventFired) {
      this.isEventFired = true;
      this.router.events
        .subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.storageService.get(StorageKeys.TOKEN).then(token => {
              if (token) {
                let encodedUrl = event.url.replace("/","%2F");
                this.sendState(event.url).subscribe();
              }
            })
          }
        })
    }
  }

  sendState(stateName: string, desc?: string): Observable<IGenericResponse> {
    return new Observable(observer => {
      const serviceUrl = environment.baseApiUrl+"api/" + `send/state/status?stateName=${stateName}`;
      this.clientApi.baseApiCall({ url: serviceUrl, payload: null, requestType: HttpRequestTypes.POST }).subscribe(res => {
        observer.next(res);
      },
        err => {
          let error = {
            IsSuccess: false,
            Message: err.message,
            Data: null,
            StatusCode: -11
          } as IGenericResponse
          observer.next(error);
          return;
        }, () => {
          observer.complete();
        });
    });
  }
  
}
