import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Platform } from "@ionic/angular";

@Injectable({
    providedIn: "root"
})
export class NavigationService {
    private history: Array<any> = []
    /**
     *
     */
    constructor(private activatedRoute: ActivatedRoute, private router: Router, private location: Location, private platform:Platform) {

    }
    private isTracking: boolean = false;
    trackNavigation(): void {
        if (!this.isTracking) {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.history.push(event.urlAfterRedirects)
                }
            })
        }
    }

    public overrideBackButton(){
        //TODO burasını incele. işe yarar bir şeyler çıkartabiliriz. capacitor
        this.platform.backButton.subscribe(() => {
            this.goBack();
        })
    }

    public goBack(): void {
        let currentPageUrl = this.history.pop();
        if (this.history.length > 0) {
            this.location.back()
        } else {
            this.router.navigateByUrl("/")
        }
    }

    public getPreviousPage(): string {
        let length = this.history.length;
        return length > 1 ? this.history[length - 2] : ""
    }
}