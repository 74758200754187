import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ListItem } from 'src/app/core/models/classes/list-item';
import { AnalyticService } from 'src/app/core/services/analytic.service';
import { LanguageService } from 'src/app/core/services/lang.service';

@Component({
  selector: 'select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
})
export class SelectLanguageComponent extends BaseModal implements OnInit {
  langOptions = []
  isModalOpened: boolean = false;

  constructor(public langService: LanguageService, private translateService: TranslateService,private analyticService:AnalyticService) {
    super()
    super._modalService = langService.modalService
  }

  ngOnInit() {
    super.ngOnInit()
    this.langService.getLangOpts().subscribe(response => {
      this.langOptions = response.map(t => {
        return { Id: t.code, Value: t.lang, icon: "./assets/lang-flags/" + t.code + ".svg", isChecked: t.code === this.translateService.currentLang } as ListItem;
      })
    })
    // console.log("langOptions",this.langOptions)
  }

  selectOption(item) {
    var that = this;
    this.langService.setCurrentLang(item.Id);
    setTimeout(() => {
      that.analyticService.sendState("change-language").subscribe();
    }, 1000);
    
    this.dismissModal()
  }

  dismissModal() {
    this.langService.dismissOptionsModal(this.id)
  }
}
