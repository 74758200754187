import { Component, Input, OnInit } from "@angular/core";
import { ModalService } from "../../services/modal.service";

@Component({
    selector: "",
    template: ""
})
export class BaseModal implements OnInit {
    @Input() id: number
    @Input() initialBreakpoint: number
    @Input() bottomPadding: number = 0;
    @Input() isBottomSheet:boolean = false
    public contentClass: any = "bg-default"//
    _modalService: ModalService
    /**
     *
     */
    constructor() {

    }

    ngOnInit(): void {
        if (this.initialBreakpoint) {
            let diff = 1 - this.initialBreakpoint;
            this.bottomPadding = 100 * diff;
        }
    }

    async dismiss(data, role = "canceled") {
        this.contentClass = "bg-default"
        await this._modalService.dismissByModalId(this.id, data, role)
    }
}