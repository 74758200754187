import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UnitPreferenceService } from '../services/unit-preference.service';

@Pipe({
    name: 'speed',
    pure: false
})
export class SpeedPipe implements PipeTransform, OnDestroy {
    onTypeChanged: Subscription
    previousResponse: any
    previousType: any
    previouesData: any

    /**
     *
     */
    constructor(private unitService: UnitPreferenceService, private _ref: ChangeDetectorRef,
        private translateService: TranslateService) {

    }

    transform(value: number, type?: string): string {
        if ((!value && value != 0) || value == -99) {
            return this.translateService.instant("constants.N/A");
        }
        if (!(type && type !== ''))
            type = this.unitService.selectedSpeedUnit;

        if (value == null || value == undefined || isNaN(value)) {
            return value + "";
        }

        if (this.previouesData && this.previouesData == value && this.previousType == type) {
            return this.previousResponse;
        }
        else {
            this._dispose()
            if (!this.onTypeChanged) {
                this.onTypeChanged = this.unitService.changeSubject.subscribe(res => {
                    if (type !== this.unitService.selectedSpeedUnit) {
                        type = this.unitService.selectedSpeedUnit;
                        this._ref.detectChanges()
                        this._ref.markForCheck()
                    }
                })
            }
            this.previousType = type;
            this.previouesData = value
            let response = this.unitService.SpeedConvert(value, type);
            this.previousResponse = response
            return response//this.unitService.SpeedConvert(value, type);
        }
    }

    private _dispose() {
        if (typeof this.onTypeChanged != "undefined") {
            this.onTypeChanged.unsubscribe();
            this.onTypeChanged = undefined
        }
    }

    ngOnDestroy(): void {
        this._dispose()
    }
}
