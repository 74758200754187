
import { FilizWalkthroughStepKeys, TrappWalkthroughStepKeys } from "../enums/storage-keys";

export const HistoryGraphs: IWalkthroughMaster = {
    key: FilizWalkthroughStepKeys.HISTORY_GRAPHS,
    mainUrl: "/filiz/history",
    queryParam: "",
    values: [
        {
            key: 'filiz_graph_chart',
            targetElementId: 'filiz_graph_chart',
            nextItem: 'graph_opts',
            presentType: "popover",
            header: "graph_chart_header",
            description: "graph_chart_desc",
            popoverButtons: { isNextActive: true, isSkipActive: false, isBackActive: false },
            nextButton: {
                text: "walkthrough.next_btn_text",
                class: "",
                handler: null
            },
            backButton: {
                text: "walkthrough.back_btn_text",
                class: "",
                handler: null
            }
        },
        {
            key: 'graph_opts',
            targetElementId: 'graph_opts',
            nextItem: 'graph_filter',
            presentType: "popover",
            popoverButtons: { isNextActive: true, isSkipActive: false, isBackActive: false },
            header: "graph_opts_header",
            description: "graph_opts_desc",
            nextButton: {
                text: "walkthrough.next_btn_text",
                class: "",
                handler: null
            },
            backButton: {
                text: "walkthrough.back_btn_text",
                class: "",
                handler: null
            }
        },
        {
            key: 'graph_filter',
            targetElementId: 'graph_filter',
            nextItem: 'graph_date_opts',
            presentType: "popover",
            popoverButtons: { isNextActive: true, isSkipActive: false, isBackActive: false },
            header: "graph_filter_header",
            description: "graph_filter_desc",
            nextButton: {
                text: "walkthrough.got_it_btn_text",
                class: "",
                handler: null
            },
            backButton: {
                text: "walkthrough.back_btn_text",
                class: "",
                handler: null
            }
        },
        {
            key: 'graph_date_opts',
            targetElementId: 'filter-date',
            nextItem: '',
            presentType: "popover",
            popoverButtons: { isNextActive: true, isSkipActive: false, isBackActive: false },
            header: "graph_date_opts_header",
            description: "graph_date_opts_desc",
            nextButton: {
                text: "walkthrough.done_btn_text",
                class: "",
                handler: null
            },
            backButton: {
                text: "walkthrough.back_btn_text",
                class: "",
                handler: null
            }
        },
    ]
}

export const TrappHistoryGraphs: IWalkthroughMaster = {
    key: TrappWalkthroughStepKeys.TRAPP_HISTORY_GRAPHS,
    mainUrl: "/pesttrapp/history",
    queryParam: "",
    values: [
        {
            key: 'trap_graph_chart',
            targetElementId: 'trap_graph_chart',
            nextItem: 'graph_filter',
            presentType: "popover",
            header: "trap_graph_chart_header",
            description: "trap_graph_chart_desc",
            popoverButtons: { isNextActive: true, isSkipActive: false, isBackActive: false },
            nextButton: {
                text: "walkthrough.next_btn_text",
                class: "",
                handler: null
            },
            backButton: {
                text: "walkthrough.back_btn_text",
                class: "",
                handler: null
            }
        },
        {
            key: 'graph_filter',
            targetElementId: 'graph_filter',
            nextItem: '',
            presentType: "popover",
            popoverButtons: { isNextActive: true, isSkipActive: false, isBackActive: false },
            header: "trap_graph_filter_header",
            description: "trap_graph_filter_desc",
            nextButton: {
                text: "walkthrough.done_btn_text",
                class: "",
                handler: null
            },
            backButton: {
                text: "walkthrough.back_btn_text",
                class: "",
                handler: null
            }
        },
        // {
        //     key: 'graph_type',
        //     targetElementId: 'graph_type',
        //     nextItem: '',
        //     presentType: "popover",
        //     popoverButtons: { isNextActive: true, isSkipActive: false, isBackActive: false },
        //     header: "trap_graph_type_header",
        //     description: "trap_graph_type_desc",
        //     nextButton: {
        //         text: "walkthrough.done_btn_text",
        //         class: "",
        //         handler: null
        //     },
        //     backButton: {
        //         text: "walkthrough.back_btn_text",
        //         class: "",
        //         handler: null
        //     }
        // },
    ]
}

export const WalkthroughSteps: Array<IWalkthroughMaster> = [
    HistoryGraphs,
    TrappHistoryGraphs
]

export interface IWalkthroughMaster {
    key: FilizWalkthroughStepKeys | TrappWalkthroughStepKeys
    mainUrl?: string
    queryParam?: string
    values: Array<WalkthroughItem>
}


export class WalkthroughItem {
    key: string
    targetElementId: string
    nextItem: string
    presentType: "popover" | "alert" | "toast" | "customToast"
    hasUserInteraction?: boolean
    hideBackdrop?: boolean
    helperMedia?: any
    style?: ITargetStyleOpts = { backdropOpacity: 1, showTargetBorder: false, theme: "light" }
    description: string
    header: string
    popoverButtons?: IPopoverButtonStatus
    nextButton?: AbsWalkthroughItemButton
    backButton?: AbsWalkthroughItemButton
}

export abstract class AbsWalkthroughItemButton {
    text?: string
    class?: string
    handler?: any
}

export interface IPopoverButtonStatus {
    isNextActive: boolean
    isBackActive: boolean
    isSkipActive: boolean
}

export interface ITargetStyleOpts {
    backdropOpacity
    showTargetBorder: boolean
    theme?: "light" | "dark"
}