import { Component, OnInit, Input } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ContactService } from 'src/app/core/services/contact.service';
import { ModalService } from 'src/app/core/services/modal.service';
@Component({
  selector: 'license-modal',
  templateUrl: './license-modal.component.html',
  styleUrls: ['./license-modal.component.scss'],
})
export class LicenseModalComponent extends BaseModal implements OnInit {
  showButtons: boolean = true;
  showPositiveContent: boolean = false;
  showNegativeContent: boolean = false;
  @Input() Type: string;
  @Input() deviceId: number;
  constructor(private modalService: ModalService, public ContactService: ContactService) {
    super();
    super._modalService = modalService;
  }

  ngOnInit() { }

  licenseCardPositive() {
    const message = "Aşağıda belirtilen cihazın kullanıcısı, cihaz lisansını yenilemek istediğine dair istekte bulunmuştur.";
    const contactUsModel = {
      content: "Content here",
      contentHeader: "Header here",
      buttonName: "Button name here"
    };

    this.ContactService.send(message, contactUsModel, this.deviceId);
    this.showButtons = false;
    this.showPositiveContent = true;
  }

  licenseCardNegative() {
    this.showButtons = false;
    this.showNegativeContent = true;
  }

  showButton() {
    this.showButtons = true;
    this.showPositiveContent = false;
    this.showNegativeContent = false;
  }
}
