import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import { DateFormats } from "../models/enums/date-formats";
import 'moment/locale/ar';
import 'moment/locale/en-gb'; 

@Injectable({
    providedIn: "root"
})
export class DateTimeService {
    private _locale: string;
    public get locale(): string {
        return this.translate.instant("lang.culture_info");
    }
    public set locale(value: string) {
        this._locale = value;
    }
    static LocaleCode: string
    /**
     *
     */
    constructor(private translate: TranslateService) {
        this.locale = translate.currentLang || "en"

        this.translate.onLangChange.subscribe(lang=>{
            if (lang.lang === 'ar') {
                moment.locale('ar');
            } else {
                // default moment locale
                moment.locale("en-gb");
            }
        });
    }

    public static getMoment = moment

    public static isDate(date: string | Date | any) {
        return new Date(date).toString() !== "Invalid Date"
    }

    public GetMoment(date?): moment.Moment {
        if (date && date !== '')
            return moment(date).locale(this.translate.instant("lang.culture_info"));
        else
            return moment().locale(this.translate.instant("lang.culture_info"));
    }

    add(date, num, type, format?) {
        if (date == "")
            return moment().add(num, type).format(format);
        return moment(date).add(num, type).format(format);
    }

    substract(date, num, format?) {
        let a: moment.unitOfTime.DurationConstructor
        if (date == "")
            return moment().subtract(num, "d").format(format);
        return moment(date).subtract(num, "d").format(format);
    }

    public static CreateSortFn(objA: Object, objB: Object, key: string) {
        let dateA = objA[key], dateB = objB[key];
        return DateTimeService.CompareDates(dateA, dateB);
    }

    public static CompareDates(date1: string | Date, date2: string | Date): number {
        try {
            let dateTime1 = moment(date1).toDate().getTime() //typeof date1 == 'string' ? new Date(date1).getTime() : date1.getTime()
            let dateTime2 = moment(date2).toDate().getTime() //typeof date2 == 'string' ? new Date(date2).getTime() : date2.getTime()
            return dateTime1 > dateTime2 ? 1 : dateTime1 == dateTime2 ? 0 : -1
        } catch (error) {
            return 0;
        }
    }

    public static CompareDatesByDay(date1: string | Date, date2: string | Date): number {
        try {
            let dateTime1 = moment(date1).toDate().setHours(0, 0, 0, 0) //typeof date1 == 'string' ? new Date(date1).getTime() : date1.getTime()
            let dateTime2 = moment(date2).toDate().setHours(0, 0, 0, 0) //typeof date2 == 'string' ? new Date(date2).getTime() : date2.getTime()
            return dateTime1 > dateTime2 ? 1 : dateTime1 == dateTime2 ? 0 : -1
        } catch (error) {
            return 0;
        }
    }

    getDays(date) {
        return moment(date).daysInMonth();
    }

    fromNow(date) {
        if (date == "")
            return moment().locale(this.translate.instant("lang.culture_info")).fromNow();
        return moment(date).locale(this.translate.instant("lang.culture_info")).fromNow();
    }

    public static FormatDate(date: moment.Moment | string | Date | number, format: DateFormats | string, preFormat?: DateFormats | string) {
        var locale = DateTimeService.LocaleCode || "en-EN" //LanguageService.currentLanguage.code
        if (!date)
            date = moment().locale(locale)
        else {
            try {
                if (typeof date == "string" || typeof date == "number") {
                    if (preFormat) {
                        date = moment(date, preFormat);
                        if (date.toString() == 'Invalid Date')
                            date = moment(date)
                    }
                    else
                        date = moment(date)
                }
            } catch (error) {

            }
            // date = new Date(date).toISOString()
            // date = moment(date).locale(locale).parseZone()
            date = moment(date).locale(locale)
        }
        // if (!format.includes('HH')) {
        //     if (date.toISOString().split('T').length > 0) {
        //         date = moment(date.toISOString().split('T')[0]);
        //     }
        // }
        return date.format(format)
    }

    public formatDate(date: moment.Moment | string | Date, format: DateFormats | string) {
        if (!date)
            date = moment().locale(this.translate.instant("lang.culture_info"))
        else {
            try {
                if (typeof date == "string") {
                    date = new Date(date).toISOString()

                }
            } catch (error) {

            }
            // date = new Date(date).toISOString()
            date = moment(date).locale(this.translate.instant("lang.culture_info"))
        }
        return date.format(format)
    }

    public static isDateInRange(date: string | Date, minDate: string | Date, maxDate: string | Date): boolean {
        let resultMin = true, resultMax = true;
        date = new Date(date);
        if (minDate && minDate !== '') {
            let minDateObj = new Date(minDate);
            if (minDateObj.getTime() > date.getTime())
                resultMin = false;
        }

        if (maxDate && maxDate !== '') {
            let maxDateObj = new Date(maxDate);
            if (maxDateObj.getTime() < date.getTime())
                resultMax = false;
        }
        return resultMin && resultMax;
    }

    isEqual(date1, date2) {
        if (date1 == "")
            return moment().isSame(moment(date2), 'day');
        else if (date2 == "")
            return moment(date1).isSame(moment(), 'day');
        return moment(date1).isSame(moment(date2), 'day');
    }


    isBefore(date1, date2) {
        if (date1 == "")
            return moment().isBefore(moment(date2));
        else if (date2 == "")
            return moment(date1).isBefore(moment());
        return moment(date1).isBefore(moment(date2));
    }

    isAfter(date1, date2) {
        if (date1 == "")
            return moment().isAfter(moment(date2));
        else if (date2 == "")
            return moment(date1).isAfter(moment());
        return moment(date1).isAfter(moment(date2));
    }

    isBetween(date, date1, date2) {
        return moment(date).isBetween(date1, date2);

    }

    daysBetween(StartDate, EndDate) {
        // The number of milliseconds in all UTC days (no DST)
        const oneDay = 1000 * 60 * 60 * 24;

        // A day in UTC always lasts 24 hours (unlike in other time formats)
        const start = Date.UTC(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate());
        const end = Date.UTC(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate());

        // so it's safe to divide by 24 hours
        return (start - end) / oneDay;
    }
    public static ClearHours(_date: string | Date) {
        if (typeof _date === 'string')
            _date = new Date(_date);
        if (_date.toString() === 'Invalid Date')
            return null
        let date = new Date(_date.getFullYear(), _date.getMonth(), _date.getDate(), 0, 0, 0, 0)
        return date
    }
    // current before month & after month
    public getFirstDayOfNextOrPreviousMonth(monthOffset: number = 1, format: string = 'YYYY-MM-01'): string {
        return this.GetMoment().add(monthOffset, 'months').startOf('month').format(format);
    }
    // given before month or after month
    public getAfterOrBeforeMontDate(date: string | Date, num: number) {
        return moment(date).clone().add(num, 'months').format('YYYY-MM-01');
    }
}


