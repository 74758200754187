import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class OrbitFormValidators {

    public static nameSurnameValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value: string = control.value;

            if (value == null || value == undefined) {
                return { nameValidation: true };
            }
            if (value == "")
                return null

            return value.split(" ").length == 1 ? { nameValidation: true } : null
        }
    }

    public static phoneNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const rawValue: string = control.value + "";

            if (rawValue == null || rawValue == undefined) {
                return { phoneValidation: true };
            }
            let value = rawValue.trim()
            if (value == "")
                return { phoneValidation: true }

            return null //{ phoneValidation: true }
        }
    }

    public static mismatchPasswordValidation(passwordControl): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const controlPassword: string = passwordControl.value
            const reenteredPassword: string = control.value;
            return controlPassword == reenteredPassword ? null : { mismatchPassword: true };
        }
    }

    public static emailValidation(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value) {
                const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
                return matches ? null : { email: true };
              } else {
                return null;
              }
        }
    }
}