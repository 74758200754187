import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { Platform } from "@ionic/angular";

@Injectable({
    providedIn:"root"
})
export class UtilsService {
    /**
     *
     */
    constructor(private platform: Platform) {

    }

    height: number

    getDeviceHeight(isHeaderVisible: boolean = false): number {
        this.setDeviceHeight();
        if (isHeaderVisible) {
            this.height = this.height - 44;
        }
        if (Capacitor.getPlatform().toLowerCase() === 'ios')
            this.height = this.height - 82;
        return this.height;
    }

    setDeviceHeight() {
        this.height = this.platform.height();
    }
}