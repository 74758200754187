import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { ModalService } from 'src/app/core/services/modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DatePickerModalComponent } from '../date-picker-modal/date-picker-modal.component';

@Component({
  selector: 'app-excel-export-filter',
  templateUrl: './excel-export-filter.component.html',
  styleUrls: ['./excel-export-filter.component.scss'],
})
export class ExcelExportFilterComponent extends BaseModal implements OnInit {
  segment: string = "Daily";
  startDate: any = new Date();
  endDate: any = new Date();
  @Input() id: number;
  @Input() isGraphType: boolean;
  @Input() title: string = "general.export-graph";
  @Input() showHourlyData: boolean = true;
  constructor(private modalService: ModalService) {
    super();
    super._modalService = modalService;
  }

  ngOnInit() { }

  result() {
    let res = {
      graphType: this.segment,
      startDate: this.startDate,
      endDate: this.endDate
    }
    this.modalService.dismissByModalId(this.id, res, "filter-obj");
  }


  enterDate(type: string) {
    let modalId = Date.now();
    let date = new Date();
    if (type == "startDate") {
      date = this.startDate;
    } else if (type == "endDate") {
      date = this.endDate;
    }
    this.modalService.presentModal({
      component: DatePickerModalComponent,
      componentProps: {
        date: date
      },
      id: modalId,
      options: ModalService.defaultOptionsTransparent
    }).then(modal => {
      modal.modalObj.onDidDismiss().then(res => {
        if (res.role == "date-data") {
          if (type == "startDate") {
            this.startDate = res.data;
          } else if (type == "endDate") {
            this.endDate = res.data;
          }
        }
      });
    });
  }


}
