import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  @Input() stepCount: number = 3;
  @Input() initialStep: number = 1;
  stepCountArray: Array<number> = [];
  constructor() {
   
  }

  ngOnInit() {
    for (let i = 1; i < this.stepCount+1; i++) {
      this.stepCountArray.push(i);      
  }
   }

}
