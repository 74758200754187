import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";

@Injectable()
export class ClientStatusBarService {

    public statusBarOverlay() {
        if (!Capacitor.getPlatform().toLowerCase().includes("web")) {
            StatusBar.setOverlaysWebView({
                overlay: true,

            }).then()
            StatusBar.setStyle({ style: Style.Light })
            StatusBar.setBackgroundColor({ color: "#FFFFFF00" })
        }

    }

    public setStatusBarTheme(isDarkMode) {
        return new Promise(resolve => {
            if (Capacitor.getPlatform().toLowerCase().includes("web"))
                resolve(true)
            else {
                // StatusBar.setStyle({ style: isDarkMode ? Style.Dark : Style.Default })
                // StatusBar.setStyle({ style: Style.Light });
                // StatusBar.setBackgroundColor({ color: isDarkMode ? "#4c9a31" : "#8483b0" })
                StatusBar.setStyle({ style: Style.Light })
                StatusBar.setBackgroundColor({ color: "#FFFFFF00" })
                resolve(true)
            }
        })
    }
}