import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AuthPageRoutingModule } from './auth-routing.module';

import { AuthPage } from './auth.page';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/core/modules/shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { IntlPhoneInputModule } from "@doktar-yazilim/intl-phone-input";
import { environment } from "src/environments/environment";
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { AuthModalComponent } from './components/auth-modal/auth-modal.component';
import { ExternalLoginComponent } from './components/external-login/external-login.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { SmsValidationComponent } from './components/sms-validation/sms-validation.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { NgOtpInputModule } from 'ng-otp-input';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AuthPageRoutingModule, 
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    NgOtpInputModule,
    // NgxMaskModule,
    IntlPhoneInputModule.forRoot({
      countryListUrl: environment.countryList,
      flagDirUrl: environment.countryFlags
    })
  ],

  declarations: [AuthPage,
    AuthModalComponent,
    LoginComponent,
    RegisterComponent,
    ExternalLoginComponent,
    ForgetPasswordComponent,
    SmsValidationComponent,
    NewPasswordComponent,
    WelcomePageComponent,
    SmsValidationComponent
  ],

  exports: [AuthModalComponent,
    LoginComponent,
    RegisterComponent,
    ExternalLoginComponent,
    ForgetPasswordComponent,
    SmsValidationComponent,
    NewPasswordComponent
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthPageModule { }
