import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { OnboardingTutorialComponent } from './core/helper/components/onboarding-tutorial/onboarding-tutorial.component';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  // },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'filiz',
    loadChildren: () => import('./pages/filiz/filiz.module').then(m => m.FilizPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'pesttrapp',
    loadChildren: () => import('./pages/pesttrapp/pesttrapp.module').then(m => m.PesttrappPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'common',
    loadChildren: () => import('./pages/common/common.module').then(m => m.CommonPageModule),
    canActivateChild: [AuthGuard]
  },
  { path: '', redirectTo: 'common', pathMatch: "full" },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
