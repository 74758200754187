import { environment } from "src/environments/environment";
import { HttpRequestTypes } from "../models/enums/http-request-types";
import { ILoginModel } from "../models/interfaces/login-model";
import { IAuthResponse } from "../models/interfaces/auth-response";
import { IRegisterModel } from "../models/interfaces/register-model";
import { ClientBaseApiService } from "./client-base-api.service";
import { Injectable } from "@angular/core";
import { INotificationModel } from "../models/interfaces/notification-model";

@Injectable({
    providedIn: "any"
})
export class AuthApiService {
    baseApiUrl = environment.baseApiUrl
    /**
     *
     */
    constructor(private clientApiService: ClientBaseApiService) {

    }

    public login(payload: ILoginModel) {
        let url = this.baseApiUrl + "v2/api/authentication/login?userName=" + payload.email + "&password=" + payload.password;
        return this.clientApiService.baseApiCall<IAuthResponse>({ url: url, payload: {}, requestType: HttpRequestTypes.GET })
    }

    public socialLogin(payload: any) {
        let url = this.baseApiUrl + "v2/api/authentication/social/login?socialType=" + payload.SocialType + "&socialToken=" + payload.SocialToken;
        return this.clientApiService.baseApiCall<IAuthResponse>({ url: url, payload: {}, requestType: HttpRequestTypes.GET })
    }

    // public register(payload: IRegisterModel) {
    //     let url = this.baseApiUrl + "api/signup"
    //     return this.clientApiService.baseApiCall({ payload, requestType: HttpRequestTypes.POST, url })
    // }
    public register(payload: IRegisterModel) {
        let url = this.baseApiUrl + "v2/api/authentication/register"
        return this.clientApiService.baseApiCall({ payload, requestType: HttpRequestTypes.POST, url })
    }
    deleteAccount() {
        let url = this.baseApiUrl + "api/delete/account"
        return this.clientApiService.baseApiCall({ url: url, payload: {}, requestType: HttpRequestTypes.POST })
    }

    sendSms(payload) {
        let url = this.baseApiUrl + "v2/api/user/forgot/password"
        return this.clientApiService.baseApiCall({ payload, requestType: HttpRequestTypes.POST, url })
    }
    FacebookUser(fbUserId) {
        const serviceUrl = environment.baseApiUrl + "api/fb/login";
        return this.clientApiService.baseApiCall({ url: serviceUrl, payload: { fbUserId: fbUserId }, requestType: HttpRequestTypes.POST });
    }

    FacebookRegister(fullName, email, phoneNumber, fbuserid) {
        const serviceUrl = environment.baseApiUrl + "api/fb/signup";
        return this.clientApiService.baseApiCall({ url: serviceUrl, payload: { nameSurname: fullName, email: email, phoneNumber: phoneNumber, fbuserid: fbuserid }, requestType: HttpRequestTypes.POST });
    }

    public GetNotifications(skipCount: number, takeCount: number) {
        let url = environment.baseApiUrl + `api/get/notifications?skipCount=${skipCount}&takeCount=${takeCount}`;
        return this.clientApiService.baseApiCall<Array<INotificationModel>>({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
    }

    deleteDeviceToken(token) {
        let campaignId = 110;
        let url = environment.baseApiUrl + "api/remove/device/token?campaignId=" + campaignId + "&deviceToken=" + token;
        return this.clientApiService.baseApiCall({ url: url, payload: {}, requestType: HttpRequestTypes.GET });
    }
}