import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  @Input() state: boolean;
  animationClass: string = "loading-animation-step1"
  constructor() { }

  ngOnInit() {
    setInterval(() => {
      this.animationClass = "loading-animation-step2"
      setTimeout(() => {
        this.animationClass = "loading-animation-step1"
      }, 1000);
    }, 2000);
  }

}
