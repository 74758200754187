import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OrbitFormValidators } from 'src/app/core/helper/class/form-validators';
import { Message_Type } from 'src/app/core/models/enums/message-type';
import { ComponentType } from 'src/app/core/models/enums/phone-input-component-types';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { ILoginModel } from 'src/app/core/models/interfaces/login-model';
import { AccountService } from 'src/app/core/services/account.service';
import { ClientAlertService } from 'src/app/core/services/client-alert.service';
import { LanguageService } from 'src/app/core/services/lang.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { NotificationService } from 'src/app/core/services/notification-service';
import { StorageService } from 'src/app/core/services/storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { FarmMapService } from 'src/app/pages/common/farm-map/services/farm-map.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../../auth.page.scss'],
})
export class LoginComponent implements OnInit {
  countryCodes: string = "tr"
  passwordVisibility: boolean = false
  componentType = ComponentType.FULL_INPUT_SEPERATED
  loginForm: FormGroup;
  @Output() openModal: EventEmitter<boolean> = new EventEmitter();
  @Output() socialLogin: EventEmitter<any> = new EventEmitter();
  constructor(private formBuilder: FormBuilder,
    private modalService: ModalService,
    private router: Router,
    private changeDetRef: ChangeDetectorRef,
    private langService: LanguageService,
    private toastService: ToastService,
    private accountService: AccountService,
    private storageService: StorageService,
    private loadingService: LoadingService,
    private notificationService: NotificationService,
    private farmService:FarmMapService) { }

  ngOnInit() {
    this.setupForm()
  }
  setupForm() {
    this.loginForm = this.formBuilder.group({
      phone: ["", [OrbitFormValidators.phoneNumberValidator(), Validators.required]],
      password: ["", [Validators.required]],
    });
  }
  currentCountryInfo
  dialCode
  lastPhonenumber: string = ""

  phoneInputChanged(ev, type) {
    switch (type) {
      case "countryChanged":
        this.currentCountryInfo = ev;
        this.dialCode = ev.dialCode
        break;
      case "phoneChanged":
        let phone: string = ev.parsedNumber;
        this.lastPhonenumber = phone
        if (this.currentCountryInfo) {
          this.loginForm.patchValue({ phone: phone }, { emitEvent: true })
        } else {

        }
        break;
      case "phoneValidationChanged":
        if (!ev) {
          // this.loginForm.patchValue({ phone: null }, { emitEvent: true })
          // this.loginForm.controls["phone"].markAsDirty()
        }
        break;
      default:
        break;
    }
  }

  onSubmit() {
    if (this.loginForm.valid) {
      let payload: ILoginModel = {
        email: this.dialCode + this.loginForm.get("phone").value.toString(),
        password: encodeURIComponent(this.loginForm.get("password").value)
      }
      this.loadingService.showLoading();
      this.accountService.Login(payload).subscribe(res => {
        if (res.IsSuccess) {
          this.farmService.CheckIfAnyFarmDeletedByOwner()
          this.notificationService.pushNotificationOperations();
          this.storageService.set(StorageKeys.WELCOME, true).then(e => {
            if (e.IsSuccess) {
              this.router.navigate(["common"], {
                replaceUrl: true
              });
            }
          });
          this.storageService.set(StorageKeys.FIRST_LOGIN, true).then();
        } else {
          this.toastService.PresentBasicToast({
            message: this.langService.getTranslation("errors." + res.Message),
            type: Message_Type.DANGER
          }).then(response => {

          });
        }
        this.loadingService.hideLoading();
      });
    } else {
      this.toastService.PresentBasicToast({
        message: this.langService.getTranslation("auth.login_not_valid"),
        type: Message_Type.WARNING
      }).then(response => {

      });
    }
  }

  forgetPassword() {
    this.openModal.emit(true);
    this.modalService.presentModal({
      component: ForgetPasswordComponent,
      componentProps: {},
      options: {}
    }).then(modalFP => {
      modalFP.modalObj.onDidDismiss().then(dismiss => {
        this.openModal.emit(false);
      });
    });
  }

  socialLoginExternal(ev) {
    this.socialLogin.emit(ev);
  }

  loginWithSocial(ev) {
    this.loadingService.showLoading();
    let payload = {
      SocialType: ev.Type,
      SocialToken: ev.Id
    }
    this.accountService.loginWithSocial(payload).subscribe(res => {
      this.loadingService.hideLoading();
      if (res.IsSuccess) {
        this.accountService._setUserData(res.Data)
        this.notificationService.pushNotificationOperations();
        this.storageService.set(StorageKeys.WELCOME, true).then(e => {
          if (e.IsSuccess) {
            this.router.navigate(["common"]);
          }
        });
        this.storageService.set(StorageKeys.FIRST_LOGIN, true).then();
      } else {
        // register olmak istermisiniz
        // this.alertService.confirmAlertController(this.translateService.instant("auth.want-register")).then(() => {
        //   this.alertService.alert.onDidDismiss().then(res => {
        //     if (res.role == "true") {
        this.socialLogin.emit(ev);
        // }
        //   });
        // });
      }
    }, err => {
      this.loadingService.hideLoading();
    });
  }


}