import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthApiService } from 'src/app/core/api/auth-api.service';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { Message_Type } from 'src/app/core/models/enums/message-type';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'orbit-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss', '../../auth.page.scss'],
})
export class NewPasswordComponent extends BaseModal implements OnInit {
  passwordForm: FormGroup = new FormGroup({
    Password: new FormControl("", Validators.required),
    PasswordRepeat: new FormControl("", Validators.required),
    PhoneNumber: new FormControl(""),
    Otp: new FormControl("")
  });
  @Input() otpKey: any;
  @Input() phoneNumber: string;
  isSend: boolean = false;
  passwordMatch: boolean = true;
  constructor(private loadingService: LoadingService,
    private authApiService: AuthApiService,
    private router: Router, private modalService: ModalService,
    private toastService: ToastService,
    private translateService: TranslateService) {
    super()
    super._modalService = modalService
  }

  ngOnInit() {
    this.passwordForm.controls["Otp"].setValue(this.otpKey);
    this.passwordForm.controls["PhoneNumber"].setValue(this.phoneNumber);
  }

  save() {
    if (this.passwordForm.value.Password == this.passwordForm.value.PasswordRepeat) {
      // this.loadingService.showLoading();
      let payload = {
        "PhoneNumber": this.passwordForm.value.PhoneNumber,
        "Otp": this.passwordForm.value.Otp,
        "NewPassword": this.passwordForm.value.Password
      }
      this.authApiService.sendSms(payload).subscribe(e => {
        if (e.IsSuccess) {
          this.isSend = true;
        } else {
          if (e.ValidationErrors && e.ValidationErrors.length > 0) {
            this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.ValidationErrors[0]), type: Message_Type.WARNING, duration: 3000 });
          } else {
            this.toastService.PresentBasicToast({ message: this.translateService.instant("errors." + e.Message), type: Message_Type.DANGER, duration: 3000 });
          }
        }
      });
      // this.isSend = true;
    } else {
      this.passwordMatch = false;
    }
  }


  redirect() {
    this.dismiss(null);
    this.router.navigate([""], { replaceUrl: true });
  }
}
