import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AndroidPermissionService {
  platform: string
  constructor(private androidPermissions: AndroidPermissions) {
    this.platform = Capacitor.getPlatform();
  }


  requestPermisson(type: PermissionType): Observable<boolean> {

    return new Observable(observer => {
      if (this.platform == "android") {
        if (type == PermissionType.Camera) {
          this.checkRequestPermission(this.androidPermissions.PERMISSION.CAMERA).subscribe(res => {
            observer.next(res);
          });
        } else if (type == PermissionType.Location) {
          this.checkRequestPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION).subscribe(res => {
            observer.next(res);
          });
        } else if (type == PermissionType.Notification) {
          this.checkRequestPermission(this.androidPermissions.PERMISSION.POST_NOTIFICATIONS).subscribe(res => {
            observer.next(res);
          });
        } else if (type == PermissionType.Storage) {

          let permissionList = [
            this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
            this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE,
            // this.androidPermissions.PERMISSION.READ_MEDIA_VISUAL_USER_SELECTED,
            // this.androidPermissions.PERMISSION.READ_MEDIA_VIDEO,
            // this.androidPermissions.PERMISSION.READ_MEDIA_IMAGES,
            // this.androidPermissions.PERMISSION.READ_MEDIA_AUDIO
          ]
          this.checkRequestPermission(permissionList).subscribe(res => {
            observer.next(res);
          });
        } else if (type == PermissionType.Video) {
          // this.checkRequestPermission(this.androidPermissions.PERMISSION.READ_MEDIA_VIDEO).subscribe(res => {
          this.checkRequestPermission(this.androidPermissions.PERMISSION.CAMERA).subscribe(res => {
            observer.next(res);
          });
        }
        else if (type == PermissionType.Audio) {

          this.checkRequestPermission(this.androidPermissions.PERMISSION.READ_MEDIA_AUDIO).subscribe(res => {
            observer.next(res);
          });
        } else
          observer.next(false)
      }
      else {
        observer.next(true)
      }

    });
  }

  checkRequestPermission(permission): Observable<boolean> {
    return new Observable(observer => {
      if (Array.isArray(permission)) {
        let negativeList = []
        permission.forEach(async el => {
          let resp = await this.androidPermissions.checkPermission(el)
          if (!resp.hasPermission)
            negativeList.push(el)
        });
        if (negativeList.length > 0)
          this.androidPermissions.requestPermissions(permission).then(resRequest => {
            observer.next(resRequest.hasPermission);
          });
        else
          observer.next(true)
      } else {
        this.androidPermissions.checkPermission(permission).then(resCheck => {
          if (resCheck.hasPermission) {
            observer.next(true);
          } else {
            this.androidPermissions.requestPermission(permission).then(resRequest => {
              observer.next(resRequest.hasPermission);
            });
          }
        });
      }
    });
  }

}

export enum PermissionType {
  Camera,
  Location,
  Notification,
  Storage,
  Video,
  Audio
}
