import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { SwiperModule } from "swiper/angular";
import { FromGeoJsonDirective } from "../directives/from-geo-json.directive";
import { BackButtonComponent } from "../helper/components/back-button/back-button.component";
import { EmptyPageComponent } from "../helper/components/empty-page/empty-page.component";
import { LanguageButtonComponent } from "../helper/components/language-button/language-button.component";
import { ListModalComponent } from "../helper/components/list-modal/list-modal.component";
import { OnboardingTutorialComponent } from "../helper/components/onboarding-tutorial/onboarding-tutorial.component";
import { SelectLanguageComponent } from "../helper/components/select-language/select-language.component";
import { StepperComponent } from "../helper/components/stepper/stepper.component";
import { SwiperComponent } from "../helper/components/swiper/swiper.component";
import { UserAgreementComponent } from "../helper/components/user-agreement/user-agreement.component";
import { IsFormValidPipe } from "../pipes/is-form-valid.pipe";
import { FormatDatePipe } from "../pipes/format-date.pipe";
import { HeaderComponent } from "../helper/components/header/header.component";
import { DeviceInfoPipe } from "src/app/pages/pesttrapp/pipes/device-info.pipe";
import { ChangePasswordComponent } from "../helper/components/change-password/change-password.component";
import { CustomFilterComponent } from "../helper/components/custom-filter/custom-filter.component";
import { MomentFromNowPipe } from "../pipes/moment-from-now.pipe";
import { BaseModalModule } from "./base-modal.module";
import { SetDateFilterComponent } from "../helper/components/set-date-filter/set-date-filter.component";
import { CustomBottomAlertComponent } from "../helper/components/custom-bottom-alert/custom-bottom-alert.component";
import { ExcelExportFilterComponent } from "../helper/components/excel-export-filter/excel-export-filter.component";
import { SelectExportTypeFilizComponent } from "../helper/components/select-export-type-filiz/select-export-type-filiz.component";
import { CustomTableComponent } from "../helper/components/custom-table/custom-table.component";
import { UnitPreferenceModule } from "./unit-preferences.module";
import { LicenseModalComponent } from "../helper/components/license-modal/license-modal.component";

@NgModule({
    imports: [
        IonicModule,
        FormsModule,
        CommonModule,
        TranslateModule,
        SwiperModule,
        ReactiveFormsModule,
        BaseModalModule,
        UnitPreferenceModule
    ],

    declarations: [
        BackButtonComponent,
        ListModalComponent,
        IsFormValidPipe,
        LanguageButtonComponent,
        SelectLanguageComponent,
        OnboardingTutorialComponent,
        SwiperComponent,
        UserAgreementComponent,
        StepperComponent,
        EmptyPageComponent,
        FromGeoJsonDirective,
        FormatDatePipe,
        HeaderComponent,
        DeviceInfoPipe,
        ChangePasswordComponent,
        CustomFilterComponent,
        MomentFromNowPipe,
        SetDateFilterComponent,
        CustomBottomAlertComponent,
        ExcelExportFilterComponent,
        SelectExportTypeFilizComponent,
        CustomTableComponent,
        LicenseModalComponent
    ],

    exports: [
        BackButtonComponent,
        ListModalComponent,
        IsFormValidPipe,
        LanguageButtonComponent,
        SelectLanguageComponent,
        OnboardingTutorialComponent,
        SwiperComponent,
        SwiperModule,
        UserAgreementComponent,
        StepperComponent,
        EmptyPageComponent,
        FromGeoJsonDirective,
        FormatDatePipe,
        HeaderComponent,
        DeviceInfoPipe,
        ChangePasswordComponent,
        CustomFilterComponent,
        MomentFromNowPipe,
        SetDateFilterComponent,
        CustomBottomAlertComponent,
        ExcelExportFilterComponent,
        SelectExportTypeFilizComponent,
        CustomTableComponent, 
        LicenseModalComponent
    ]
})
export class SharedModule {

}