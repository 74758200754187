import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { LanguageService } from 'src/app/core/services/lang.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { StorageService } from 'src/app/core/services/storage.service';
import SwiperCore, { Pagination } from "swiper";
import { SwiperComponent } from 'swiper/angular';

// install Swiper modules
SwiperCore.use([Pagination]);
@Component({
  selector: 'onboarding-tutorial',
  templateUrl: './onboarding-tutorial.component.html',
  styleUrls: ['./onboarding-tutorial.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OnboardingTutorialComponent extends BaseModal implements OnInit, AfterViewInit {
  @ViewChild('swiper', { static: false }) swiperRef?: SwiperComponent;
  list: Array<any> = [];
  current: number = 0;
  interval: any = null;
  isStoped: boolean = false;
  listenerLang: Subscription;
  max: number = 0;
  firstPage: boolean = true;
  constructor(public langService: LanguageService, private modalService: ModalService, private router: Router, private ch: ChangeDetectorRef,
    private storageService: StorageService, private translateService: TranslateService) {
    super()
    
  }
  ngAfterViewInit(): void {
    this.storageService.init().then(s => {
      setTimeout(() => {
        this.list.forEach(e => {
          let text = document.getElementById("slide-text-" + e.index);
          setTimeout(() => {
            let height = text.clientHeight + 150
            e["height"] = 'calc(100% - ' + height + 'px)'
          }, 100);

        });
        this.ch.detectChanges();
      }, 100);

    })


  }

  ngOnInit() { }
  ionViewDidLeave() {
    this.firstPage = true;
  }
  next() {
    if (this.swiperRef.swiperRef.activeIndex == this.list.length - 1) {
      this.goToLogin();
    } else {
      this.swiperRef.swiperRef.slideNext();
    }
  }

  goToLogin() {
    this.router.navigateByUrl("/");
  }
  getStart() {
    this.firstPage = false;
    if (!this.firstPage) {
      this.list = new Array(4).fill(1).map((t, index) => {
        return {
          img: "../../../../../assets/images/on-boarding-" + (index + 1) + ".png",
          description: "on-boarding.description-" + (index + 1),
          header: "on-boarding.header-" + (index + 1),
          index: index,
          height: 'calc(100% - 270px)'
        }
      })
      this.listenerLang = this.translateService.onLangChange.subscribe(lang => {
        setTimeout(() => {
          this.list.forEach(e => {
            let text = document.getElementById("slide-text-" + e.index);
            let height = text?.clientHeight + 150
            e["height"] = 'calc(100% - ' + height + 'px)'
            if (height > this.max) {
              this.max = height;
            }
          });
          // let pag = document.getElementsByClassName("swiper-pagination swiper-pagination-bullets");
          // console.log(pag)
          // pag[0].setAttribute('style', 'top:calc(100% - ' + (this.max + 30) + 'px)');
          this.ch.detectChanges();
        }, 100);
      });
    }
  }
}
